import * as React from 'react'
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';

import T from 'Utils/localizerHook';
import { Icon } from 'Utils/Icon'
import { getDirectionFromText } from 'Utils/Utilites';
import classes from './InputLabel.module.scss'

interface IProps {
    label: string | undefined;
    hint?: string;
    isTruncateOff?: boolean;
    numbering?: string;
    isRequired?: boolean;
}

const InputLabel: React.FC<IProps> = ({ label, hint, isTruncateOff, numbering, isRequired }) => {
    const tooltipText = hint || "";
    const localizedHint = T(tooltipText)

    return (
        <label className="form-label d-flex text-gray-500">
            {isRequired && <small className={classes.Asterisk}>*</small>}
            {numbering && <span className={`fw-bold f-s-14 me-1 ${classes.InputLabel} ${classes.FontSize}`}>{numbering}</span>}
            <span className={`text-gray-500 px-0 fw-bold f-s-14 ${isTruncateOff ? '' : 'text-truncate'} ${classes.InputLabel} ${classes.FontSize}`} title={isTruncateOff ? "" : label} dir={getDirectionFromText(String(label))}>
                {label}
            </span>
            {hint && <> <span className={`d-flex ${classes.FontSize}`} data-tip={localizedHint} >
                <Icon iconname="Info" fontSize="small" className="text-gray-500 ms-2" /></span>
                <ReactTooltip /></>}
        </label>
    )
}

export default React.memo(InputLabel, (next, prev) => _.isEqual(next, prev));
import axios from 'axios';
import { Settings } from 'Utils/Settings';


const instance = axios.create({
    baseURL: Settings.apiBaseUri,
    headers: {
        'Access-Control-Allow-Methods': 'HEAD, GET, POST, PUT, PATCH, DELETE',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
});

// instance.defaults.headers.common['Accept']='application/json';
// instance.defaults.headers.common['Virgin-Device-Id']='12345';
// instance.defaults.headers.common['user-agent']='VirginMobile/1.1';
// instance.defaults.headers.post['Content-Type']='application/x-www-form-urlencoded';
instance.defaults.headers.common['Authorization'] = "AUTH_TOKEN";

instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    return config;
}, function (error) {
    // Do something with request error
    console.log(error);
    return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    return Promise.reject(error);
});

export default instance;





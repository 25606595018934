import { BaseService } from "./Core/BaseService";
import { IEmbedReportPagesService, IEmbedReportService } from "../Models/DashboardModels";

export default class DashboardsService extends BaseService {
 
    public async getReportPages(groupName:string): Promise<Array<IEmbedReportPagesService>> {
        return this.ProtectedApi.get("Reports/GetReportPagesByGroupName", { params:{ 
            groupName: groupName
         } })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                throw error;
            });
    };

    public async getUserEmbedReport(reportId:string): Promise<IEmbedReportService> {
        return this.ProtectedApi.get("Reports/GetUserEmbeddingReportInfo", { params:{ 
            reportId: reportId
         } })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                throw error;
            });
    };

    public async getJoipTop10ISAsEmbedReport(reportId:string): Promise<IEmbedReportService> {
        return this.ProtectedApi.get("Reports/GetAnonymousEmbeddingReportInfo", { params:{ 
            reportId: reportId
         } })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                throw error;
            });
    };
}
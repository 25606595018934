import * as React from 'react';
import store from 'store2';
// Sentry.io
import * as Sentry from "@sentry/react";

import CommonService from '../CommonService';
import { IConfiguration, IConfigurations } from 'Models/CommonModels';
import wrapPromise from 'Utils/wrapPromise';
import {Settings} from 'Settings'

const fetchSettings = () => {
  const _commonService = new CommonService();
  const data = _commonService.getConfigurations();
  return wrapPromise(data);
}

const checkIsEligible = () : boolean => {
  return window.location.pathname != "/callback";
}

const _fetchSettings = checkIsEligible() ? fetchSettings() : null;


export const FetchSettings = () => {
  let data: any = null;
  if (_fetchSettings) {
      data = _fetchSettings.read();
  };
  
  React.useEffect(() => {
    if (data) {
      let configurations: IConfigurations = {}
      data.forEach((config: IConfiguration) => configurations[config.key] = config.value)
      store.set(process.env.REACT_APP_LOCAL_STORAGE_SETTINGS, configurations)
      
      if(process.env.NODE_ENV !== "development") {
        Sentry.init({
          dsn: Settings.sentrydsn,
          environment:process.env.NODE_ENV,
          release: Settings.joipVersion
        });
      }
    }
  }, [data]);

  return (<></>)
}

import React from 'react';
import { useQuery } from 'react-query';
import { Report } from 'powerbi-client';

import EmbedReport from 'Components/Shared/PowerBI/EmbedReport/EmbedReport';
import DashboardsService from 'Services/DashboardsService';
import MyReportsContainer from './MyReportsContainer';
import { Settings } from 'Utils/Settings';

const MyWorksReport = () => {
  const [report, setReport] = React.useState<Report>();
  const reportId = Settings.dashboardsUserMyWorksReport;
  const _DashboardsService = new DashboardsService();

  const getReport = async (report: Report) => {
    setReport(report);
  }

  const fetcReportPages = async () => { return await _DashboardsService.getReportPages("UserWorksReport"); }
  const { data } = useQuery(["Reports/GetReportPagesByGroupName?groupName=", "UserWorksReport"], fetcReportPages, {
    cacheTime: Settings.cacheTimeProfile
  });

  return (
    <MyReportsContainer>
      <EmbedReport reportId={reportId} getEmbedReport={getReport} pages={data || []} defaultPageId={data ? data[0].powerBiPageId : ''}
        getReportApiEndPoint={_DashboardsService.getUserEmbedReport.bind(_DashboardsService, reportId)}
        settings={{
          settings: {
            panes: {
              bookmarks: {
                visible: false
              },
              filters: {
                expanded: false,
                visible: false
              },
              syncSlicers: {
                visible: false
              },
              pageNavigation: {
                visible: false
              }
            },
          }
        }} />
    </MyReportsContainer>
  )
}

export default  React.memo(MyWorksReport);
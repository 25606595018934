import * as React from 'react'
import * as Icons from '@material-ui/icons'

import dir from '../Utils/direction'

const getIconElement = (name: keyof Icons.SvgIconComponent) => {
    return Icons[name];
}

const getIconByName = (props: any, isDirAware: boolean) => {
    const { iconname } = props;
    const iconName = iconname && iconname.replace(/Icon$/, '') // removes 'Icon' suffix if added

    const IconElements = Object.keys(Icons).map(icon => icon);
    let index: number = IconElements.findIndex((c: any) => c === iconName)
    if (index >= 0) {
        const iconKey = IconElements[index] as keyof Icons.SvgIconComponent
        if (iconKey) {
            const iconEl = getIconElement(iconKey)

            const flip: boolean = isDirAware && dir.getDir() === dir.Dir.RTL;
            const style = {
                transform: flip ? "scaleX(-1)" : undefined
            }
            props = { ...props, style }

            return React.createElement(iconEl, { ...props })
        }
    }
    return null
}

export const Icon: React.FC<{ iconname: string, [x: string]: any }> = (props) => {
    return getIconByName(props, false)
}

export const DirAwareIcon: React.FC<{ iconname: string, [x: string]: any }> = (props) => {
    return getIconByName(props, true)
}


import { IActorProfile } from "./ProfileModels";

export enum CONTENT_VERIFICATION_STATUS {
  all = 0,
  pending = 1,
  verified = 2,
  declined = 3,
}
export enum CONTENT_VERIFICATION_TYPE {
  all = 0,
  employment = 1,
  education = 2,
  qualification = 3,
  distinction = 4,
  invitedPosition = 5,
  membership = 6,
  service = 7,
}

export interface IContentVerificationView {
  requesterProfileId: number,
  contentTypeId: number,
  contentTypeName: string,
  contentId: number,
  createDateTime: string,
  orgProfileId: number,
  orgName: string,
  title: string,
  department: string,
  countryId: number,
  countryName: string,
  city: string,
  startYear: number,
  startMonth: number,
  startDay: number,
  endYear: number,
  endMonth: number,
  endDay: number,
  statusId: number,
  statusName: string,
  statusDateTime: string,
  declineReason: string
}

export interface IContentVerificationHistoryView extends IContentVerificationView {
  contentVerifications: Array<IContentVerificationRequestBase>
}
export interface IContentVerificationRequestBase {
  id: number;
  statusId: number;
  contentTypeId: number;
  contentTypeName?: string;
  declineReason?: string;
  statusDateTime?: string;
  createDateTime?: string;
}
export interface IContentVerificationRequest extends IContentVerificationRequestBase{  
  education: IAffiliation | null;
  invitedPosition: IAffiliation | null;
  qualification: IAffiliation | null;
  distinction: IAffiliation | null;
  service: IAffiliation | null;
  employment: IAffiliation | null;
  membership: IAffiliation | null;
  userProfile: IActorProfile;
  orgAdminProfile: IActorProfile | null;
  rowVersion?: string;
}
export interface IAffiliation {
  orgName: string;
  departmentName: string;
  startYear?: number;
  startMonth?: number;
  startDay?: number;
  endYear?: number;
  endMonth?: number;
  endDay?: number;
  city: string;
  countryName: string;
  title: string;
  url: string;
  professionName?: string;
  professionSubTypeName?: string;
  faculty?: string;
  degreeTypeName?: string;
  degreeOptionName?: string;
  levelName?: string;
  isAwardedCertificate?: boolean;
}
export interface IContentVerificationPagination {
  pageIndex: number;
  pageSize: number;
  contentType?: CONTENT_VERIFICATION_TYPE;
  status?: CONTENT_VERIFICATION_STATUS,
  searchByName?: string;
}
export interface IContentVerificationFilter {
  contentType: CONTENT_VERIFICATION_TYPE,
  status: CONTENT_VERIFICATION_STATUS
}
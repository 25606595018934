import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Client } from "@twilio/conversations";
import moment from 'moment';

import { ApplicationState, AppThunk } from '../index';
import { IChatModal, ClientStatus } from 'Models/ChatsModel';
import { addMessageNotificationsCount, resetMessageNotifications } from './Notifications/NotificationSlice';

const initialState: IChatModal = {
    client: null,
    clientStatus: "disconnected",
    conversations: [],
    selectedConversation: null,
    activeMessageObj: {},
    activeMessages: [],
    participant: null,
    user: null,
    activeConversations: [],
    convLoading: false,
    showEmptyMessage: false,
    usersTyping: [],
    isActiveMessgeLoading: false
};


const chatSlice = createSlice({
    name: 'chatSlice',
    initialState: initialState,
    reducers: {
        addClient: (state, action: PayloadAction<Client>) => {
            state.client = action.payload;
        },
        changeClientStatus: (state, action: PayloadAction<ClientStatus>) => {
            state.clientStatus = action.payload
        },
        addConversations: (state, action: PayloadAction<any[]>) => {

            const conversations = action.payload;
            conversations?.forEach(async (item: any) => {
                item._lastMessage = item?.lastMessage;
            });

            state.conversations = conversations
        },
        addConversation: (state, action: PayloadAction<any>) => {
            const conversation = action.payload;
            if (!state.conversations.find((c: any) => c.sid == conversation.sid)) {
                conversation.badgeCount = 0;
                state.conversations.unshift(conversation);
                state.showEmptyMessage = false;
            }
        },
        addSelectedConversation: (state, action: PayloadAction<any>) => {
            state.selectedConversation = action.payload
        },
        addActiveMessages: (state, action: PayloadAction<any>) => {
            state.activeMessages = action.payload
        },
        appendActiveMessages: (state, action: PayloadAction<any>) => {
            if (action.payload) {
                state.activeMessages = action.payload.concat(state.activeMessages) //state.activeMessages.concat(action.payload)
            }
        },
        AddActiveMessageObj: (state, action: PayloadAction<any>) => {
            state.activeMessageObj = action.payload
        },
        messageAdded: (state, action: PayloadAction<any>) => {

            const message = action.payload;

            if (state.selectedConversation && state.selectedConversation.sid == message.conversation.sid) {
                state.activeMessages = [...state.activeMessages, message];
            }
            const conversatioins = [...state.conversations];
            const conversationIndex = conversatioins.findIndex((item: any) => item.sid == message.conversation.sid);
            const conversation = conversatioins[conversationIndex];

            conversation._lastMessage = {
                index: message?.index,
                dateCreated: message?.dateCreated
            }

            const sortedConversations = conversatioins.sort(((a: any, b: any) => Number(moment(b?._lastMessage?.dateCreated).format('YYYYMMDDHHmmss')) - Number(moment(a?._lastMessage?.dateCreated).format('YYYYMMDDHHmmss'))));
            state.conversations = [...sortedConversations];
        },
        messageDeleted: (state, action: PayloadAction<any>) => {
            const message = action.payload;

            if (state.selectedConversation && state.selectedConversation.sid == message.conversation.sid) {
                state.activeMessages = state.activeMessages.filter((item: any) => item.sid != message.sid);
            }

            const conversatioins = [...state.conversations];
            const conversationIndex = conversatioins.findIndex((item: any) => item.sid == message.conversation.sid);
            if (conversatioins[conversationIndex]._lastMessage.index == message.index) {
                conversatioins[conversationIndex]._lastMessage.index = message.index - 1;
            }
            state.conversations = conversatioins;
        },
        setReciverParticipant: (state, action: PayloadAction<any>) => {
            state.participant = action.payload;
        },
        setReciverUser: (state, action: PayloadAction<any>) => {
            state.user = action.payload;
        },
        setActiveConversationsSids: (state, action: PayloadAction<any>) => {
            state.activeConversations.push(action.payload)
        },
        setConLoading: (state, action: PayloadAction<boolean>) => {
            state.convLoading = action.payload
        },
        addUserTyping: (state, action: PayloadAction<any>) => {
            if (action.payload) {
                state.usersTyping.push(action.payload);
            }
        },
        removeUserTyping: (state, action: PayloadAction<any>) => {
            if (action.payload) {
                state.usersTyping = state.usersTyping.filter((p: any) => p.sid != action.payload.sid);
            }
        },
        resetActiveConversation: (state) => {
            state.selectedConversation = null;
            state.activeMessageObj = {};
            state.activeMessages = [];
            state.participant = null;
            state.user = null;
            state.activeConversations = [];
        },
        updateMessage: (state, action: PayloadAction<any>) => {
            const message = action.payload.message;
            if (message) {
                const messageIndex = state?.activeMessages?.findIndex((item: any) => item.sid == message.sid);
                if (messageIndex >= 0) {
                    state.activeMessages[messageIndex] = {};
                    state.activeMessages[messageIndex] = message;
                }

                const conversation = state?.conversations?.find((item: any) => item.sid == message.conversation.sid);
                if (conversation && conversation?._lastMessage?.index == message.index) {
                    conversation._lastMessage.index = message.index;
                    conversation._lastMessage.dateCreated = message.dateCreated
                }
            }
        },
        setActiveMessgeLoading: (state, action: PayloadAction<boolean>) => {
            state.isActiveMessgeLoading = action.payload
        },
    }
});

export const {
    addClient,
    changeClientStatus,
    addConversations,
    addConversation,
    addSelectedConversation,
    AddActiveMessageObj,
    messageAdded,
    setReciverParticipant,
    setReciverUser,
    setActiveConversationsSids,
    setConLoading,
    addActiveMessages,
    appendActiveMessages,
    messageDeleted,
    addUserTyping,
    removeUserTyping,
    resetActiveConversation,
    updateMessage,
    setActiveMessgeLoading
} = chatSlice.actions;


export const setReciverUserInfo = (conversation: any, jrin: string): AppThunk => async (dispatch, getstate) => {

    const participants = await conversation.getParticipants();
    const participant = participants.find((item: any) => item.identity != jrin);
    const userObj = await participant?.getUser();

    dispatch(setReciverParticipant(participant));
    dispatch(setReciverUser(userObj));
};

export const sortConversations = (convs: any[] | null): AppThunk => async (dispatch, getstate) => {

    let conversations: any[] | null = [];
    if (convs) {
        conversations = [...convs];

        const sortedReslult: any[] = conversations.sort(((a: any, b: any) => Number(moment(b?.lastMessage?.dateCreated).format('YYYYMMDDHHmmss')) - Number(moment(a?.lastMessage?.dateCreated).format('YYYYMMDDHHmmss'))));
        dispatch(addConversations([...sortedReslult]));

    } else {
        const localConversations = getstate().chat?.conversations;
        if (localConversations) {
            conversations = [...localConversations];

            if (conversations) {
                const sortedReslult: any[] = conversations.sort(((a: any, b: any) => Number(moment(b?._lastMessage?.dateCreated).format('YYYYMMDDHHmmss')) - Number(moment(a?._lastMessage?.dateCreated).format('YYYYMMDDHHmmss'))));
                dispatch(addConversations([...sortedReslult]));
            }
        }
    }
};

export const getBadgeCount = (): AppThunk => async (dispatch, getstate) => {
    const conversations: any = getstate().chat?.conversations;
    dispatch(resetMessageNotifications(null));

    conversations?.forEach(async (item: any) => {
        let count = await item.getUnreadMessagesCount();
        if (count == null) {
            count = item?.lastMessage?.index >= 0 ? item.lastMessage.index + 1 : 0
        }
        item.badgeCount = count;
        dispatch(addMessageNotificationsCount(count));
    });
}

export const incrementConversationBadgeCount = (conv: any): AppThunk => async (dispatch, getstate) => {
    if (conv) {
        const activeConversation = getstate().chat?.selectedConversation;
        if (conv.sid != activeConversation?.sid) {
            const conversation = getstate().chat?.conversations.find((item: any) => item.sid == conv.sid);
            conversation.badgeCount += 1;
            dispatch(addMessageNotificationsCount(1));
        }
    }
}

export const decrementConversationBadgeCount = (conv: any): AppThunk => async (dispatch, getstate) => {
    if (conv) {
        const conversation = getstate().chat?.conversations.find((item: any) => item.sid == conv.sid);
        if (conversation.badgeCount > 0) {
            conversation.badgeCount -= 1;
            dispatch(resetMessageNotifications(1));
        }
    }
}

export const resetConversationBadgeCount = (conv: any): AppThunk => async (dispatch, getstate) => {
    if (conv) {
        const activeConversation = getstate().chat?.conversations.find((item: any) => item.sid == conv.sid);
        dispatch(resetMessageNotifications(activeConversation?.badgeCount));
        activeConversation.badgeCount = 0;
    }
}

export const chat = (state: ApplicationState) => state.chat;
export default chatSlice.reducer;

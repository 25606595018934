import { IconSizeType } from 'Models/CommonModels';
import { IPostImage } from 'Models/PostModels';
import moment from 'moment';
import constants from './constants';
import { Settings } from './Settings';

export const profileCoverPic = (image?: string | null): string => {
    if (image)
        return (Settings.profileImageUrl_coverImage + image);
    else
        return (constants.urls.CoverImgPlaceholder)
}

export const profilePic100 = (image?: string | null): string => {
    if (image)
        return (Settings.profileImageUrl_100x100 + image);
    else
        return (constants.urls.profilePlaceHolder)
}

export const profilePic200 = (image?: string | null): string => {
    if (image)
        return (Settings.profileImageUrl_200x200 + image);
    else
        return (constants.urls.profilePlaceHolder)
}

export const orgProfilePic100 = (image?: string | null): string => {
    if (image)
        return (Settings.profileImageUrl_100x100 + image);
    else
        return (constants.urls.OrgProfilePlaceHolder)
}

export const orgProfilePic200 = (image?: string | null): string => {
    if (image)
        return (Settings.profileImageUrl_200x200 + image);
    else
        return (constants.urls.OrgProfilePlaceHolder)
}

export const mobileNumberTruncate = (phoneNo: string, code?: string | null) => {
    let phoneNumber = `${Number(phoneNo)}`;

    if (code && phoneNumber.startsWith(code)) {
        phoneNumber = phoneNumber.substr(code.length);
    };

    return phoneNumber;
}

export const isValidUrl = (url: string) => {
    if (url.match(/[http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/]|[][a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/)) {
        return true;
    }
    return false;
}

export const isEmail = (text: string) => {
    if (text.match(/^\S+@\S+$/)) {
        return true;
    }
    return false;
}

export const prependHTTP = (url: string) => {
    if (
        /\S/.test(url) && // not blank space
        !/^http:\/\//.test(url) &&
        !/^https:\/\//.test(url) // doesn't include 'http://' or 'https://'
    ) {
        return "https://" + url;
    }
    return url;
};

export const getOrganizationId = (join: string) => {
    var joinParts = join.split('.');
    if (joinParts.length > 3)
        return Number(joinParts[3]);
    else
        return null
}

export const getUserProfileId = (jrin: string) => {
    var jrinParts = jrin.split('.');
    if (jrinParts.length > 3)
        return Number(jrinParts[3]);
    else
        return null
}


export const getDirectionFromText = (text: string): string => {
    const REGX__LANG = {
        AR: /^[\u0600-\u06FF]+/g,
    }
    let textDir = 'ltr';
    if (text.match(REGX__LANG.AR)) { //Check IF AR
        textDir = 'rtl'
    }
    return textDir
}

export const removeSpaces = (str: string) => {
    return str.split(' ').join('');
}

/**
 * 
 * @param data values (startYear, startMonth, startDay, endYear, endMonth, endDay)
 * @returns boolean
 */
export const validateStartBeforeEnd = (data: { startYear: string, startMonth?: string, startDay?: string, endYear?: string, endMonth?: string, endDay?: string }): boolean => {
    if (data.startYear !== "") {
        const start = `${data.startYear}-${data.startMonth}-${data.startDay}`
        const end = `${data.endYear}-${data.endMonth}-${data.endDay}`
        const startDate = moment(start, "YYYY-MM-DD")
        const endDate = moment(end, "YYYY-MM-DD")
        if (start === end) {
            return true
        }
        return moment(endDate).isAfter(startDate)
    }
    return false
}

export const getIconSize = (size: IconSizeType) => {
    switch (size) {
        case "xsmall": return { width: 16, height: 16 };
        case "small": return { width: 20, height: 20 };
        case "large": return { width: 35, height: 35 };
        default: return { width: 24, height: 24 };
    }
}

/**
 * 
 * @param url file url
 * @returns File name
 */
export const getFileNameFromUrl = (url: string) => {
    let urlString = url.split('/');
    let fileName = urlString[urlString.length - 1];
    return fileName.split('-').slice(1).join('-');
}
/**
 * 
 * @param file FileList
 * @returns boolean - is file extension is allowed
 */
export const checkFileExt = (file: any) => {
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.pdf)$/i;
    const result = allowedExtensions.exec(file[0]?.name);
    return Boolean(result)
}
/**
 * check if the inputs are only numbers
 * @param e 
 */
export const mobileNumberOnkeyPressHandler = (e: any) => {
    e = e || window.event;
    var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[0-9]+$/))
        e.preventDefault();
}
/**
 * 
 * @param file FileList
 * @returns string - file extension
 */
export const getFileExt = (file: FileList) => {
    return file[0].name.split('.').pop();
}
/**
 * 
 * @param fileName 
 * @returns boolean - is file extension is pdf
 */
export const getFileExtPDF = (fileName: string) => {
    return fileName.split('.').pop()?.toLocaleLowerCase() === "pdf";
}
/**
 * 
 * @param file File
 * @returns promise - encode as base64 digits
 */
export const processFile = (file: File): Promise<string> => {
    var reader: any = new FileReader();
    reader.readAsArrayBuffer(file);
    return new Promise((resolve, reject) => {
        reader.onload = () => {
            var array: any = new Uint8Array(reader.result);
            //@ts-ignore
            resolve(new Buffer.from(array).toString('base64'));
        };
    });
}

export const getLocalDate = (date: string, format?: string) => {
    if (!moment(date).isValid()) return "";
    return moment.parseZone(date).local().format(format);
}

export const reflectNotifiactionCountInPageTitle = (count: number) => {
    const pattern = /^\(\d+\)/;

    if (count === 0 || pattern.test(document.title)) {
      document.title = document.title.replace(
        pattern,
        count === 0 ? "" : "(" + (count - 1) + ")"
      );
    } else {
      document.title = "(" + (count - 1) + ") " + document.title;
    }
  };

  export const preventEnterKeyDefault = (e: any) => {
    if(e.keyCode == 13){
        e.preventDefault();
    }
  }


  export const analyzeImages = (images: IPostImage[]) => {
    const arrayLength = images.length < 5 ? images.length : 5;
    const isPortrait = (width: number, height: number) => {
      return width < height && width / height <= 0.75;
    };
    let numberOfPortraits = 0;
    let numberOfLandscapes = 0;
    let analyzedImages: IPostImage[] = [];
  
    for (let i = 0; i < arrayLength; i++) {
      const isPortraitImage = isPortrait(
        Number(images[i].width),
        Number(images[i].height)
      );
  
      if (isPortraitImage) {
        numberOfPortraits++;
      } else {
        numberOfLandscapes++;
      }
  
      const analyzedImage = { ...images[i], isPortraitImage };
  
      analyzedImages.push(analyzedImage);
    }
  
    //Put all portraits to the beginning of the array
    analyzedImages.sort(
      (a, b) => Number(b.isPortraitImage) - Number(a.isPortraitImage)
    );
  
    return { analyzedImages, numberOfPortraits, numberOfLandscapes };
  };
  
  //This will assign the paddingTop of Post Image Window
  export const assignWindowTopPadding = (
    numberOfImages: number,
    numberOfPortraits: number
  ) => {
    if (numberOfImages === 2 && numberOfPortraits === 1) {
      return 50;
    } else if (numberOfImages >= 5 && numberOfPortraits >= 4) {
      return 83.3333333;
    } else {
      return 100;
    }
  };


  const styleOptionResult = (numberOfPortraits: number) => {
    switch (numberOfPortraits) {
      case 0:
        return '0P';
  
      case 1:
        return '1P';
  
      case 2:
        return '2P';
      case 3:
        return '3P';
      case 4:
        return '4P';
  
      default:
        return '5P';
    }
  };


  //P: Portrait, "e.g. 1P = 1 Portrait", L: Landscape "e.g. 2L means 2 Landscape Images"
const styles = [
    {
      '0P': [
        {
          imageContainerStyle: {
            inset: 'calc(0% + 0px) calc(0% + 0px) calc(50% + 1.01px)',
          },
          wrapperStyle: { paddingTop: '50%' },
          rootStyle: { height: 'calc(133.333%)', top: '0%', width: '100%' },
        },
  
        {
          imageContainerStyle: {
            inset: 'calc(50% + 1.01px) calc(0% + 0px) calc(0% + 0px)',
          },
          wrapperStyle: {
            paddingTop: '50%',
          },
          rootStyle: { height: 'calc(133.185%)', top: '0%', width: '100%' },
        },
      ],
  
      '1P': [
        {
          imageContainerStyle: {
            inset:
              'calc(0% + 0px) calc(50% + 1.01px) calc(0% + 0px) calc(0% + 0px)',
          },
          wrapperStyle: { paddingTop: '100%' },
          rootStyle: { height: 'calc(150%)', top: '0%', width: '100%' },
        },
  
        {
          imageContainerStyle: {
            inset:
              'calc(0% + 0px) calc(0% + 0px) calc(0% + 0px) calc(50% + 1.01px)',
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: { height: 'calc(150%)', top: '0%', width: '100%' },
        },
      ],
  
      '2P': [
        {
          imageContainerStyle: {
            inset:
              'calc(0% + 0px) calc(50% + 1.01px) calc(0% + 0px) calc(0% + 0px)',
          },
          wrapperStyle: { paddingTop: '200%' },
          rootStyle: {
            height: '100%',
            left: '-19.4143%',
            width: 'calc(138.829%)',
          },
        },
  
        {
          imageContainerStyle: {
            inset:
              'calc(0% + 0px) calc(0% + 0px) calc(0% + 0px) calc(50% + 1.01px)',
          },
          wrapperStyle: {
            paddingTop: '200%',
          },
          rootStyle: {
            height: '100%',
            left: '-21.4286%',
            width: 'calc(142.857%)',
          },
        },
      ],
    },
  
    {
      //Portrait should be on the left:
      '0P': [
        {
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(0% + 0px) calc(33.3333% + 1.01px)`,
          },
          wrapperStyle: { paddingTop: '66.6667%' },
          rootStyle: {
            height: '100%',
            left: '-0.0555556%',
            width: 'calc(100.111%)',
          },
        },
  
        {
          imageContainerStyle: {
            inset: `calc(66.6667% + 1.01px) calc(50% + 1.01px) calc(0% + 0px)
                    calc(0% + 0px)`,
          },
          wrapperStyle: {
            paddingTop: '66.6667%',
          },
          rootStyle: {
            height: '100%',
            left: '0%',
            width: 'calc(100%)',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc(66.6667% + 1.01px) calc(0% + 0px) calc(0% + 0px)
                    calc(50% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '66.6667%',
          },
          rootStyle: { height: '100%', left: '0%', width: 'calc(100%)' },
        },
      ],
  
      '1P': [
        {
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(0% + 0px) calc(33.3333% + 1.01px)`,
          },
          wrapperStyle: { paddingTop: '66.6667%' },
          rootStyle: { height: 'calc(225%)', top: '-24.25%', width: '100%' },
        },
  
        {
          imageContainerStyle: {
            inset: `calc(66.6667% + 1.01px) calc(50% + 1.01px) calc(0% + 0px)
                      calc(0% + 0px)`,
          },
          wrapperStyle: {
            paddingTop: '66.6667%',
          },
          rootStyle: {
            height: '100%',
            left: '-0.0555556%',
            width: 'calc(100.111%)',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc(66.6667% + 1.01px) calc(0% + 0px) calc(0% + 0px)
                      calc(50% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '66.6667%',
          },
          rootStyle: { height: '100%', left: '0%', width: 'calc(100%)' },
        },
      ],
  
      '2P': [
        {
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(33.3333% + 1.01px) calc(0% + 0px)
                      calc(0% + 0px)`,
          },
          wrapperStyle: { paddingTop: '150%' },
          rootStyle: { height: '100%', left: '0%', width: 'calc(100%)' },
        },
  
        {
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(0% + 0px) calc(50% + 1.01px)
                      calc(66.6667% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '150%',
          },
          rootStyle: { height: '100%', left: '-62.5%', width: 'calc(225%)' },
        },
        {
          imageContainerStyle: {
            inset: `calc(50% + 1.01px) calc(0% + 0px) calc(0% + 0px)
                      calc(66.6667% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '150%',
          },
          rootStyle: { height: '100%', top: '0%', width: 'calc(100%)' },
        },
      ],
  
      '3P': [
        {
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(33.3333% + 1.01px) calc(0% + 0px)
                        calc(0% + 0px)`,
          },
          wrapperStyle: { paddingTop: '150%' },
          rootStyle: {
            height: '100%',
            left: '-2.06074%',
            width: 'calc(104.121%)',
          },
        },
  
        {
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(0% + 0px) calc(50% + 1.01px)
                      calc(66.6667% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '150%',
          },
          rootStyle: { height: '100%', left: '0%', width: 'calc(100%)' },
        },
        {
          imageContainerStyle: {
            inset: `calc(50% + 1.01px) calc(0% + 0px) calc(0% + 0px)
                      calc(66.6667% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '150%',
          },
          rootStyle: { height: '100%', top: '0%', width: 'calc(100%)' },
        },
      ],
    },
  
    {
      '0P': [
        {
          imageContainerStyle: {
            inset: `calc((0% / 6) + 0px) calc((0% / 6) + 0px) calc((200% / 6) + 1.01px) calc((0% / 6) + 0px)`,
          },
          wrapperStyle: { paddingTop: '66.66666666666667%' },
          rootStyle: {
            height: '100%',
            left: '-0.05555555555555314%',
            width: 'calc(((901 / 600) / 1.5) * 100%)',
          },
        },
  
        {
          imageContainerStyle: {
            inset: `calc((400% / 6) + 1.01px) calc((400% / 6) + 1.01px) calc((0% / 6) + 0px) calc((0% / 6) + 0px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: '100%',
            left: '-25%',
            width: 'calc(((900 / 600) / 1) * 100%)',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc((400% / 6) + 1.01px) calc((200% / 6) + 1.01px) calc((0% / 6) + 0px) calc((200% / 6) + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: '100%',
            left: '-25%',
            width: 'calc(((900 / 600) / 1) * 100%)',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc((400% / 6) + 1.01px) calc((0% / 6) + 0px) calc((0% / 6) + 0px) calc((400% / 6) + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: '100%',
            left: '-25%',
            width: 'calc(((900 / 600) / 1) * 100%)',
          },
        },
      ],
  
      '1P': [
        {
          //Should be Portrait
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(0% + 0px) calc(33.3333% + 1.01px)`,
          },
          wrapperStyle: { paddingTop: '66.6667%' },
          rootStyle: {
            height: 'calc(106.785%)',
            top: '0%',
            width: '100%',
          },
        },
  
        {
          imageContainerStyle: {
            inset: `calc(66.6667% + 1.01px) calc(66.6667% + 1.01px) calc(0% + 0px)
            calc(0% + 0px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: '100%',
            left: '0%',
            width: 'calc(100%)',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc(66.6667% + 1.01px) calc(33.3333% + 1.01px) calc(0% + 0px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: '100%',
            left: '-25.0833%',
            width: 'calc(150.167%)',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc(66.6667% + 1.01px) calc(0% + 0px) calc(0% + 0px)
              calc(66.6667% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: '100%',
            left: '-25%',
            width: 'calc(150%)',
          },
        },
      ],
  
      '2P': [
        {
          //Must be Portrait
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(33.3333% + 1.01px) calc(0% + 0px)
              calc(0% + 0px)`,
          },
          wrapperStyle: { paddingTop: '150%' },
          rootStyle: {
            height: '100%',
            left: '-2.06074%',
            width: 'calc(104.121%)',
          },
        },
  
        {
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(0% + 0px) calc(66.6667% + 1.01px)
              calc(66.6667% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: 'calc(150%)',
            top: '0%',
            width: '100%',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc(33.3333% + 1.01px) calc(0% + 0px) calc(33.3333% + 1.01px)
              calc(66.6667% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: '100%',
            left: '-25%',
            width: 'calc(150%)',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc(66.6667% + 1.01px) calc(0% + 0px) calc(0% + 0px)
              calc(66.6667% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: '100%',
            left: '-25.0833%',
            width: 'calc(150.167%)',
          },
        },
      ],
  
      '3P': [
        {
          //Must be Portrait
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(33.3333% + 1.01px) calc(0% + 0px)
              calc(0% + 0px)`,
          },
          wrapperStyle: { paddingTop: '150%' },
          rootStyle: {
            height: '100%',
            left: '-2.06074%',
            width: 'calc(104.121%)',
          },
        },
  
        {
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(0% + 0px) calc(66.6667% + 1.01px)
              calc(66.6667% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: 'calc(150%)',
            top: '0%',
            width: '100%',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc(33.3333% + 1.01px) calc(0% + 0px) calc(33.3333% + 1.01px)
              calc(66.6667% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: '100%',
            left: '-25%',
            width: 'calc(150%)',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc(66.6667% + 1.01px) calc(0% + 0px) calc(0% + 0px)
              calc(66.6667% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: 'calc(150%)',
            top: '0%',
            width: '100%',
          },
        },
      ],
  
      '4P': [
        {
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(33.3333% + 1.01px) calc(0% + 0px)
            calc(0% + 0px)`,
          },
          wrapperStyle: { paddingTop: '150%' },
          rootStyle: {
            height: '100%',
            left: '-2.06074%',
            width: 'calc(104.121%)',
          },
        },
  
        {
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(0% + 0px) calc(66.6667% + 1.01px)
            calc(66.6667% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: 'calc(150%)',
            top: '0%',
            width: '100%',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc(33.3333% + 1.01px) calc(0% + 0px) calc(33.3333% + 1.01px)
            calc(66.6667% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: '100%',
            left: '-25%',
            width: 'calc(150%)',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc(66.6667% + 1.01px) calc(0% + 0px) calc(0% + 0px)
            calc(66.6667% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: 'calc(150%)',
            top: '0%',
            width: '100%',
          },
        },
      ],
    },
  
    {
      '0P': [
        {
          //Must be Portrait
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(50% + 1.01px) calc(50% + 1.01px) calc(0% + 0px)`,
          },
          wrapperStyle: { paddingTop: '100%' },
          rootStyle: {
            height: '100%',
            left: '-25%',
            width: 'calc(150%)',
          },
        },
  
        {
          imageContainerStyle: {
            inset: `calc(50% + 1.01px) calc(50% + 1.01px) calc(0% + 0px) calc(0% + 0px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: '100%',
            left: '-25.0833%',
            width: 'calc(150.167%)',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(0% + 0px) calc(66.6667% + 1.01px)
                calc(50% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '66.6667%',
          },
          rootStyle: {
            height: '100%',
            left: '0%',
            width: 'calc(100%)',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc(33.3333% + 1.01px) calc(0% + 0px) calc(33.3333% + 1.01px)
                calc(50% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '66.6667%',
          },
          rootStyle: {
            height: '100%',
            left: '0%',
            width: 'calc(100%)',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc(66.6667% + 1.01px) calc(0% + 0px) calc(0% + 0px)
                calc(50% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '66.6667%',
          },
          rootStyle: {
            height: '100%',
            left: '0%',
            width: 'calc(100%)',
          },
        },
      ],
  
      '1P': [
        {
          //Must be Portrait
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(50% + 1.01px) calc(50% + 1.01px) calc(0% + 0px)`,
          },
          wrapperStyle: { paddingTop: '100%' },
          rootStyle: {
            height: 'calc(140%)',
            top: '0%',
            width: '100%',
          },
        },
  
        {
          imageContainerStyle: {
            inset: `calc(50% + 1.01px) calc(50% + 1.01px) calc(0% + 0px) calc(0% + 0px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: '100%',
            left: '-25%',
            width: 'calc(150%)',
          },
        },
  
        {
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(0% + 0px) calc(66.6667% + 1.01px)
                  calc(50% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '66.6667%',
          },
          rootStyle: {
            height: '100%',
            left: '-0.0555556%',
            width: 'calc(100.111%)',
          },
        },
  
        {
          imageContainerStyle: {
            inset: `calc(33.3333% + 1.01px) calc(0% + 0px) calc(33.3333% + 1.01px)
                  calc(50% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '66.6667%',
          },
          rootStyle: {
            height: '100%',
            left: '0%',
            width: 'calc(100%)',
          },
        },
  
        {
          imageContainerStyle: {
            inset: `calc(66.6667% + 1.01px) calc(0% + 0px) calc(0% + 0px)
                  calc(50% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '66.6667%',
          },
          rootStyle: {
            height: '100%',
            left: '0%',
            width: 'calc(100%)',
          },
        },
      ],
  
      '2P': [
        {
          //Must be Portrait
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(50% + 1.01px) calc(50% + 1.01px) calc(0% + 0px)`,
          },
          wrapperStyle: { paddingTop: '100%' },
          rootStyle: {
            height: 'calc(144.062%)',
            top: '0%',
            width: '100%',
          },
        },
  
        {
          imageContainerStyle: {
            inset: `calc(50% + 1.01px) calc(50% + 1.01px) calc(0% + 0px) calc(0% + 0px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: 'calc(140%)',
            top: '0%',
            width: '100%',
          },
        },
  
        {
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(0% + 0px) calc(66.6667% + 1.01px)
                  calc(50% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '66.6667%',
          },
          rootStyle: {
            height: '100%',
            left: '0%',
            width: 'calc(100%)',
          },
        },
  
        {
          imageContainerStyle: {
            inset: `calc(33.3333% + 1.01px) calc(0% + 0px) calc(33.3333% + 1.01px)
                  calc(50% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '66.6667%',
          },
          rootStyle: {
            height: '100%',
            left: '-0.0555556%',
            width: 'calc(100.111%)',
          },
        },
  
        {
          imageContainerStyle: {
            inset: `calc(66.6667% + 1.01px) calc(0% + 0px) calc(0% + 0px)
                  calc(50% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '66.6667%',
          },
          rootStyle: {
            height: '100%',
            left: '0%',
            width: 'calc(100%)',
          },
        },
      ],
  
      '3P': [
        {
          //Must be Portrait
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(50% + 1.01px) calc(50% + 1.01px) calc(0% + 0px)`,
          },
          wrapperStyle: { paddingTop: '100%' },
          rootStyle: {
            height: 'calc(144.062%)',
            top: '0%',
            width: '100%',
          },
        },
  
        {
          imageContainerStyle: {
            inset: `calc(50% + 1.01px) calc(50% + 1.01px) calc(0% + 0px) calc(0% + 0px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: 'calc(140%)',
            top: '0%',
            width: '100%',
          },
        },
  
        {
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(0% + 0px) calc(66.6667% + 1.01px)
                  calc(50% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '66.6667%',
          },
          rootStyle: {
            height: 'calc(225%)',
            top: '-24.25%',
            width: '100%',
          },
        },
  
        {
          imageContainerStyle: {
            inset: `calc(33.3333% + 1.01px) calc(0% + 0px) calc(33.3333% + 1.01px)
                  calc(50% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '66.6667%',
          },
          rootStyle: {
            height: '100%',
            left: '0%',
            width: 'calc(100%)',
          },
        },
  
        {
          imageContainerStyle: {
            inset: `calc(66.6667% + 1.01px) calc(0% + 0px) calc(0% + 0px)
                  calc(50% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '66.6667%',
          },
          rootStyle: {
            height: '100%',
            left: '-0.0555556%',
            width: 'calc(100.111%)',
          },
        },
      ],
  
      '4P': [
        {
          //Must be Portrait
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(50% + 1.01px) calc(40% + 1.01px) calc(0% + 0px)`,
          },
          wrapperStyle: { paddingTop: '100%' },
          rootStyle: {
            height: 'calc(144.062%)',
            top: '0%',
            width: '100%',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(0% + 0px) calc(40% + 1.01px) calc(50% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: 'calc(140%)',
            top: '0%',
            width: '100%',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc(60% + 1.01px) calc(66.6667% + 1.01px) calc(0% + 0px)
                  calc(0% + 0px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: 'calc(150%)',
            top: '0%',
            width: '100%',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc(60% + 1.01px) calc(33.3333% + 1.01px) calc(0% + 0px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: '100%',
            left: '-25%',
            width: 'calc(150%)',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc(60% + 1.01px) calc(0% + 0px) calc(0% + 0px)
                  calc(66.6667% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
  
          rootStyle: {
            height: 'calc(150%)',
            top: '0%',
            width: '100%',
          },
        },
      ],
  
      '5P': [
        {
          //Must be Portrait
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(50% + 1.01px) calc(40% + 1.01px) calc(0% + 0px)`,
          },
          wrapperStyle: { paddingTop: '100%' },
          rootStyle: {
            height: 'calc(144.062%)',
            top: '0%',
            width: '100%',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc(0% + 0px) calc(0% + 0px) calc(40% + 1.01px) calc(50% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: 'calc(140%)',
            top: '0%',
            width: '100%',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc(60% + 1.01px) calc(66.6667% + 1.01px) calc(0% + 0px)
                calc(0% + 0px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: 'calc(137.188%)',
            top: '0%',
            width: '100%',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc(60% + 1.01px) calc(33.3333% + 1.01px) calc(0% + 0px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: 'calc(150%)',
            top: '0%',
            width: '100%',
          },
        },
        {
          imageContainerStyle: {
            inset: `calc(60% + 1.01px) calc(0% + 0px) calc(0% + 0px)
                calc(66.6667% + 1.01px)`,
          },
          wrapperStyle: {
            paddingTop: '100%',
          },
          rootStyle: {
            height: 'calc(150%)',
            top: '0%',
            width: '100%',
          },
        },
      ],
    },
  ];

  export const assignStyleToImages = (
    numberOfPortraits: number,
    images: IPostImage[]
  ) => {
    const numberOfImages = images.length;
    let selectedStyle;
  
    let styleOption: '0P' | '1P' | '2P' | '3P' | '4P' | '5P';
    if (numberOfImages >= 2 && numberOfImages <= 5) {
      const index = numberOfImages - 2;
      const styleGroup = styles[index];
  
      if (numberOfPortraits >= 0 && numberOfPortraits <= 5 && styleGroup) {
        styleOption = styleOptionResult(numberOfPortraits);
        if (styleGroup[styleOption]) {
          selectedStyle = styleGroup[styleOption];
        }
      }
    }
  
    if (selectedStyle) {
      const styleAssignedImages = [];
      for (let i = 0; i < images.length; i++) {
        const styleAssigned = { image: images[i], styles: selectedStyle[i] };
        styleAssignedImages.push(styleAssigned);
      }
  
      return styleAssignedImages;
    }
  
    return null;
  };
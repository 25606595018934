import * as React from 'react'

const TopBorderedContainer = (props: any) => {
    return (
        <div className="border-top border-5 border-secondary shadow-sm bg-white p-3">
            {props.children}
        </div>
    )
}

export default TopBorderedContainer;
import { WebStorageStateStore } from 'oidc-client';
import { Settings } from 'Utils/Settings';

export default {
    authority: Settings.authority, //(string): The URL of the OIDC provider.
    client_id: Settings.clientAppId, //(string): Your client application's identifier as registered with the OIDC provider.
    redirect_uri: Settings.redirectUri, //The URI of your client application to receive a response from the OIDC provider.
    automaticSilentRenew: Boolean(Settings.automationSilentRenew), //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
    accessTokenExpiringNotificationTime: Number(Settings.accessTokenExpiringNotificationTime),
    silent_redirect_uri: Settings.silentRedirectUri, //(string): The URL for the page containing the code handling the silent renew.
    response_type: Settings.responseType, //(string, default: 'id_token'): The type of response desired from the OIDC provider.
    scope: Settings.scope, //(string, default: 'openid'): The scope being requested from the OIDC provider.
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    monitorSession: true
};
import * as React from 'react';
import store from 'store2';
import { Settings } from './Settings';
import userManager from './userManager';
//import * as userConfig from '../Config/userManagerConfig';

const login = () => {
        store.remove(process.env.REACT_APP_LOCAL_STORAGE_CURR__USER);
        userManager.signinRedirect();
}

const Logout = () => {
    store.remove(process.env.REACT_APP_LOCAL_STORAGE_CURR__USER);
    userManager.signoutRedirect();
}


const isAuthenticated = (): boolean => {
    let _componetn: boolean = true;
    const _store = store.getAll();

    const key: string = `oidc.user:${Settings.authority}:${Settings.clientAppId}`;
    if (!_store[key]) {
        _componetn = false;
    }
    return _componetn;
};

const getUserToken = (): string | undefined => {
    const _store = store.getAll();
    const key: string = `oidc.user:${Settings.authority}:${Settings.clientAppId}`;
    const tokenObj = _store[key];

    return tokenObj?.access_token
}

const getUserJrin = (): string | undefined => {
    const _store = store.getAll();
    const key: string = `oidc.user:${Settings.authority}:${Settings.clientAppId}`;
    const tokenObj = _store[key];

    return tokenObj?.profile.jrin
}

const SilentCallback = () => {
    React.useEffect(() => {
        userManager.signinSilentCallback();

    }, [])
    return (React.createElement("div"));
}

const SuspendUser = () => {

    store.set("isSuspended", true);

    React.useEffect(() => {
        Logout();
    }, [])
    return (React.createElement("div"));
}

const getUser = async () => {
    return await userManager.getUser().then((user) => {
        if (user) {
            return user;
        } else {
            return null;
        }
    }).catch(() => null)
}

const Auth = {
    login,
    Logout,
    isAuthenticated,
    SuspendUser,
    SilentCallback,
    getUser,
    getUserToken,
    getUserJrin
}

userManager.events.addUserSignedOut(() => {
    userManager.signoutRedirect();
})

export default Auth;
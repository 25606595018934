import { store } from 'emoji-mart';
import React from 'react';
import { useSelector } from 'react-redux';

import SharedModal from 'Shared/UI/Modal/Modal';
import { currUser } from 'Store/Features/UserSlice';
import { UserModel } from 'Models/UserModels';
import T from 'Utils/localizerHook'

const SessionExpired = () => {
    const [openModal, setOpenModal] = React.useState(false);

    const resources = {
        Title: T("Session.Expired.Title"),
        Message: T("Session.Expired.Message"),
        Ok: T("Session.Expired.Ok")
    }

    React.useEffect(() => {
        window.addEventListener("storage", (e) => localStorageSetHandler(e), false);
    }, []);

    const localStorageSetHandler = (event: any) => {
        if (event.key == "curr.user") {

            const oldValue = JSON.parse(event.oldValue) as UserModel;
            const newValue = JSON.parse(event.newValue) as UserModel;

            // if (oldValue && oldValue?.id && newValue?.id == null) {
            //     setOpenModal(true);
            // }

            if (newValue && newValue?.id && oldValue?.id == null && (newValue.isSuspended || false) == false) {
                window.location.reload();
            }
        }
    }

    const closeModalHandler = () => {
        window.location.reload();
    }

    return (
        <>
            {/* <SharedModal id={"session-expird-modal"} mdAttr="modal-dialog-centered" show={openModal}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-Name">{resources.Title}</h5>
                    </div>
                    <div className="modal-body">
                        <p>{resources.Message}</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={closeModalHandler}>{resources.Ok}</button>
                    </div>
                </div>
            </SharedModal> */}
        </>
    )
}

export default React.memo(SessionExpired);
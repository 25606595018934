import React from 'react';
import { Client as ConversationsClient } from "@twilio/conversations";
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { matchPath, useLocation } from 'react-router';

import ChatsService from 'Services/ChatsService';
import { currUser } from 'Store/Features/UserSlice';
import { messages } from 'Config/RoutesConstants';
import { addMessageNotificationStack } from 'Store/Features/Notifications/NotificationSlice';
import { GENERAL_NOTIFICATION_SOURCE, GENERAL_NOTIFICATION_TYPE, IReceivedGeneralNotification } from 'Models/Notifications';
import { addClient, setReciverUserInfo, addActiveMessages, AddActiveMessageObj, changeClientStatus, chat, messageAdded, setActiveConversationsSids, setConLoading, addConversation, sortConversations, messageDeleted, addUserTyping, removeUserTyping, getBadgeCount, resetConversationBadgeCount, incrementConversationBadgeCount, decrementConversationBadgeCount, updateMessage, setActiveMessgeLoading } from 'Store/Features/ChatSlice';
import T from 'Utils/localizerHook';

const Chats = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector(currUser);
    const chatInfo = useSelector(chat);
    const { pathname } = useLocation();

    const _hatsService = new ChatsService();

    const resources = {
        NotificationApplication: T("notifification.application"),
        NotificationImage: T("notifification.image"),
        NotificationVideo: T("notifification.video"),
        NotificationAudio: T("notifification.audio")
    }

    React.useEffect(() => {

        if (chatInfo?.client) {
            chatInfo?.client?.removeAllListeners();
            registerClientListeners(chatInfo?.client);
        }

    }, [chatInfo?.client, pathname])

    const initConversations = async () => {

        //Fetch user token
        const token = await _hatsService.getUserChatToken();

        //Create and store chat client
        const client: ConversationsClient = await ConversationsClient.create(token);

        await client.user.updateAttributes({ image: userInfo?.profilePicName, name: userInfo?.firstName + " " + userInfo?.familyName })

        registerClientListeners(client);
        dispatch(addClient(client));

        client.getSubscribedConversations()
            .then(async (conversations: any) => {
                let result = await fetchAllConversations(conversations, []);
                setTimeout(() => {
                    dispatch(sortConversations(result));
                    dispatch(getBadgeCount());
                }, 1000);
            }).finally(() => dispatch(setConLoading(false)));

        return client;
    };

    React.useEffect(() => {
        initConversations();
    }, []);



    React.useEffect(() => {

        dispatch(setConLoading(true));
        const client = chatInfo?.client;

        if (client) {

            // client.getConversationByUniqueName("210_219").then((conversation: any) => {
            //     conversation.delete();
            // });

            client?.removeAllListeners();
            registerClientListeners(client);
        }

    }, [chatInfo?.client]);

    //fetch all conversations
    const fetchAllConversations = async (conversations: any, items: any) => {
        let conversationItems: any[] = [...items];

        if (conversations) {
            conversationItems = [...conversations.items];
            if (conversations.hasNextPage) {
                const nextConversations = await conversations.nextPage();
                fetchAllConversations(nextConversations, conversationItems);
            }
        }

        return conversationItems;
    }

    const registerClientListeners = (client: any) => {

        client.on("tokenAboutToExpire", async () => {
            const token = await _hatsService.getUserChatToken();
            client.updateToken(token);
        });

        client.on("tokenExpired", async () => {
            const token = await _hatsService.getUserChatToken();
            client.updateToken(token);
        });

        client.on("connectionStateChanged", (state: any) => {
            dispatch(changeClientStatus(state));
        });


        // client.on("conversationJoined", (conversation) => {
        //     this.setState({ conversations: [...this.state.conversations, conversation] });
        //});
        // client.on("conversationLeft", (thisConversation) => {
        //     this.setState({
        //         conversations: [...this.state.conversations.filter((it) => it !== thisConversation)]
        //});

        client.on("messageAdded", async (message: any) => {
            AddTargetMessage(message);

            if (message.author != userInfo?.jrin) {
                const isMatch = matchPath(pathname, messages.pattern);
                if (!isMatch) {

                    const paricipant = await message.getParticipant();
                    const user = await paricipant.getUser();


                    let dataMessage = "";

                    if (message?.media) {
                        switch (message.media.contentType) {
                            case message.media.contentType?.includes("image"):
                                dataMessage = JSON.stringify({ body: resources.NotificationImage })
                                break;
                            case message.media.contentType?.includes("video"):
                                dataMessage = JSON.stringify({ body: resources.NotificationVideo })
                                break;
                            case message.media.contentType?.includes("audio"):
                                dataMessage = JSON.stringify({ body: resources.NotificationAudio })
                                break;
                            default:
                                dataMessage = JSON.stringify({ body: resources.NotificationApplication })
                                break;
                        }
                    } else {
                        dataMessage = message.body ? JSON.stringify({ body: message.body }) : "";
                    }

                    const nofificationItem: IReceivedGeneralNotification = {
                        Source: GENERAL_NOTIFICATION_SOURCE.UserProfile,
                        Type: GENERAL_NOTIFICATION_TYPE.SendMessage,
                        Data: dataMessage,
                        Id: message.sid,
                        InitiatorFullName: user?.attributes?.name,
                        Date: moment().format(),
                        InitiatorPicture: user?.attributes?.image,
                        InitiatorId: 0,
                        TargetProfileId: 0
                    }

                    dispatch(addMessageNotificationStack(nofificationItem));
                }

                dispatch(incrementConversationBadgeCount(message.conversation));
            }
        });

        client.on("messageUpdated", (message: any) => {
            dispatch(updateMessage(message));
        });

        client.on("messageRemoved", (message: any) => {
            dispatch(messageDeleted(message));
            dispatch(decrementConversationBadgeCount(message.conversation));
        });

        client.on("conversationJoined", (conversation: any) => {
            dispatch(addConversation(conversation));
        });

        client.on("typingStarted", (participant: any) => {
            dispatch(addUserTyping(participant));
        });

        client.on("typingEnded", (participant: any) => {
            dispatch(removeUserTyping(participant));
        });
    }

    React.useEffect(() => {
        const selectedConversation = chatInfo?.selectedConversation;

        if (selectedConversation && userInfo?.jrin) {
            dispatch(setReciverUserInfo(selectedConversation, userInfo?.jrin));

            if (!chatInfo?.activeConversations.some(item => item == selectedConversation.sid)) {
                dispatch(setActiveMessgeLoading(true));
                dispatch(setActiveConversationsSids(selectedConversation.sid));

                selectedConversation.getMessages()
                    .then((newMessages: any) => {
                        if (newMessages.items) {
                            dispatch(AddActiveMessageObj(newMessages));
                            dispatch(addActiveMessages(newMessages.items));
                            selectedConversation.updateLastReadMessageIndex(selectedConversation?._lastMessage?.index || 0);
                        }
                    })
                    .finally(() => dispatch(setActiveMessgeLoading(false)));
            }
            dispatch(resetConversationBadgeCount(selectedConversation));
        }

    }, [chatInfo?.selectedConversation]);

    const AddTargetMessage = (message: any) => {
        dispatch(messageAdded(message));
    }


    return <></>
}

export default Chats;
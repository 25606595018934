import { BaseService } from "./Core/BaseService";
import { IPost, IPostComment, IPostImage, IPostMentions, IUrlPreview } from '../Models/PostModels';
import { ILikes } from '../Models/PostModels';

export default class PostService extends BaseService {

    public async listTimelinePosts(jrin: string, pageSize: number, paginationToken: string = ""): Promise<{ items: Array<IPost>; paginationToken: string }> {
        const url = 'Posts/ListUserPosts';
        const params = {
            jrin: jrin,
            paginationToken: paginationToken,
            pageSize: pageSize
        }
        return this.ProtectedApi.get(url, {
            params: params
        })
            .then(response => response.data)
            .catch(error => {
                throw error;
            })
    }

    public async listOrgTimelinePosts(orgId: number, pageSize: number, paginationToken: string = ""): Promise<{ items: Array<IPost>; paginationToken: string }> {
        const url = 'Posts/ListOrgPosts';
        const params = {
            orgId: orgId,
            paginationToken: paginationToken,
            pageSize: pageSize
        }
        return this.ProtectedApi.get(url, {
            params: params
        })
            .then(response => response.data)
            .catch(error => {
                throw error;
            })
    }

    public async listFeedsPosts(pageSize: number, paginationToken: string = ""): Promise<{ items: Array<IPost>; paginationToken: string }> {
        const url = 'Posts/ListFeedPosts';
        const params = {
            pageSize: pageSize,
            paginationToken: paginationToken
        }
        const res = await this.ProtectedApi.get(url, {
            params: params
        })
            .then(response => {
                if (response.data) {
                    const items: Array<IPost> = response.data?.items;
                    const newToken: string = response.data?.paginationToken;
                    if (!Boolean(items?.length) && newToken !== "") {
                        return this.listFeedsPosts(pageSize, newToken)
                    }
                    else {
                        return response.data
                    }
                }
            })
            .catch(error => {
                throw error;
            })
        return res
    }

    public async getPost(postId: string, commentId?: string): Promise<IPost> {
        const url = 'Posts/LoadPost';
        const params = {
            postId: postId,
            commentId: commentId
        }
        return this.ProtectedApi.get(url, {
            params: params
        })
            .then(response => response.data)
            .catch(error => {
                throw error;
            })
    }

    public async addPost(Post: IPost): Promise<boolean> {
        const url = `Posts/AddPost`;
        const data = {
            content: {
                plainText: Post.plainText,
                rawText: Post.content
            },
            linkPreview: Post.linkPreview,
            schemaVersion: Post.schemaVersion,
            imagesUrls:Post.imagesUrls,
            taggedProfiles: Post.postMentions?.taggedProfiles,
            taggedISAs: Post.postMentions?.taggedISAs,
            taggedWorks: Post.postMentions?.taggedWorks,
            taggedOrgProfiles:Post.postMentions?.taggedOrgProfiles,
            privacy: Post.privacy,
            sharedPostId:Post.sharedPostId
        }
        return this.ProtectedApi.post(url, data)
            .then(response => response.data)
            .catch(error => {
                this.throwException(error)
                throw error;
            })
    }

    public async addOrgPost(Post: IPost, orgId: number): Promise<boolean> {
        const url = `Posts/AddOrgPost`;
        const data = {
            orgId: orgId,
            content: {
                plainText: Post.plainText,
                rawText: Post.content
            },
            linkPreview: Post.linkPreview,
            schemaVersion: Post.schemaVersion,
            imagesUrls:Post.imagesUrls,
            taggedProfiles: Post.postMentions?.taggedProfiles,
            taggedISAs: Post.postMentions?.taggedISAs,
            taggedWorks: Post.postMentions?.taggedWorks,
            taggedOrgProfiles:Post.postMentions?.taggedOrgProfiles,
            sharedPostId:Post.sharedPostId
        }
        return this.ProtectedApi.post(url, data)
            .then(response => response.data)
            .catch(error => {
                this.throwException(error)
                throw new Error(error);
            })
    }

    public async updatePost(Post: IPost): Promise<boolean> {
        const url = `Posts/UpdatePost`;
        const data = {
            postId: Post.id,
            content: {
                plainText: Post.plainText,
                rawText: Post.content
            },
            linkPreview: Post.linkPreview,
            schemaVersion: Post.schemaVersion,
            taggedProfiles: Post.postMentions?.taggedProfiles,
            taggedISAs: Post.postMentions?.taggedISAs,
            taggedWorks: Post.postMentions?.taggedWorks,
            taggedOrgProfiles:Post.postMentions?.taggedOrgProfiles,
            privacy: Post.privacy,
            imagesUrls:Post.imagesUrls
        }
        return this.ProtectedApi.put(url, data)
            .then(response => response.data)
            .catch(error => {
                this.throwException(error)
                throw error;
            })
    }

    public async deletePost(id: string, isOrgPost: boolean): Promise<boolean> {
        const url = 'Posts/DeletePost';
        return this.ProtectedApi.delete(url, {
            params: {
                postId: id,
                isOrgPost: isOrgPost
            }
        })
            .then(() => true)
            .catch((error) => {
                this.throwException(error)
                throw error;
            })
    }

    public async getLikes(postId: string, pageSize: number, paginationToken?: string): Promise<ILikes> {
        const params = { postId: postId, pageSize: pageSize, paginationToken: paginationToken };
        return this.ProtectedApi.get('Posts/ListLikes', { params })
            .then(response => {
                const data: ILikes = {
                    items: response.data.items,
                    paginationToken: response.data.paginationToken
                };
                return data;
            })
            .catch((error) => {
                throw error;
            })
    }

    public async likePost(postId: string, ownerId: number, isOrgPost: boolean): Promise<{ isLiked: boolean, likeId: string }> {
        const url = 'Posts/LikePost'
        const data = {
            postId: postId,
            ownerId: ownerId,
            isOrgPost: isOrgPost
        }
        return this.ProtectedApi.post(url, data)
            .then(response => {
                return { isLiked: true, likeId: response.data }
            })
            .catch((error) => {
                this.throwException(error);
                throw error;
            })
    }

    public async unlikePost(postId: string, likeId: string, ownerId: number, isOrgPost: boolean): Promise<boolean> {
        return this.ProtectedApi.delete('Posts/UnLike', {
            params: {
                likeId: likeId,
                postId: postId,
                ownerId: ownerId,
                isOrgPost: isOrgPost
            }
        })
            .then(() => true)
            .catch((error) => {
                this.throwException(error);
                throw error;
            })
    }

    public async listPostComments(postId: string, pageSize: number, paginationToken: string = ""): Promise<{ items: Array<IPostComment>; paginationToken: string }> {
        const url = 'Posts/ListComments';
        const params = {
            postId: postId,
            pageSize: pageSize,
            paginationToken: paginationToken
        }
        return this.ProtectedApi.get(url, {
            params: params
        })
            .then(response => {
                if (response.data)
                    return response.data
            })
            .catch((error) => {
                throw error;
            })
    }

    public async addComment(postId: string, postOwnerId: number, comment: IPostComment, isOrgPost: boolean): Promise<IPostComment> {
        const url = 'Posts/AddComment'
        const data = {
            postId: postId,
            content: {
                plainText: comment.plainText,
                rawText: comment.content
            },
            ownerId: postOwnerId,
            isOrgPost: isOrgPost
        }
        return this.ProtectedApi.post(url, data)
            .then(response => {
                const newComment: IPostComment = { ...comment, id: response.data }
                return newComment
            }).catch(error => {
                this.throwException(error);
                throw error;
            });

    }

    public async updateComment(postId: string, postOwnerId: number, comment: IPostComment, isOrgPost: boolean): Promise<IPostComment> {
        const url = 'Posts/UpdateComment'
        const data = {
            postId: postId,
            commentId: comment.id,
            content: {
                plainText: comment.plainText,
                rawText: comment.content
            },
            ownerId: postOwnerId,
            isOrgPost: isOrgPost
        }
        return this.ProtectedApi.put(url, data)
            .then(() => {
                const newComment: IPostComment = { ...comment }
                return newComment
            }).catch(error => {
                this.throwException(error, { errorCode: 404, msgKey: "Comment.Not.Found" });
                throw error;
            });

    }

    public async deleteComment(postId: string, postOwnerId: number, commentId: string, commentOwnerId: number, isOrgPost: boolean): Promise<boolean> {
        const url = 'Posts/DeleteComment'
        const params = {
            commentId: commentId,
            postId: postId,
            ownerId: postOwnerId,
            commentOwnerId: commentOwnerId,
            isOrgPost: isOrgPost
        };
        return this.ProtectedApi.delete(url, {
            params: params
        })
            .then(() => true).catch(error => {
                this.throwException(error);
                throw error;
            });
    }

    public async urlScraper(link: string): Promise<IUrlPreview> {
        const url = 'WebScraper/GetMetadata'
        const data = {
            url: link
        };
        return this.ProtectedApi.post(url, data)
            .then(response => {
                return response.data
            })
    }

    public async addPostImages(images:Array<IPostImage>): Promise<Array<IPostImage>> {
        const url = 'Posts/AddPostImages'
        const data = {
            images:images
        }
        return this.ProtectedApi.post(url, data)
            .then(response => {
                return response.data
            }).catch(error => {
                this.throwException(error);
                throw error;
            });

    }

    public async deletePostImages(images:Array<IPostImage>): Promise<boolean> {
        const url = 'Posts/DeletePostImages';
        const data = {
            images:images
        }
        return this.ProtectedApi.post(url,data)
            .then(() => true)
            .catch((error) => {
                this.throwException(error)
                throw error;
            })
    }

}
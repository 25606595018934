import React from 'react';

import syles from "./Offline.module.scss";
import { WifiOff } from '@material-ui/icons';
import T from 'Utils/localizerHook';

const Offline = (props: any) => {
    return <div className={`text-center ${syles.offlineContent} pt-5`}>
        <WifiOff className={`${syles.image} text-primary mt-5`} />
        <h4>{T("OfflinePage.SomethingWentWrong")}</h4>
        <h6>{T("OfflinePage.RefreshThePage")}</h6>
        <br />
        <button onClick={() => window.location.reload()} type="button" className="btn btn-outline-primary rounded-pill">{T("offlinepage.tryagain")}</button>
    </div>
}
export default Offline;
//TODO: [Marwan] Merge with sohaib models
import { IEmail, IOrgEmail } from "./EmailAddressModels";
import { IMobile, IOrgMobile } from "./MobileModels";
import { IPaginatedList, PRIVACY, PROFILE_TYPE, SOURCE } from "./CommonModels";

import { INetworkStatus } from "./NetworkModels";
import { IISA } from "./InnovationInterestModels";
import { ISocialLinks } from "./SocialLinkModels";
import { IOrgProfileIDs } from "./OrganizationModels";


export enum PROFILE_ACTION {
  DeleteConnection = "1",
  Follow = "2",
  Bookmark = "3",
  AskReferral = "4",
  ReportSpam = "5"
}

export interface IProfileID {
  id: number;
  name: string;
  value: string;
  url: string;
  privacy: number;
}

export interface IActorProfile {
  id: number;
  fullName: string;
  profilePicName: string;
  profileTitle?: string;
  joipId: string;
  isIdentityVerified: boolean;
  isOrg?: boolean;
}

export interface IAlternativeName {
  id: number;
  name: string;
  privacy: number;
  source?: any;
}

export interface IOverViewInfo {
  overViewInfo: {
    firstName: string;
    familyName: string;
    title: string;
    titleAttrId: number;
    titlePrivacy: number;
    workplace: string;
    workplaceAttrId: number;
    workplacePrivacy: number;
    university: string;
    universityAttrId: number;
    universityPrivacy: number;
    about: string;
    aboutAttrId: number;
    aboutPrivacy: number;
  };
  alternativeNames: Array<IAlternativeName>;
}

export interface IOrgOverViewInfo {
  orgOverviewDto: {
    name: string;
    about: string;
    orgTypeId: any;
    foundationDate: any;
    join: any;
  };
  alternativeNames: Array<IOrgAlternativeName>;
}

export interface IOrgAlternativeName {
  name: string;
  id: number;
}

export interface IAbout {
  about: string;
  aboutAttrId: number;
  aboutPrivacy: number;
  alternativeNames: Array<IAlternativeName>;
  emailAddresses: Array<IEmail>;
  userProfileMobiles: Array<IMobile>;
  address: IAddress;
  basicInfo: IBasicInfo;
  profileMyIds : Array<IProfileID>;
  socialLinks : ISocialLinks
}

export interface IOrgAbout {
  id: number,
  about: string,
  foundationDate: string,
  alternativeNames: Array<IOrgAlternativeName>,
  emailAddresses: Array<IOrgEmail>,
  orgPorfileMobiles: Array<IOrgMobile>,
  orgProfileIds : Array<IOrgProfileIDs>,
  orgSocialLinks : ISocialLinks
}


export interface IBaseAddress {
  id?: number;
  address?: string;
  postalCode?: string;
  countryId?: number | string;
  countryName?: string;
  cityId?: number | string;
  cityName?: string;
}

export interface IAddress extends IBaseAddress {
  privacy?: PRIVACY;
}

export interface IOrgAddress extends IBaseAddress { }

export interface IAlternativeName {
  id: number;
  name: string;
  privacy: number;
  source?: any;
}

export interface IBasicInfo {
  id?: number;
  dateOfBairth?: string;
  dateOfBairthPrivacy?: PRIVACY;
  dateOfBairthAttrId?: number;
  nationalityId?: number;
  nationalityPrivacy?: PRIVACY;
  nationalityAttrId?: number;
  nationalityName?: string;
  genderId?: GENDER;
  genderPrivacy?: PRIVACY;
  genderAttrId?: number;
}

export interface IProfileData {
  profileUserId: number;
  firstName: string;
  familyName: string;
  fullName: string;
  jrin: string;
  title: string | null;
  profilePicName: string | null;
  profileCoverPicName: string | null;
  university: string | null;
  workplace: string | null;
  emailAddress: string | null;
  phoneNo: string | null;
  networkStatus: INetworkStatus | null;
  bookmarkId: number | null;
  isIdentityVerified: boolean;
}

export enum GENDER {
  Male = 1,
  Female = 2,
}

export enum PROFILE_ATTRIBUTE {
  About = 4,
  Title = 5,
  University = 6,
  WorkPlace = 7,
  Birthdate = 2,
  GenderId = 3,
  Nationality = 1,
}

export interface IGuide {
  id: number;
  title: string;
  content: string;
  actionName: string;
  actionURL: string;
  isPrimary: boolean;
  imageURL: string;
}

export interface IBasicInfo {
  id?: number;
  dateOfBairth?: string;
  dateOfBairthPrivacy?: PRIVACY;
  dateOfBairthAttrId?: number;
  nationalityId?: number;
  nationalityPrivacy?: PRIVACY;
  nationalityAttrId?: number;
  nationalityName?: string;
  genderId?: GENDER;
  genderPrivacy?: PRIVACY;
  genderAttrId?: number;
}

export interface IProfileIDs {
  id: number;
  name: string;
  value: string;
  url: string;
  privacy: number;
  source: number;
}

export interface IContactAndBasicInfoContent {
  emailAddresses: IEmail[];
  userProfileMobiles: IMobile[];
  basicInfo?: IBasicInfo;
  address?: IAddress;
}

export interface IOrgContactContent {
  emailAddresses: IOrgEmail[];
  profileMobiles: IOrgMobile[];
  address?: IOrgAddress;
}

export interface IBaseOrgProfile {
  email?: string;
  typeId: number;
  name: string;
  foundationDate?: any;
  ringGoldId?: string;
  biography?: string;
  countryId?: number | null;
  cityId?: number | null;
  mobileCountryId?: number | null;
  mobileNumber?: string | null;
  fullMobileNumber?: string | null;
  websites?: string[];
  alternativeNames?: string[];
  identifiers?: {};
}

export interface IProfileVisit {
  profileId: number;
  profileName: string;
  profilePicture: string;
  profileJoipId: string;
  visitCount: number;
  lastVisitDate: string;
  profileType: PROFILE_TYPE;
  isIdentityVerified: boolean;
}

export interface ICompletenessCard {
  id: number,
  item: string,
  route: string
}

export interface ICompletenessCardInfo {
  completenessCardList: Array<ICompletenessCard>,
  completenessValue: number
}

export interface IUserAchievements {
  profileVisibility: number;
  researchDevelopmentAge: number;
  outputsCount: number;
  referralsCount: number;
  connectionsCount: number;
  followersCount: number;
  followingCount: number;
  collaboratorsCount: number;
  postsCount: number;
  likesCount: number;
  commentsCount: number;
  viewsCount: number;
}

export interface IOrgAchievements {
  researchDevelopmentAge: number;
  outputsCount: number;
  followersCount: number;
  postsCount: number;
  likesCount: number;
  commentsCount: number;
  viewsCount: number;
  productsCount: number;
  servicesCount: number;
}


export interface IUserEmployment {
  id?: number,
  userProfileId?: number,
  orgProfileId: number | null,
  orgName: string,
  roleTitle?: string,
  departmentName: string,
  city: string,
  url?: string,
  countryId: number,
  startYear: number | null,
  startMonth: number | null,
  startDay: number | null,
  endYear: number | null,
  endMonth: number | null,
  endDay: number | null,
  isVerified?: boolean,
  professionId: number | null,
  professionSubTypeId: number | null,
  source?: SOURCE,
  privacy?: PRIVACY,
  countryName?: string,
  orcidSourceName?: string,
  profileName?: string
}


export interface IUserEducation {
  id?: number,
  userProfileId: number,
  orgProfileId?: number,
  orgName: string,
  degreeTypeId?: string,
  degreeOptionsId?: string,
  degreeTitle?: string,
  faculty: string,
  departmentName: string,
  city: string,
  url: string,
  countryId: string,
  startYear?: string,
  startMonth?: string,
  startDay?: string,
  endYear?: string,
  endMonth?: string,
  endDay?: string,
  source: SOURCE,
  privacy?: PRIVACY,
  countryName?: string,
  orcidSourceName?: string,
  isAs?: IISA[];
}

export interface IOrgCustomer {
  id: number,
  orgProfileId: number,
  name: string,
  countryId: number,
  countryName: string
}



export enum VERIFICATION_DOC_TYPE {
  NationalID = 1,
  Passrort = 2
}


export enum VERIFICATION_STATUS {
  Pending = 1,
  Accepted = 2,
  Declined = 3
}
interface IIdentityVerificaionBasicInfoBase {
  emailAddress?: string,
  phoneNo?: string,
  declineReason?: string,
  statusDateTime?: string,
  verificationStatusId?: VERIFICATION_STATUS,
}
export interface IIdentityVerificaionBasicInfo extends IIdentityVerificaionBasicInfoBase {
  firstName: string,
  familyName: string,
  countryId?: string,
  idBackDocument?: string,
  idFrontDocument?: string,
  passportDocument?: string,
  docType: VERIFICATION_DOC_TYPE,
  addressCountryId?: string
}
export interface IOrgIdentityVerificaionBasicInfo extends IIdentityVerificaionBasicInfoBase {
  orgName: string,
  countryId?: string,
  phoneCountryId?: string,
  documents: IDocument[]
}
interface IIdentityVerificationBase {
  id?: number,
  verificationStatusId: VERIFICATION_STATUS,
  createDateTime: string,
  email: string;
  mobileNumber: string;
  fullMobileNumber: string;
  declineReason?: string,
  statusDateTime?: string
}
export interface IIdentityVerification extends IIdentityVerificationBase {
  firstName: string,
  lastName: string,
  verificationDocumentTypeId: VERIFICATION_DOC_TYPE,
  idBackDocumentURL?: string,
  idFrontDocumentURL?: string,
  passportDocumentURL?: string,
  idBackDocumentExt?: string,
  idFrontDocumentExt?: string,
  passportDocumentExt?: string,
  countryId: string,
  countryName: string,
}
export interface IOrgIdentityVerification extends IIdentityVerificationBase {
  orgName: string,
  countryId: string,
  countryName: string,
  mobileCountryId: string,
  mobileCountryName: string,
  documents: IDocument[]
}
export interface IDocument {
  documentTitle: string,
  documentURL: string,
  documentExt?: string
}
export interface CurriculumVitaeModel {
  userId: number,
  jrin: string,
  jrinurl: string,
  fullName: string,
  title: string,
  workplace: string,
  countryName: string,
  cityName: string,
  about: string,
  email: string,
  mobile: string,
  employments: CurriculumVitaeSharedModel[],
  educations: CurriculumVitaeSharedModel[],
  distinctions: CurriculumVitaeSharedModel[],
  qualifications: CurriculumVitaeSharedModel[],
  invitedPositions: CurriculumVitaeSharedModel[],
  memberships: CurriculumVitaeSharedModel[],
  services: CurriculumVitaeSharedModel[],
  profileIdentifiers: CurriculumVitaeProfileIdentifierModel[],
  socialLinks: CurriculumVitaeSocialLinkModel[],
  works: CurriculumVitaeWorkModel[],
  referrals: CurriculumVitaeReferral[],
  isas: CurriculumVitaeISAModel[]
}

export interface CurriculumVitaeReferral {
  refereeFullName: string,
  refereeJRIN: string,
  message: string,
  createDateTime: string
}
export interface CurriculumVitaeWorkModel {
  title: string,
  jdoi: string,
  jdoiurl: string,
  journalName: string,
  workTypeName: string,
  createDateTime: string,
  profileName: string,
  workCategoryName: string,
  publicationYear?: number,
  publicationMonth?: number,
  publicationDay?: number
}
export interface CurriculumVitaeProfileIdentifierModel {
  name: string,
  url: string,
  value: string,
  source: SOURCE
}
export interface CurriculumVitaeSocialLinkModel {
  name: string,
  url: string
}
export interface CurriculumVitaeSharedModel {
  orgName: string,
  departmentName: string,
  title: string,
  startYear?: number,
  startMonth?: number,
  startDay?: number,
  endYear?: number,
  endMonth?: number,
  endDay?: number,
  city: string,
  countryName: string,
  faculty?: string
}
export interface CurriculumVitaeISAModel {
  isaName: string,
}
export interface IProfileViewsPagination {
  pageIndex: number;
  pageSize: number;
}

export interface IPaginatedListProfileViews<Type> {
  paginatedList: IPaginatedList<Type>,
  isVisitLogEnabled: boolean
}

export interface ICard {
  id: number;
  profileId: number;
  name: string;
  firstName?: string;
  pictureName: string;
  title: string;
  visitDate: string;
  jrin: string;
  networkStatus?: INetworkStatus;
  isIdentityVerified: boolean;
  isRead?: boolean;
}

export interface IActivityLog {
  items: IActivityLogItem[],
  paginationToken: string
}

export interface IActivityLogItem {
  activityId: string,
  activityCategory: ACTIVITY_CATEGORY,
  createDateTime: string,
  year: number,
  month: number,
  day: number,
  activityType: ACTIVITY_TYPE,
  itemData: string
}

export enum ACTIVITY_CATEGORY {
  ALL = 0,
  Posting = 1,
  Network = 2,
  Org = 3
}

export enum ACTIVITY_TYPE {
  CreatePost = 1,
  UpdatePost = 2,
  RemovePost = 3,
  CreateComment = 4,
  UpdateComment = 5,
  DeleteCommentByCommentOwner = 6,
  LikePost = 7,
  UnlikePost = 8,
  Connect = 9,
  Unconnect = 10,
  Follow = 11,
  Unfollow = 12,
  CreateOrg = 13,
  RemoveOrg = 14,
  DeleteCommentByPostOwner = 15,
  SharePost=16
}

export interface IRingGoldDataSearch {
  searchTerm: string;
  country: string;
  city: string;
  postCode: string;
  pageIndex: number;
}

export interface RingGoldSearchItemsModel {
  hasNextPage: boolean
  hasPreviousPage: boolean
  items: IRingGoldInfo[]
  pageIndex: number
  totalCount: number
  totalPages: number
}

export interface IRingGoldInfo {
  id: number,
  name: string,
  postCode?: string,
  type?: string,
  city?: string,
  country?: string,
  countryIso?: string,
  adminAreaLevelOne?: string,
  adminAreaLevelTwo?: string,
  adminAreaLevelThree?: string,
  adminAreaLevelFour?: string,
  adminAreaLevelFive?: string,
  selfDescriptions?: Array<string>,
  activityStatus?: string,
  governances?: Array<string>,
  economicModel?: string,
  level?: string,
  numberOfSites?: string,
  tier?: string,
  size?: string
  staff?: string,
  identifiers?: {},
  urls?: Array<string>,
  alternativeNames?: Array<string>,
  missions?: Array<string>,
  subjects?: Array<string>,
  reach?: string
}

export interface IUserProfileStatistics {
  researchers: number,
  orgs: number,
  publications: number,
  userPublications: number,
  posts: number
}


export interface IReportSpamParams {
  reasonId: number;
  reasonDesc: string;
  userProfileId?:number;
  orgProfileId?:number;
}
import axios, { AxiosInstance } from 'axios';
import { Settings } from 'Utils/Settings';
import auth from '../Utils/Auth';
import userMgr from '../Utils/userManager';

const instance: AxiosInstance = axios.create({
    baseURL: Settings.apiBaseUri
});

instance.interceptors.request.use(async (config) => {
    let user = await userMgr.getUser().then((user) => user).catch(() => null);
    if (user?.expired) {
        await userMgr.signinSilent().catch((error) => { auth.login(); });
        user = await userMgr.getUser().then((user) => user).catch(() => null);
    }
    // Do something before request is sent
    config.headers = {
        'Authorization': `Bearer ${user?.access_token}`,
        'Access-Control-Allow-Methods': 'HEAD, GET, POST, PUT, PATCH, DELETE',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.status === 401) {
        auth.login();
    }

    return Promise.reject(error);
});

export default instance;




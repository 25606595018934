import React from 'react';
import { FieldError } from 'react-hook-form';

import InputLabel from '../InputLabel/InputLabel';
import styles from './Select.module.scss'

interface IProps {
    values: Array<any>,
    name: string;
    value: any;
    reg?: any;
    placeholder?: string;
    label?: string;
    hint?: string;
    disabled?: boolean;
    loading?: boolean;
    isRequired?: boolean;
    onChangeHandler?: any;
    onBlurHandler?: any;
    errors?: FieldError | undefined;
    selectStyles?: string
}

const Select: React.FC<IProps> = (props: IProps) => {

    const Spinner: React.ReactNode = (
        <div className={`spinner-border ${styles.Spinner}`} role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    )
    return (
        <div className="position-relative">
            <InputLabel label={props.label} hint={props.hint} isRequired={props.isRequired} />
            {props?.loading && Spinner}
            <select
                className={`form-select form-control ${props.selectStyles} ${!props.disabled && props.errors && props.errors.message ? "is-invalid" : ''}`}
                name={props.name}
                ref={props.reg}
                disabled={props.disabled ? props.disabled : false}
                onChange={(event) => props.onChangeHandler ? props.onChangeHandler(event) : null}
                onBlurCapture={(event) => props.onBlurHandler ? props.onBlurHandler(event) : null}
                value={props.value ? props.value : ''}
            >
                {props.values
                    ? props.values.map((option, i: any) =>
                        <option key={i}
                            value={option.value}
                            hidden={option.hidden}
                        >
                            {option.label}
                        </option>
                    )
                    : null}
            </select>
            <div className="invalid-feedback d-block">
                {!props.disabled ? props.errors && props.errors.message : ''}
            </div>
        </div>
    )
}

export default Select;

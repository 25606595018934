import { suspendUser } from 'Config/RoutesConstants';
import { UserModel } from 'Models/UserModels';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import UserService from 'Services/UserService';
import { currUser } from 'Store/Features/UserSlice';
import Auth from './Auth';
import userManager from './userManager';

const CheckUser = () => {
    const userInfo = useSelector(currUser);
    const _userService = new UserService();
    const history = useHistory();

    React.useEffect(() => {
        if(userInfo?.id && Auth.isAuthenticated()){
            window.addEventListener('focus', () => {
                getUser();
            });

            userManager.events.addAccessTokenExpiring(() => {
                getUser();
            })
        }
    }, [userInfo?.id]);

    const getUser = () =>{     
        _userService.getUserInfo()
        .then((user: UserModel) => {
            if (user.isSuspended) {
                history.push(suspendUser.route);
            }
        });
    }
    return React.createElement("div");
}

export default CheckUser;
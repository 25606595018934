import { BaseService } from "./Core/BaseService";


export default class ChatsService extends BaseService {

    public async getUserChatToken(): Promise<string> {
        return this.ProtectedApi.get('Chats/GetToken')
            .then((response) => response.data)
            .catch((error) => {
                throw error
            })
    }
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import store from 'store2';

import { ApplicationState, AppThunk } from '../index';
import { UserModel } from 'Models/UserModels';

const initialState: UserModel = { id: 0, firstName: '', familyName: '', profilePicName: '', jrin: '' };

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        addUser: (state, action: PayloadAction<UserModel>) => {
            const user = action.payload;
            state.id = user.id;
            state.firstName = user.firstName;
            state.familyName = user.familyName;
            state.jrin = user.jrin;
            state.profilePicName = user.profilePicName;
            state.orgs = user.orgs;
            state.isWelcomeMessageDismessed = user.isWelcomeMessageDismessed;
            state.isIdentityVerified = user.isIdentityVerified;
            state.isEmailVerified = user.isEmailVerified;
            state.isSuspended = user.isSuspended;
            state.paperSubmission = {
                isChairman: user.paperSubmission?.isChairman || false,
                isReviewer: user.paperSubmission?.isReviewer || false,
                isSubmitter: true
            }
            store.set(process.env.REACT_APP_LOCAL_STORAGE_CURR__USER, user);
        },
        getUserInfo: (state) => state,
        updateUser: (state, action: PayloadAction<UserModel>) => {
            const user = action.payload;
            if (user) {
                state.id = user.id ? user.id : state.id;
                state.firstName = user.firstName ? user.firstName : state.firstName;
                state.familyName = user.familyName ? user.familyName : state.familyName;
                state.jrin = user.jrin ? user.jrin : state.jrin;
                state.profilePicName = user.profilePicName ? user.profilePicName : state.profilePicName;
                state.orgs = user.orgs ? user.orgs : state.orgs;
                state.isWelcomeMessageDismessed = user.isWelcomeMessageDismessed ? user.isWelcomeMessageDismessed : state.isWelcomeMessageDismessed;
                state.isIdentityVerified = user.isIdentityVerified ? user.isIdentityVerified : state.isIdentityVerified;
                state.isEmailVerified = user.isEmailVerified ? user.isEmailVerified : state.isEmailVerified;
                state.isSuspended = user.isSuspended ? user.isSuspended : state.isSuspended;
                store.set(process.env.REACT_APP_LOCAL_STORAGE_CURR__USER, state);
            }
        },
        updateUserChairmanPermission: (state, action: PayloadAction<boolean>) => {
            const isChairman = action.payload;

            state.paperSubmission = {
                isChairman: isChairman,
                isReviewer: state.paperSubmission?.isReviewer || false,
                isSubmitter: true
            }
            store.set(process.env.REACT_APP_LOCAL_STORAGE_CURR__USER, state);
        },
        updateUserReviewerPermission: (state, action: PayloadAction<boolean>) => {
            const isReviewer = action.payload;

            state.paperSubmission = {
                isChairman: state.paperSubmission?.isChairman || false,
                isReviewer: isReviewer,
                isSubmitter: true
            }
            store.set(process.env.REACT_APP_LOCAL_STORAGE_CURR__USER, state);
        }
    }
});


export const { addUser, getUserInfo, updateUser, updateUserChairmanPermission, updateUserReviewerPermission } = userSlice.actions;

export const setUserFromLocalStorge = (): AppThunk => (dispatch, getstate) => {
    const user = store.get(process.env.REACT_APP_LOCAL_STORAGE_CURR__USER) as UserModel;
    if (user) {
        dispatch(addUser(user));
    }
};

export const currUser = (state: ApplicationState) => state.user;
export default userSlice.reducer;

import store from "store2";
import { IConfigurations } from "Models/CommonModels";

export const getSetting = (key: string): string => {
    const configs: IConfigurations = store.get(process.env.REACT_APP_LOCAL_STORAGE_SETTINGS);
    if (configs !== null && configs !== undefined) {
        if (configs[key] !== undefined && configs[key] != "") {
            return configs[key];
        }
    }
    return process.env[key]!!;
};

export class Settings {
    static authority: string = getSetting("REACT_APP_AUTHORITY");
    static clientAppId: string = getSetting("REACT_APP_CLINENT_ID");
    static redirectUri: string = getSetting("REACT_APP_REDIRECT_URI");
    static automationSilentRenew: boolean = Boolean(getSetting("REACT_APP_AUTOMATION_SILENT_RENEW"));
    static accessTokenExpiringNotificationTime: number = Number(getSetting("REACT_APP_ACCESS_TOKEN_EXPIRING_NOTIFICATION_TIME"));
    static silentRedirectUri: string = getSetting("REACT_APP_SILENT_REDIRECT_URI");
    static responseType: string = getSetting("REACT_APP_RESPONSE_TYPE");
    static scope: string = getSetting("REACT_APP_SCOPE");
    static loadResourcesPath: string = process.env.NODE_ENV == "development" ? getSetting("REACT_APP_LOAD_RESOURCES_PATH") : `${window.location.protocol}//${window.location.host}/api/AppResources/ListResourcesString?langid={{lng}}`;
    static apiBaseUri: string = process.env.NODE_ENV == "development" ? getSetting("REACT_APP_API_BASE_URI") : `${window.location.protocol}//${window.location.host}/api/`;
    static profileImageUrl_100x100: string = getSetting("REACT_APP_PROFILE_iMG_100X100_BASE_URI");
    static profileImageUrl_200x200: string = getSetting("REACT_APP_PROFILE_iMG_200X200_BASE_URI");
    static profileImageUrl_coverImage: string = getSetting("REACT_APP_PROFILE_iMG_COVERIMAGE_BASE_URI");
    static workFileMaxSize: number = Number(getSetting("REACT_APP_PROFILE_WORK_FILE_DOCUMENT_MAX_SIZE"));
    static networksDefaultPageSize: number = Number(getSetting("REACT_APP_NETWORK_DEFAULT_PAGE_SIZE"));
    static bookmarksDefaultPageSize: number = Number(getSetting("REACT_APP_BOOKMARK_DEFAULT_PAGE_SIZE"));
    static postsTimelineDefaultPageSize: number = Number(getSetting("REACT_APP_POSTS_TIMELINE_DEFAULT_PAGE_SIZE"));
    static postsFeedsDefaultPageSize: number = Number(getSetting("REACT_APP_POSTS_FEEDS_DEFAULT_PAGE_SIZE"));
    static commentsDefaultPageSize: number = Number(getSetting("REACT_APP_COMMENTS_DEFAULT_PAGE_SIZE"));
    static signalR_HubEndpoint: string = process.env.NODE_ENV == "development" ? getSetting("REACT_APP_SIGNALR_HUB_ENDPOINT") : `${window.location.protocol}//${window.location.host}//notificationHub`;
    static cacheTimeProfile: number = Number(getSetting("REACT_APP_CACHE_TIME_PROFILE"));
    static likesDefaultPageSize: number = Number(getSetting("REACT_APP_LIKES_DEFAULT_PAGE_SIZE"));
    static publicPageCacheTime: number = Number(getSetting("REACT_APP_PUBLIC_PAGE_CASH_TIME"));
    static notificationDelay: number = Number(getSetting("REACT_APP_NOTIFICATION_DELAY"));
    static resourcesCacheTime: number = Number(getSetting("REACT_APP_RESOURCES_CASH_TIME"));
    static resourcesVersion: string = String(getSetting("REACT_APP_RESOURCES_VERSION"));
    static searchSuggestionsResultSize: number = Number(getSetting("REACT_APP_SEARCH_SUGGESTIONS_RESULT_SIZE"))
    static sentrydsn: string = getSetting("REACT_APP_SENTRY_DSN")
    static joipVersion: string = getSetting("REACT_APP_JOIP_PLATFORM_VERSION")
    static identityVerificationMaxDocuments: number = Number(getSetting("REACT_APP_IDENTITY_VERIFICATION_MAX_DOCUMENTS"));
    static googleSiteKey: string = String(getSetting("REACT_APP_SITE_KEY"));
    static contentVerificationDefaultPageSize: number = Number(getSetting("REACT_APP_CONTENT_VERIFICATION_DEFAULT_PAGE_SIZE"));
    static chatMediaSize: number = Number(getSetting("REACT_APP_CHAT_MEDIA_SIZE"));
    static isCacheCleanerRuning: boolean = Boolean(getSetting("REACT_APP_RUN_CACHE_CLEANER"));
    static emailVerificationCodeTimer: number = Number(getSetting("REACT_APP_EMAIL_VERIFICATION_CODE_VERIFY_TIMER"));
    static bibtexFileExtensions: string = String(getSetting("REACT_APP_BIBTEX_FILE_EXTENSIONS"));
    static minYearSelect: number = Number(getSetting("REACT_APP_MIN_YEAR_SELECT"));
    static joipForumUrl: string = String(getSetting("REACT_APP_FORUM_URL"));
    static dashboardsUserMyWorksReport: string = String(getSetting("REACT_APP_DASHBOARDS_USER_MYWORKS_REPORT"));
    static dashboardsOrgMyWorksReport: string = String(getSetting("REACT_APP_DASHBOARDS_ORG_MYWORKS_REPORT"));
    static dashboardsJoipTop10ISAsReport: string = String(getSetting("REACT_APP_DASHBOARDS_JOIP_TOP10ISAs_REPORT"));
    static JoipHelpCenterURI: string = String(getSetting("REACT_APP_HELP_CENTER_URI"));
    static JoipHelpCenterURI_AR: string = String(getSetting("REACT_APP_HELP_CENTER_URI_AR"));
    static workDefaultPageSize: number = Number(getSetting("REACT_APP_WORK_DEFAULT_PAGE_SIZE"));
    static conferenceDefaultPageSize: number = Number(getSetting("REACT_APP_CONFERENCE_DEFAULT_PAGE_SIZE"));
    static submissionsDefaultPageSize: number = Number(getSetting("REACT_APP_SUBMISSIONS_DEFAULT_PAGE_SIZE"));
    static postImagesMaxNumber: number = Number(getSetting("REACT_APP_POST_IMAGES_MAX_NUMBER"));

}


import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import Lang from '../Utils/languge';
import { Settings } from 'Utils/Settings';


// have a look at the Quick start guide 
// for passing in lng and translations on init
export const backendOptions = {
    crossDomain: true,
    expirationTime: Settings.resourcesCacheTime, // 7 days
    defaultVersion: Settings.resourcesVersion,
    store: window.localStorage,
    prefix: `JOIP_res_${Settings.resourcesVersion}_`,
    // for all available options read the backend's repository readme file
    //loadPath: '/locales/{{lng}}/{{ns}}.json',
    //loadPath: 'http://192.168.1.100/locales/{{lng}}/{{ns}}.json',
    //loadPath: process.env.REACT_APP_LOAD_RESOURCES_PATH,
    overrideMimeType: false,
};

i18n
    .use(ChainedBackend)

    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    //.use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: String(Lang.getLang()),
        initImmediate: false,
        fallbackLng: String(Lang.getLang()),
        debug: false,
        react: {
            wait: true,
            useSuspense: true
        },
        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            backends: [
                LocalStorageBackend,
                Backend
            ],
            backendOptions: [backendOptions,
                {
                    loadPath: Settings.loadResourcesPath
                }
            ]
        }
    });

export default i18n;


import { BaseService } from "./Core/BaseService";
import { IBadges, IGeneralNotification, INetworkNotificationItem } from 'Models/Notifications';

export default class NotificationService extends BaseService {

    public async getGeneralNotifications(): Promise<IGeneralNotification[]> {
        return this.ProtectedApi.get("Notifications/ListGeneralNotifications")
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            })
    }

    public async UpdateUnreadGeneralNotifications(ids: Array<number>): Promise<void> {
        this.ProtectedApi.put('Notifications/UpdateUnreadGeneralNotifications', { "ids": ids });
    }

    public async UpdateUnreadNetworkNotifications(ids: Array<number>): Promise<void> {
        this.ProtectedApi.put('Notifications/UpdateUnreadNotifications', { "ids": ids });
    }

    public async getNetworkNotifications(): Promise<INetworkNotificationItem[]> {
        return this.ProtectedApi.get("Notifications/ListNetworkNotifications")
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            })
    }

    public async getUnreadNotificationsCount(): Promise<IBadges> {
        return this.ProtectedApi.get('Notifications/GetUnreadNotificationsCounts')
            .then(response => {
                return response.data as IBadges;
            })
            .catch((error) => {
                throw error;
            })
    }
}
import React from 'react';
import { Report } from 'powerbi-client';

import EmbedReport from 'Components/Shared/PowerBI/EmbedReport/EmbedReport';
import DashboardsService from 'Services/DashboardsService';
import JoipReportsContainer from './JoipReportsContainer';
import { Settings } from 'Utils/Settings';

const Top10ISAsReport = () => {
  const [report, setReport] = React.useState<Report>();
  const reportId = Settings.dashboardsJoipTop10ISAsReport;
  const _DashboardsService = new DashboardsService();

  const getReport = async (report: Report) => {
    setReport(report);
  }

  // will use for maltibale pages
  // const fetcReportPages = async () => { return await _DashboardsService.getReportPages("JoipReport"); }
  // const { data } = useQuery(["Reports/GetReportPagesByGroupName?groupName=", "JoipReport"], fetcReportPages, {
  //   cacheTime: Settings.cacheTimeProfile
  // });

  return (
    <JoipReportsContainer>
      <EmbedReport reportId={reportId} getEmbedReport={getReport} pages={[]}
        getReportApiEndPoint={_DashboardsService.getJoipTop10ISAsEmbedReport.bind(_DashboardsService, reportId)}
        settings={{
          settings: {
            panes: {
              bookmarks: {
                visible: false
              },
              filters: {
                expanded: false,
                visible: false
              },
              syncSlicers: {
                visible: false
              },
              pageNavigation: {
                visible: false
              }
            },
          }
        }} />
    </JoipReportsContainer>
  )
}

export default  React.memo(Top10ISAsReport);

export enum BOOKMARK_TYPE {
    USER_PROFILE = 1,
    ORG_PROFILE = 2,
    WORK = 3,
    ORG_WORK = 4
}

export interface IBookmarkItem {
    id: number;
    profileName?: string;
    profilePicture?: string;
    profileJRIN?: string;
    workTitle?: string;
    orgProfileName?: string;
    orgProfilePicture?: string;
    orgProfileJOIN?: string;
    orgWorkTitle?: string;
    pinnedToTop: boolean;
    bookmarkType: BOOKMARK_TYPE;
    bookmarkedProfileId?: number | null;
    bookmarkedOrgProfileId?: number | null;
    bookmarkedWorkId?: number | null;
    bookmarkedOrgWorkId?: number | null;
    bookmarkDate?: string;
    isIdentityVerified?: boolean;
}

export interface IBookmarkPagination {
    pageSize: number;
    pageIndex: number;
}
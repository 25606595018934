export enum PRIVACY {
  PUBLIC = 1,
  MY_NETWORK = 2,
  ONLY_ME = 3,
}

export enum NETWORK_TYPE {
  WEBSITE = 1,
  FACEBOOK = 2,
  TWITTER = 3,
  LINKEDIN = 4,
}

export enum SOURCE {
  JOIP = 1,
  ORCID = 2,
}

export enum VIEWER_TYPE {
  Owner = 1, // Owner of the profile
  JoipUser = 2, // Joip User visting a profile
  NonJoipUser = 3, // Non Joip User visting a profile
}

export enum GUIDES_TYPE {
  IdGuides = 1,
  WorkGuides = 2,
  OrgIdGuides = 3,
  OrgWorkGuides = 4,
  IdentityVerificationGuide = 5,
  OrgIdentityVerificationGuide = 6,
  ContentVerificationGuide = 7,
  OrgContentVerificationGuide = 8,
  ReportGuides = 9,
  OrgReportGuides = 10

}

export enum PROFILE_TYPE {
  USER_PROFILE = 1,
  ORG_PROFILE = 2,
}

export enum MATCHING_CRITERIA {
  Contains = 1,
  StartWith = 2
}

export type IconSizeType = "xsmall" | "small" | "medium" | "large"

export interface IConfiguration {
  key: string;
  value: string;
}

export interface IConfigurations {
  [key: string]: string;
}

export interface IPaginatedList<Type> {
  items: Array<Type>;
  totalCount: number;
  pageIndex: number;
  hasNextPage: boolean;
  hasPreviousPage?: boolean;
  totalPages: number;
}

export interface ISelect<Type> {
  label: string;
  value: Type;
}

export interface ICountrySelect {
  label: string;
  value: string;
  countryCode?: string;
}

export interface IMenuSection {
  items: Array<IMenuItem>;
  isLast?: boolean;
  funs?: Array<() => void>;
}

export interface IMenuItem {
  path?: string;
  title: string;
  icon?: string;
  badge?: number;
  items?: Array<IMenuItem>;
  selected?: boolean;
  isHeader?: boolean;
  subLevel?: number;
  activeRouts?: Array<string>;
  funIndex?: number;
  funs?: Array<() => void>;
  openNewTab?: boolean;
  isInactive?: boolean;
}

export type DropdownToggleType = 'SELECTED_ICON' | 'SELECTED_TEXT' | 'SELECTED_ICON_TEXT' | 'ICON' | 'LABEL'

export interface IDropdownItem {
  /** item text */
  label: string;
  /** optional - value for each item, used in function callback when selected */
  value?: string;
  /** optional - icon name, displayed alongside the item */
  iconName?: string;
  imgSource?: string;
  isActive?: boolean;
}

export interface IDropdownItemBase extends IDropdownItem {
  /** optional - callback function to be excecuted on item click */
  callbackFunction: any;
  disabled?: boolean;
}

export interface IConfirmDelete {
  show: boolean;
  text?: string;
  title?: string;
  isLoading?: boolean;
}

// used by redux (ViewWorkSlice)
export interface IViewWork {
  show: boolean;
  workId: number,
  isOwner?: boolean,
  isOrgWork?: boolean
  isLoading?: boolean;
}

export interface IOrgNameInput {
  name: string,
  value: number
}

export interface ICountryCityInput {
  countryId: number,
  country?: string,
  city: string,
}

export interface IDateRangeInputs {
  startYear?: string,
  startMonth?: string,
  startDay?: string,
  endYear?: string,
  endMonth?: string,
  endDay?: string,
}

export interface IDateInputs {
  year: string,
  month: string,
  day: string,
}

export interface IAnnouncement {
  content: string;
}

//type FilterType = CONTENT_VERIFICATION_TYPE | CONTENT_VERIFICATION_STATUS
export type FilterType = number | string
export interface IFilterItem<Type> {
  value: Type,
  name: string
}

export interface IInputTagSettings {
  placeholder?: string,
  pattern?: RegExp,
  delimiters?: string,
  texts?:
  {
    pattern?: string,
    duplicate?: string,
    notAllowed?: string
  },
  keepInvalidTags: boolean,
  backspace?: boolean
}

export enum INPUT_FIELD_TYPE {
  PARAGRAPH = 1, // "essay answer",
  MULTIPLE_CHOICE = 2, // "single choice"
  CHECKBOXES = 3, // "multi choices",
  DATE_PICKER = 4, // "date"
  SEPARATOR = 5, // "group title"
  FILE = 6 // "file"
}

export type Direction = "vertical" | "horizontal"
export interface IInputField {
  name: string,
  type: INPUT_FIELD_TYPE,
  label: string,
  numbering?: string,
  placeholder?: string,
  isRequired?: boolean,
  options?: IInputFieldOption[],
  optionsDirection?: Direction,
  value?: any,
  errorMsg?: string,
  fileName?:string,
  fileUrl?:string
}
export interface IInputFieldOption {
  value?: string,
  text: string,
  isChecked?: boolean,
  isDefault?: boolean,
  followupQuestion?:IInputField
}

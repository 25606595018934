import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationState, AppThunk } from "../index";
import { AxiosError } from "axios";
import _ from "lodash";

import { IPost, IPostComment, IPostMentions } from "Models/PostModels";
import PostService from "Services/PostService";

export interface PostState {
  posts: Array<IPost>;
  paginationToken: string;
  isPostsLoading: boolean;
  pageName?: PageType,
  isError: boolean,
  errorStatus?: number | null
}

const initialState: PostState = {
  posts: [],
  paginationToken: "",
  isPostsLoading: false,
  isError: false
};

type PageType = "FEEDS" | "TIMELINE"

const _postService = new PostService();

const postSlice = createSlice({
  name: "post",
  initialState: initialState,
  reducers: {
    fetchPosts: (
      state,
      action: PayloadAction<{ items: Array<IPost>; token: string }>
    ) => {
      const { items, token } = action.payload;
      state.posts = state.posts.concat(items);
      state.paginationToken = token;
    },
    clearState: (state) => {
      state.posts = [];
      state.paginationToken = "";
      state.isError = false;
      state.errorStatus = null;
    },
    AddPost: (
      state,
      action: PayloadAction<{ item: IPost }>
    ) => {
      const { item } = action.payload;
      state.posts = [item, ...state.posts]
    },
    EditPost: (
      state,
      action: PayloadAction<{ item: IPost }>
    ) => {
      const { item } = action.payload;
      const post = state.posts.find((post) => post.id === item.id)
      if (post) {
        post.content = item.content;
        post.plainText = item.plainText;
        post.privacy = item.privacy;
        post.linkPreview = item.linkPreview;
        post.schemaVersion = item.schemaVersion;
        post.imagesUrls = item.imagesUrls;
        state.posts[state.posts.findIndex((post) => post.id === item.id)] = post;
      }
    },
    deletePost: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const posts = state.posts.filter((c) => c.id !== id);
      state.posts = posts;
    },
    likePost: (state, action: PayloadAction<{ postId: string, likeId: string }>) => {
      const { postId, likeId } = action.payload;
      const post = state.posts.find((post) => post.id === postId);
      if (post) {
        post.isLiked = true;
        post.likeId = likeId;
      }
    },
    likesIncrement: (state, action: PayloadAction<string>) => {
      const postId = action.payload;
      const post = state.posts.find((post) => post.id === postId);
      if (post) {
        post.likesCount = post.likesCount + 1;
      }
    },
    unlikePost: (state, action: PayloadAction<string>) => {
      const post = state.posts.find((post) => post.id === action.payload);
      if (post) {
        post.isLiked = false;
        post.likeId = "";
      }
    },
    likesDecrement: (state, action: PayloadAction<string>) => {
      const postId = action.payload;
      const post = state.posts.find((post) => post.id === postId);
      if (post) {
        post.likesCount = post.likesCount - 1;
      }
    },
    fetchPostComments: (state, action: PayloadAction<{ postId: string, comments: Array<IPostComment>, token: string }>) => {
      const { postId, comments, token } = action.payload;
      const post = state.posts.find((post) => post.id === postId);
      if (post) {
        let newComments = post.comments.concat(comments);
        newComments = _.uniqBy(newComments, "id") // remove duplicated comments
        newComments = _.orderBy(newComments, ['createDateTime'], ['asc']);
        post.comments = newComments;
        post.highlightComment = null;
        post.commentsPaginationToken = token;
      }
    },
    addPostComment: (state, action: PayloadAction<{ postId: string, item: IPostComment }>) => {
      const { postId, item } = action.payload;
      const post = state.posts.find((post) => post.id === postId);
      if (post) {
        post.comments = [...post.comments, item];
        post.commentsCount = post.commentsCount + 1;
      }
    },
    updatePostComment: (state, action: PayloadAction<{ postId: string, item: IPostComment }>) => {
      const { postId, item } = action.payload;
      const post = state.posts.find((post) => post.id === postId);
      if (post) {
        const index = post.comments.findIndex((comment) => comment.id === item.id);
        if (index > -1)
          post.comments[index] = item;
      }
    },
    deletePostComment: (state, action: PayloadAction<{ postId: string, commentId: string }>) => {
      const { postId, commentId } = action.payload;
      const post = state.posts.find((post) => post.id === postId);
      if (post) {
        post.comments = post.comments.filter((c) => c.id !== commentId);
        post.commentsCount = post.commentsCount - 1;
      }
    },
    setPostLoading: (state, action: PayloadAction<boolean>) => {
      state.isPostsLoading = action.payload;
    },
    setCommentLoading: (state, action: PayloadAction<{ postId: string, isLoading: boolean }>) => {
      const { postId, isLoading } = action.payload;
      const post = state.posts.find((post) => post.id === postId);
      if (post) {
        post.isCommentsFetching = isLoading;
      }
    },
    setPageName: (state, action: PayloadAction<PageType | undefined>) => {
      state.pageName = action.payload;
      state.isError = false;
    },
    setLoadingError: (state, action: PayloadAction<AxiosError | undefined>) => {
      state.isError = true;
      state.errorStatus = action.payload?.response?.status;
    }
  },
});

export const { fetchPosts, clearState, AddPost, EditPost, deletePost, setPostLoading, setCommentLoading, likePost, unlikePost, likesIncrement, likesDecrement,
  fetchPostComments, addPostComment, updatePostComment, deletePostComment, setPageName, setLoadingError } = postSlice.actions;

export const listTimelinePostsAsync = (jrin: string, pageSize: number, paginationToken: string = ""): AppThunk => async (dispatch, getstate) => {
  await _postService
    .listTimelinePosts(jrin, pageSize, paginationToken)
    .then((res) => {
      if (res.items !== null && getstate().post?.pageName === "TIMELINE") {
        const itemsWithComments: Array<IPost> = res.items.map(post => { return { ...post, comments: [] } })
        dispatch(fetchPosts({ items: itemsWithComments, token: res.paginationToken }));
      }
    }).catch(() => dispatch(setLoadingError()))
    .finally(() => dispatch(setPostLoading(false)));
};

export const listOrgTimelinePostsAsync = (orgId: number, pageSize: number, paginationToken: string = ""): AppThunk => async (dispatch, getstate) => {
  await _postService
    .listOrgTimelinePosts(orgId, pageSize, paginationToken)
    .then((res) => {
      if (res.items !== null && getstate().post?.pageName === "TIMELINE") {
        const itemsWithComments: Array<IPost> = res.items.map(post => { return { ...post, comments: [] } })
        dispatch(fetchPosts({ items: itemsWithComments, token: res.paginationToken }));
      }
    }).catch(() => dispatch(setLoadingError()))
    .finally(() => dispatch(setPostLoading(false)));
};

export const listFeedsPostsAsync = (pageSize: number, paginationToken: string = ""): AppThunk => async (dispatch, getstate) => {
  await _postService
    .listFeedsPosts(pageSize, paginationToken)
    .then((res) => {
      if (res.items !== null && getstate().post?.pageName === "FEEDS") {
        const itemsWithComments: Array<IPost> = res.items.map(post => { return { ...post, comments: [] } })
        dispatch(fetchPosts({ items: itemsWithComments, token: res.paginationToken }));
      }
    })
    .catch(() => dispatch(setLoadingError()))
    .finally(() => dispatch(setPostLoading(false)));
};

export const getPostAsync = (postId: string, commentId?: string): AppThunk => async (dispatch, getstate) => {
  await _postService
    .getPost(postId, commentId)
    .then((res) => {
      if (res !== null) {
        const comments: Array<IPostComment> = res.highlightComment !== null ? [res.highlightComment] : []
        const itemWithComments: IPost = { ...res, comments: comments, commentsPaginationToken: "" }
        dispatch(fetchPosts({ items: [itemWithComments], token: "" }));
      }
    })
    .catch((error) => dispatch(setLoadingError(error)))
    .finally(() => dispatch(setPostLoading(false)));
};
export const AddPostAsync = (item: IPost, hideSharedPost:boolean = false): AppThunk => async (dispatch, getstate) => {
  await _postService
    .addPost(item)
    .then((data: any) => {
      const post = { ...item }
      post.id = data
      if(!hideSharedPost)
       dispatch(AddPost({ item: post }));
    })
    .catch(err => { });
};
export const AddOrgPostAsync = (item: IPost, orgId: number, hideSharedPost:boolean=false): AppThunk => async (dispatch, getstate) => {
  await _postService
    .addOrgPost(item, orgId)
    .then((data: any) => {
      const post = { ...item }
      post.id = data
      if(!hideSharedPost)
      dispatch(AddPost({ item: post }));
    })
    .catch(err => { });
};

export const EditPostAsync = (item: IPost): AppThunk => async (dispatch, getstate) => {
  await _postService
    .updatePost(item)
    .then((res: any) => {
      dispatch(EditPost({ item: item }));
    })
    .catch(err => { });
};

export const likePostAsync = (postId: string, ownerId: number, isOrgPost: boolean): AppThunk => async (dispatch, getstate) => {
  dispatch(likePost({ postId: postId, likeId: "" }))
  dispatch(likesIncrement(postId))
  await _postService.likePost(postId, ownerId, isOrgPost)
    .then(res => {
      dispatch(likePost({ postId: postId, likeId: res.likeId }));
    })
    .catch(err => {
      dispatch(unlikePost(postId));
      dispatch(likesDecrement(postId))
    });
};

export const unlikePostAsync = (postId: string, likeId: string, ownerId: number, isOrgPost: boolean): AppThunk => async (dispatch, getstate) => {
  if (likeId !== "") {
    await _postService.unlikePost(postId, likeId, ownerId, isOrgPost)
      .then(res => {
        dispatch(unlikePost(postId));
        dispatch(likesDecrement(postId))
      })
      .catch(err => { })
  }
};

export const listPostCommentsAsync = (postId: string, pageSize: number, paginationToken: string = ""): AppThunk => async (dispatch, getstate) => {
  dispatch(setCommentLoading({ postId: postId, isLoading: true }))
  await _postService
    .listPostComments(postId, pageSize, paginationToken)
    .then((res) => {
      let comments: Array<IPostComment> = [];
      comments = res.items !== null ? res.items : [];
      dispatch(fetchPostComments({ postId, comments: comments, token: res.paginationToken }));
    })
    .catch(err => { })
    .finally(() => dispatch(setCommentLoading({ postId: postId, isLoading: false })));
};

export const addPostCommentAsync = (postId: string, postOwnerId: number, comment: IPostComment, isOrgPost: boolean): AppThunk => async (dispatch, getstate) => {
  await _postService.addComment(postId, postOwnerId, comment, isOrgPost)
    .then((res) => {
      dispatch(addPostComment({ postId: postId, item: res }));
    })
    .catch(err => { })
};

export const updatePostCommentAsync = (postId: string, postOwnerId: number, comment: IPostComment, isOrgPost: boolean): AppThunk => async (dispatch, getstate) => {
  await _postService.updateComment(postId, postOwnerId, comment, isOrgPost)
    .then((res) => {
      dispatch(updatePostComment({ postId: postId, item: res }));
    })
    .catch(err => { })
};

export const selectPosts = (state: ApplicationState) => state.post?.posts;
export const selectPost = (state: ApplicationState, postId: string) => state.post?.posts.find(p => p.id === postId);
export const selectPaginationToken = (state: ApplicationState) => state.post?.paginationToken;
export const selectIsPostLoading = (state: ApplicationState) => state.post?.isPostsLoading;
export const isLoadingError = (state: ApplicationState) => state.post?.isError;
export const selectErrorStatus = (state: ApplicationState) => state.post?.errorStatus;

export const selectPostOne = (state: ApplicationState) => state.post?.posts.find(p => p.id === "rp5nDNhX0y2TQzw7Yyxg");


export default postSlice.reducer;

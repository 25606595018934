import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Toast } from 'bootstrap';
import { Link, matchPath, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

import { INetworkNotificationStackItem } from 'Models/Notifications';
import { notification, removerNetworkNotificationStack } from 'Store/Features/Notifications/NotificationSlice';
import TimeAgo from 'Utils/TimeAgo';
import T from 'Utils/localizerHook';
import { Settings } from 'Utils/Settings';
import { getDirectionFromText, profilePic100 } from 'Utilites';
import { Icon } from 'Utils/Icon';

export const CardToast: React.FC<{ item: INetworkNotificationStackItem }> = ({ item }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname } = useLocation();

    var [toast, setToast] = useState<boolean>(true);
    const ref: any = useRef();
    useEffect(() => { setToast(true); })
    useEffect(() => {
        //const myToast: any = ref.current
        var bsToast = Toast.getInstance(ref.current)

        if (!bsToast) {
            // initialize Toast
            bsToast = new Toast(ref.current, { autohide: true, delay: Settings.notificationDelay })
            // hide after init
            bsToast.hide()
            setToast(false)
        }
        else {
            // toggle
            toast ? bsToast.show() : bsToast.hide()
        }
    });

    useEffect(() => {
        var myModalEl = ref.current
        myModalEl.addEventListener('hidden.bs.toast', () => {
            dispatch(removerNetworkNotificationStack({ id: item.id }));
        })
    }, [item.id])

    const refresh = (selectedPath: string): void => {
        let currentPath = pathname;
        const isMatch = matchPath(selectedPath, currentPath);

        if (isMatch) {
            history.replace(`/refresh/` + moment().format("x"));
            setTimeout(() => {
                history.replace(selectedPath)
            }, 0)
        } else {
            history.push(selectedPath);
        }

        setToast(false);
    }

    return <>
        <div className="py-2">
            <div className="toast" role="alert" ref={ref} id={item.id}>
                <div className="toast-header">
                    <Icon iconname="NotificationsNone" className="text-muted" />
                    <strong className="me-auto">{T(item.title)}</strong>
                    <small>{T("Notification.JustNow")}</small>
                    <button type="button" className="btn-close" onClick={() => setToast(false)} aria-label="Close"></button>
                </div>
                <div className="toast-body p-2">
                    <Link className="dropdown-item btn" to="#" onClick={() => refresh(item.URL)}>
                        <div className="row">
                            <div className="col-sm-3 col-3">
                                <img loading="lazy" width="50" height="50" src={profilePic100(item.initiatorPicture)} className="rounded-circle me-2" alt={item.initiatorFullName} />
                            </div>
                            <div className={`pt-1 col-sm-9 col-9 pe-2 text-muted ps-0`} style={{ whiteSpace: "normal" }}>
                                <div >
                                    <span className={`fw-bold text-capitalize align-middle`}>{item.initiatorFullName} </span>
                                    <span className="fs-small text-gray-400 align-middle">
                                        <span className="d-inline-block text-truncate align-middle" dir={getDirectionFromText(String(item.message))} style={{ maxWidth: "200px" }}>{T(item.message)}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </>
}

const NotificationStack: React.FC = (): JSX.Element => {
    const notificatonList = useSelector(notification);

    const CardData = CardToast;
    let Content = notificatonList?.notificationsStack.map((item, index) => <CardData key={`network.notification.key.${index}`} item={item} />)

    return <div className="position-fixed bottom-0 start-0 ps-2" style={{ zIndex: 1 }}>
        <div>{Content}</div>
    </div>
}
export default React.memo(NotificationStack)
import { BaseService } from 'Services/Core/BaseService';
import { UserModel } from 'Models/UserModels';

export default class UserService extends BaseService {


    public async getUserInfo(): Promise<UserModel> {
        return this.ProtectedApi.get("Profile/GetUserProfileInfo")
            .then((response) => {
                return response.data as UserModel;
            }).catch((error) => {
                throw error;
            });
    }
}
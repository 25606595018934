import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import pupa from 'pupa';
import moment from 'moment';

import { ApplicationState, AppThunk } from '../../index';
import { IBadges, GENERAL_NOTIFICATION_TYPE, INetworkNotificationStackItem, IReceivedNetworkNotification, IReceivedGeneralNotification, INetworkNotificationSliceState, NETWORK_NOTIFICATION_TYPE } from 'Models/Notifications';
import { NotificationLikeOnMyPost, NotificationCommentOnMyPost, NotificationSystem, AcceptedYourConnectionRequest, SentYouConnectionRequest, StartedFollowingYou, AskedForReferral, WroteYouReferral, RequestedWorkFileShare, SharedWorkFiles, MentionProfile, ContentVerificationDeclined, ContentVerificationAccepted, NotificationWorkContribute, NotificationAddOrgProfileAdmin, NotificationDeleteOrgProfileAdmin } from 'Utils/Resources';
import { researcherProfile, post, researcherIdentityVerification, resercherReceivedReferralRequests, researcherReferrals, researcherReceivedWorkFileShareRequests, researcherWorkFileShare, researcherContentVerificationRequest, messages, organizationProfile, chairmanConferenceSubmissionDetails, chairmanConferences, reviewerConferenceSubmissionDetails, reviewerConferencesSubmissions, researcherConferencesSubmissions, chairmanConferencesSubmissions } from 'Config/RoutesConstants';
import NotificationService from 'Services/NotificationService';
import { VERIFICATION_STATUS } from 'Models/ProfileModels';
import { CONTENT_VERIFICATION_STATUS } from 'Models/ContentVerificationModels';
import { reflectNotifiactionCountInPageTitle } from 'Utils/Utilites';
import { addOrgs } from 'Store/Features/OrgsSlice';
import OrgProfileService from 'Services/OrgProfileService';
import { updateUser } from '../UserSlice';

const initialState: INetworkNotificationSliceState = {
    messageNotificationCount: 0,
    networkNotificationCount: 0,
    otherNotificationCount: 0,
    notificationsStack: [],
};
const _notificationService = new NotificationService();
const _userService = new OrgProfileService();

const resources = {
    AcceptedYourConnectionRequest: AcceptedYourConnectionRequest,
    SentYouConnectionRequest: SentYouConnectionRequest,
    StartedFollowingYou: StartedFollowingYou,
    LikeOnMyPost: NotificationLikeOnMyPost,
    CommentOnMyPost: NotificationCommentOnMyPost,
    SystemNotification: NotificationSystem,
    AcceptedYourConnectionRequestTitle: "Notification.Title.AcceptedYourConnectionRequest",
    SentYouConnectionRequestTitle: "Notification.Title.SentYouConnectionRequest",
    StartedFollowingYouTitle: "Notification.Title.StartedFollowingYou",
    LikeOnMyPostTitle: "Notification.Title.LikeOnMyPost",
    CommentOnMyPostTitle: "Notification.Title.CommentOnMyPost",
    ProfileIdentityVerification: "Notification.GeneralList.ProfileIdentityVerification",
    SystemNotificationIdentityRequestAccepted: "Notification.Title.IdentityRequestAccepted",
    SystemNotificationIdentityRequestDeclined: "Notification.Title.IdentityRequestDeclined",
    AskedForReferralTitle: "Notification.Title.AskedForReferral",
    WroteYouReferralTitle: "Notification.Title.ReferralReceived",
    AskedForReferral: AskedForReferral,
    WroteYouReferral: WroteYouReferral,
    RequestedWorkFileShare: RequestedWorkFileShare,
    RequestedWorkFileShareTitle: "Notification.Title.RequestedWorkFileShare",
    SharedWorkFiles: SharedWorkFiles,
    SharedWorkFilesTitle: "Notification.Title.SharedWorkFiles",
    MentionProfile: MentionProfile,
    MentionProfileTitle: "Notification.Title.MentionProfile",
    ContentVerification: "Notification.ContentVerification",
    NotificationContentVerificationAccepted: ContentVerificationAccepted,
    NotificationContentVerificationDeclined: ContentVerificationDeclined,
    SentMessage: "New Message", //"Notification.Title.SentNewMessage"
    NotificationWorkContributor: NotificationWorkContribute,
    NotificationWorkContributorTitle: "Notification.Title.WorkContributor",
    NotificationAddOrgAdmin: NotificationAddOrgProfileAdmin,
    NotificationAddOrgAdminTitle: "Notification.Title.AddOrgAdmin",
    NotificationDeleteOrgAdmin: NotificationDeleteOrgProfileAdmin,
    NotificationDeleteOrgAdminTitle: "Notification.Title.DeleteOrgAdmin",
    NotificationAddChairmanTitle: "Notification.Title.AddChairman",
    NotificationDeleteChairmanTitle: "Notification.Title.DeleteChairman",
    NotificationAddReviewerTitle: "Notification.Title.AddReviewer",
    NotificationDeleteReviewerTitle: "Notification.Title.DeleteReviewer",
    NotificationReviewerAcceptActionTitle: "Notification.Title.ReviewerAcceptAction",
    NotificationReviewerRejectActionTitle: "Notification.Title.ReviewerRejectAction",
    NotificationNewSubmissionTitle: "Notification.Title.NewSubmission",
    NotificationAcceptSubmissionTitle: "Notification.Title.AcceptSubmission",
    NotificationRejectSubmissionTitle: "Notification.Title.RejectSubmission",
    NotificationAddChairman: "Notification.AddChairman",
    NotificationDeleteChairman: "Notification.DeleteChairman",
    NotificationAddReviewer: "Notification.AddReviewer",
    NotificationDeleteReviewer: "Notification.DeleteReviewer",
    NotificationReviewerAcceptAction: "Notification.ReviewerAcceptAction",
    NotificationReviewerRejectAction: "Notification.ReviewerRejectAction",
    NotificationNewSubmission: "Notification.NewSubmission",
    NotificationAcceptSubmission: "Notification.AcceptSubmission",
    NotificationRejectSubmission: "Notification.RejectSubmission",
    NotificationMissingWorkFileRequestTitle: "Notification.Title.MissingWorkFileRequest",
    NotificationMissingWorkFileRequest: "Notification.MissingWorkFileRequest",
    NotificationSharedPost:"Notification.Message.SharedPost",
    NotificationSharedPostTitle: "Notification.Title.SharedPost"
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState: initialState,
    reducers: {
        addNetworkNotificationStack: (state, action: PayloadAction<IReceivedNetworkNotification>) => {
            state.networkNotificationCount = state.networkNotificationCount + 1;
            const data = action.payload;
            let message: string = "";
            let title: string = "";
            switch (data.NetworkNotificationType) {
                case NETWORK_NOTIFICATION_TYPE.AcceptedConnection:
                    message = resources.AcceptedYourConnectionRequest;
                    title = resources.AcceptedYourConnectionRequestTitle;
                    break;
                case NETWORK_NOTIFICATION_TYPE.ConnectionRequest:
                    message = resources.SentYouConnectionRequest;
                    title = resources.SentYouConnectionRequestTitle;
                    break;

                case NETWORK_NOTIFICATION_TYPE.NewFollower:
                    message = resources.StartedFollowingYou;
                    title = resources.StartedFollowingYouTitle;
                    break;
            }

            const model: INetworkNotificationStackItem = {
                id: data.Id,
                date: moment().format(),
                initiatorPicture: data.InitiatorPicture,
                initiatorFullName: data.InitiatorFullName,
                URL: pupa(researcherProfile.routes[0], [data.JRIN + '', 'about']),
                targetProfileId: data.TargetProfileId,
                message: message,
                title: title
            }
            state.notificationsStack.push(model);
        },
        addMessageNotificationStack: (state, action: PayloadAction<IReceivedGeneralNotification>) => {
            const data = action.payload;
            const dynamicData = JSON.parse(data.Data);
            const model: INetworkNotificationStackItem = {
                id: data.Id,
                date: moment().format(),
                initiatorPicture: data.InitiatorPicture,
                initiatorFullName: data.InitiatorFullName,
                URL: messages.route,
                targetProfileId: data.TargetProfileId,
                message: dynamicData.body,
                title: resources.SentMessage
            }
            state.notificationsStack.push(model);
        },
        addGeneralNotificationStack: (state, action: PayloadAction<IReceivedGeneralNotification>) => {
            state.otherNotificationCount = state.otherNotificationCount + 1;
            reflectNotifiactionCountInPageTitle(state.otherNotificationCount + 1);
            const data = action.payload;
            const dynamicData = JSON.parse(data.Data);
            let message: string = "";
            let title: string = "";
            let url: string = "";
            switch (data.Type) {
                case GENERAL_NOTIFICATION_TYPE.LikeOnMyPost:
                    message = resources.LikeOnMyPost;
                    title = resources.LikeOnMyPostTitle;
                    url = pupa(post.routes[1], [dynamicData.PostId])
                    break;

                case GENERAL_NOTIFICATION_TYPE.CommentOnMyPost:
                    message = resources.CommentOnMyPost;
                    title = resources.CommentOnMyPostTitle;
                    url = pupa(post.routes[0], [dynamicData.PostId, dynamicData.CommentId])
                    break;

                case GENERAL_NOTIFICATION_TYPE.ProfileIdentityVerification:
                    message = dynamicData.Status == VERIFICATION_STATUS.Accepted ? resources.SystemNotificationIdentityRequestAccepted : resources.SystemNotificationIdentityRequestDeclined;
                    title = resources.ProfileIdentityVerification;
                    url = pupa(researcherIdentityVerification.route, [dynamicData.RequestId])
                    break;

                case GENERAL_NOTIFICATION_TYPE.ReferralRequest:
                    message = resources.AskedForReferral;
                    title = resources.AskedForReferralTitle;
                    url = pupa(resercherReceivedReferralRequests.route, [dynamicData.RequestId])
                    break;

                case GENERAL_NOTIFICATION_TYPE.ReferralReceived:
                    message = resources.WroteYouReferral;
                    title = resources.WroteYouReferralTitle;
                    url = pupa(researcherReferrals.route, [dynamicData.ReferralId])
                    break;

                case GENERAL_NOTIFICATION_TYPE.WorkFileShareRequest:
                    message = resources.RequestedWorkFileShare;
                    title = resources.RequestedWorkFileShareTitle;
                    url = pupa(researcherReceivedWorkFileShareRequests.route, [dynamicData.RequestId])
                    break;

                case GENERAL_NOTIFICATION_TYPE.WorkFileShareReceived:
                    message = resources.SharedWorkFiles;
                    title = resources.SharedWorkFilesTitle;
                    url = researcherWorkFileShare.route
                    break;
                case GENERAL_NOTIFICATION_TYPE.ProfileMentioned:
                    message = resources.MentionProfile;
                    title = resources.MentionProfileTitle;
                    url = pupa(post.routes[1], [dynamicData.PostId])
                    break;

                case GENERAL_NOTIFICATION_TYPE.ContentVerification:
                    message = dynamicData.Status == CONTENT_VERIFICATION_STATUS.verified ? resources.NotificationContentVerificationAccepted : resources.NotificationContentVerificationDeclined;
                    title = resources.ContentVerification;
                    url = pupa(researcherContentVerificationRequest.route, [dynamicData.ContentId, dynamicData.ContentType])
                    break;

                case GENERAL_NOTIFICATION_TYPE.WorkContributor:
                    message = resources.NotificationWorkContributor;
                    title = resources.NotificationWorkContributorTitle;
                    url = pupa(researcherProfile.routes[1], [dynamicData.JRIN + '', dynamicData.JDOI, 'works'])
                    break;

                case GENERAL_NOTIFICATION_TYPE.OrgWorkContributor:
                    message = resources.NotificationWorkContributor;
                    title = resources.NotificationWorkContributorTitle;
                    url = pupa(organizationProfile.routes[1], [dynamicData.JOIN + '', dynamicData.JDOI, 'works'])
                    break;

                case GENERAL_NOTIFICATION_TYPE.AddOrgProfileAdmin:
                    message = resources.NotificationAddOrgAdmin;
                    title = resources.NotificationAddOrgAdminTitle;
                    url = pupa(organizationProfile.routes[0], [dynamicData.JOIN, 'about'])
                    break;

                case GENERAL_NOTIFICATION_TYPE.DeleteOrgProfileAdmin:
                    message = resources.NotificationDeleteOrgAdmin;
                    title = resources.NotificationDeleteOrgAdminTitle;
                    url = pupa(organizationProfile.routes[0], [dynamicData.JOIN, 'about'])
                    break;
                case GENERAL_NOTIFICATION_TYPE.AddChairman:
                    message = resources.NotificationAddChairman;
                    title = resources.NotificationAddChairmanTitle;
                    url = pupa(chairmanConferencesSubmissions.route, [data.InitiatorId, dynamicData.ConfId])
                    break;
                case GENERAL_NOTIFICATION_TYPE.DeleteChairman:
                    message = resources.NotificationDeleteChairman;
                    title = resources.NotificationDeleteChairmanTitle;
                    url = chairmanConferences.route
                    break;
                case GENERAL_NOTIFICATION_TYPE.AddReviewer:
                    message = resources.NotificationAddReviewer;
                    title = resources.NotificationAddReviewerTitle;
                    url = pupa(reviewerConferenceSubmissionDetails.route, [dynamicData.SubmissionId])
                    break;
                case GENERAL_NOTIFICATION_TYPE.DeleteReviewer:
                    message = resources.NotificationDeleteReviewer;
                    title = resources.NotificationDeleteReviewerTitle;
                    url = reviewerConferencesSubmissions.route
                    break;
                case GENERAL_NOTIFICATION_TYPE.ReviewerAcceptAction:
                    message = resources.NotificationReviewerAcceptAction;
                    title = resources.NotificationReviewerAcceptActionTitle;
                    url = pupa(chairmanConferenceSubmissionDetails.route, [dynamicData.SubmissionId, dynamicData.ReviewerId])
                    break;
                case GENERAL_NOTIFICATION_TYPE.ReviewerRejectAction:
                    message = resources.NotificationReviewerRejectAction;
                    title = resources.NotificationReviewerRejectActionTitle;
                    url = pupa(chairmanConferenceSubmissionDetails.route, [dynamicData.SubmissionId, dynamicData.ReviewerId])
                    break;
                case GENERAL_NOTIFICATION_TYPE.AcceptSubmission:
                    message = resources.NotificationAcceptSubmission;
                    title = resources.NotificationAcceptSubmissionTitle;
                    url = pupa(researcherConferencesSubmissions.route, [dynamicData.SubmissionId])
                    break;
                case GENERAL_NOTIFICATION_TYPE.RejectSubmission:
                    message = resources.NotificationRejectSubmission;
                    title = resources.NotificationRejectSubmissionTitle;
                    url = pupa(researcherConferencesSubmissions.route, [dynamicData.SubmissionId])
                    break;
                case GENERAL_NOTIFICATION_TYPE.NewSubmission:
                    message = resources.NotificationNewSubmission;
                    title = resources.NotificationNewSubmissionTitle;
                    url = pupa(chairmanConferenceSubmissionDetails.route, [dynamicData.SubmissionId, ""])
                    break;
                case GENERAL_NOTIFICATION_TYPE.MissingWorkFileRequest:
                    message = resources.NotificationMissingWorkFileRequest;
                    title = resources.NotificationMissingWorkFileRequestTitle;
                    url = pupa(researcherProfile.routes[1], [dynamicData.JoipId + '', dynamicData.JDOI, 'works'])
                    break;
                case GENERAL_NOTIFICATION_TYPE.SharePost:
                    message = resources.NotificationSharedPost;
                    title = resources.NotificationSharedPostTitle;
                    url = pupa(post.routes[1], [dynamicData.PostId])
                    break;
            }
            const model: INetworkNotificationStackItem = {
                id: data.Id,
                date: moment().format(),
                initiatorPicture: data.InitiatorPicture,
                initiatorFullName: data.InitiatorFullName,
                URL: url,
                targetProfileId: data.TargetProfileId,
                message: message,
                title: title
            }
            state.notificationsStack.push(model);
        },
        removerNetworkNotificationStack: (state, action: PayloadAction<{ id: string }>) => {
            state.notificationsStack = state.notificationsStack.filter(item => item.id != action.payload.id);
        },
        resetNetworkNotifications: (state) => {
            state.networkNotificationCount = 0;
        },
        resetGeneralNotifications: (state) => {
            state.otherNotificationCount = 0;
        },
        resetMessageNotifications: (state, action: PayloadAction<number | null>) => {
            if (action.payload != null) {
                if (state.messageNotificationCount > 0 && state.messageNotificationCount - action.payload >= 0)
                    state.messageNotificationCount -= action.payload;
            } else {
                state.messageNotificationCount = 0;
            }
        },
        addNotificationsCount: (state, action: PayloadAction<IBadges>) => {
            state.networkNotificationCount = state.networkNotificationCount + action.payload.networkNotificationCount;
            state.otherNotificationCount = state.otherNotificationCount + action.payload.otherNotificationCount;
        },
        addMessageNotificationsCount: (state, action: PayloadAction<number>) => {
            state.messageNotificationCount += action.payload;
        },
    }
});

export const {
    addNetworkNotificationStack,
    addGeneralNotificationStack,
    removerNetworkNotificationStack,
    resetNetworkNotifications,
    resetGeneralNotifications,
    addNotificationsCount,
    resetMessageNotifications,
    addMessageNotificationsCount,
    addMessageNotificationStack,
} = notificationSlice.actions;

export const unreadNotificationsCounts = (): AppThunk => (dispatch, getstate) => {
    _notificationService.getUnreadNotificationsCount().then(data => {
        if (data) {
            dispatch(addNotificationsCount(data));
        }
    }).catch();
};

export const AddOrgAdmin = (): AppThunk => (dispatch, getState) => {
    _userService.getOrgsByAdminId().then(response => {
        dispatch(addOrgs(response));
        dispatch(updateUser({ orgs: response.map(item => String(item.id)) }))
    }).catch();
}

export const notification = (state: ApplicationState) => state.notification;
export default notificationSlice.reducer;

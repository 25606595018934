import * as React from 'react';

import { Icon } from 'Utils/Icon';

interface IProps {
    text: string,
    buttonText?: string,
    buttonOnClickAction?: any,
    children?: any
}

const NoData: React.FC<IProps> = ({ text, buttonText, buttonOnClickAction, children }) => {
    const [show, setShow] = React.useState<Boolean>(false);

    React.useEffect(() => {
        setShow(true);
    }, []);

    return show && <div className="text-muted text-center my-4 py-2">
        <div><Icon iconname="InfoOutlined" className="text-muted fs-1 text-secondry" /></div>
        <div>{text}</div>
        {buttonOnClickAction && <div><a className="btn-link" style={{ cursor: 'pointer' }} onClick={buttonOnClickAction}>{buttonText}</a></div>}
        {children && <div>{children}</div>}
    </div>
}

export default NoData;
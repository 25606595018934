import { createSlice, PayloadAction } from '@reduxjs/toolkit';


import { ApplicationState } from '../index';
import { IViewWork } from 'Models/CommonModels';


export const initialState: IViewWork = { show: false, workId: 0, isOwner: false, isOrgWork: false, isLoading: false };

const viewWorkSlice = createSlice({
    name: 'viewWork',
    initialState: initialState,
    reducers: {
        showViewWork: (state, action: PayloadAction<{ workId: number, isOwner: boolean, isOrgWork: boolean }>) => {
            state.show = true;
            state.workId = action.payload.workId;
            state.isOwner = action.payload.isOwner;
            state.isOrgWork = action.payload.isOrgWork;
        },
        hideViewWork: (state, action: PayloadAction) => {
            state.show = false;
            state.workId = 0;
            state.isOwner = false;
            state.isOrgWork = false;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        }
    }
});

export const { showViewWork, hideViewWork, setLoading } = viewWorkSlice.actions;

export const viewWork = (state: ApplicationState) => state.viewWork;
export default viewWorkSlice.reducer;

import * as React from 'react';
import parse from 'react-html-parser';
import { Link } from 'react-router-dom';
import store from 'store2';

import PublicSubPagesLayout from 'Layouts/PublicSubPagesLayout';
import T from 'Utils/localizerHook';
import errorImg from 'Assets/Images/Illustrations/Start-Illustration.png';
import Auth from 'Utils/Auth';
import { home } from 'Config/RoutesConstants';


const UserSuspended: React.FC = () => {

    React.useEffect(() => { store.remove("isSuspended") }, []);

    return (
        <PublicSubPagesLayout>
            <div className="container p-5">
                <div className="row">
                    <div className={`col-md-6 pt-5 mt-4`}>
                        <h2 className={`text-primary `}>{T("Account.Suspended.Title")}</h2>
                        <p className="text-primary">{T("Account.Suspended.Headline")}</p>
                        <p className="text-primary">{parse(T("Account.Suspended.Description"))}</p>
                        <div>
                            <button className='btn btn-primary rounded-pill' onClick={Auth.login}>{T("Account.Suspended.Login")}</button>
                            <Link className='btn btn-outline-primary ms-2 rounded-pill' to={home.route}>{T("Account.Suspended.Home")}</Link>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img loading="lazy" src={errorImg} width="100%" height="100%" />
                    </div>
                </div>
            </div>
        </PublicSubPagesLayout>
    );
};

export default UserSuspended;



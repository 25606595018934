import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import store from 'store2';

import { ApplicationState } from '../index';
import { UserModel } from 'Models/UserModels';
import { ILightOrganization, IOrgProfileAdmins } from 'Models/OrganizationModels';

export interface OrgState {
  orgs: Array<ILightOrganization>;
  orgAdmins:Array<IOrgProfileAdmins>;
  firstTimeCalled: boolean;
  profileViewsCount:number;
}

const initialState: OrgState = {
  orgs: [],
  orgAdmins:[],
  firstTimeCalled: false,
  profileViewsCount:0
};

const orgSlice = createSlice({
  name: 'org',
  initialState: initialState,
  reducers: {
    addOrgs: (state, action: PayloadAction<Array<ILightOrganization>>) => {
      const orgState = action.payload;
      state.orgs = [...orgState]
    },
    addNewOrg: (state, action: PayloadAction<ILightOrganization>) => {
      state.orgs.push(action.payload);
      const user = store.get(process.env.REACT_APP_LOCAL_STORAGE_CURR__USER) as UserModel;
      user.orgs?.push(String(action.payload.id));
      store.set(process.env.REACT_APP_LOCAL_STORAGE_CURR__USER, user);
    },
    addNewOrgAdmin: (state, action: PayloadAction<Array<IOrgProfileAdmins>>) => {
      const orgAdminState = action.payload;
      state.orgAdmins = [...orgAdminState]
    },
    deleteOrg: (state, action: PayloadAction<{ orgId: number }>) => {
      state.orgs = state.orgs.filter(item => item.id !== action.payload.orgId);
      const user = store.get(process.env.REACT_APP_LOCAL_STORAGE_CURR__USER) as UserModel;
      user.orgs = user.orgs?.filter(item => item !== String(action.payload.orgId));
      store.set(process.env.REACT_APP_LOCAL_STORAGE_CURR__USER, user);
    },
    deleteOrgAdmin: (state, action: PayloadAction<{ id: number }>) => {
      state.orgAdmins = state.orgAdmins.filter(item => item.id !== action.payload.id );
    },
    updateOrg: (state, action: PayloadAction<ILightOrganization>) => {
      const orgindex = state.orgs.findIndex(item => item.id == action.payload.id);
      state.orgs[orgindex].name = action.payload.name;
      state.orgs[orgindex].imageName = action.payload.imageName;
    },
    updateNotificationToRead: (state, action: PayloadAction<{ orgId: number }>) => {
      const orgindex = state.orgs.findIndex(item => item.id == action.payload.orgId);
      state.orgs[orgindex].notificationCount = 0;
    }
    ,
    updateBadgeOrgProfileViews: (state, action: PayloadAction<number>) => {
      state.profileViewsCount = action.payload ;
    }
  }
});


export const { addOrgs, addNewOrg,addNewOrgAdmin, deleteOrg ,deleteOrgAdmin, updateOrg, updateNotificationToRead,updateBadgeOrgProfileViews } = orgSlice.actions;


export const adminOrgs = (state: ApplicationState) => state.org?.orgs;
export const orgProfileAdmins = (state: ApplicationState) => state.org?.orgAdmins;
export const orgProfileViewsCount=(state : ApplicationState) => state.org?.profileViewsCount;
export default orgSlice.reducer;

import * as Store from 'store2';
import dir from '../Utils/direction';

export enum Languge {
    AR = "1",
    EN = "2"
}

const getLang = (): Languge => {

    if (window.location.search) {
        if (window.location.search === "?lng=ar") {
            return Languge.AR;
        }
        if (window.location.search === "?lng=en") {
            return Languge.EN;
        }
    }

    const currentLang = (Store.get("i18nextLng") as Languge);

    if (!currentLang) {
        return Languge.EN;
    }

    return currentLang;
}

const setDefaultLang = (lang?: Languge) => {
    if (lang) {
        Store.set("i18nextLng", String(lang));
    } else {
        Store.set("i18nextLng", String(Languge.EN));
    }
}

const changeLang = (lang: Languge) => {
    Store.set("i18nextLng", String(lang));
    dir.setDefaultDir(lang === Languge.EN ? dir.Dir.LTR : dir.Dir.RTL);
    window.location.reload();
}


const loadLangSetup = (lang?: Languge) => {
    const _lang: Languge = getLang();
    setDefaultLang(_lang);
    document.body.setAttribute('lng', String(getlangStrformNum(_lang)));
    dir.setDefaultDir(String(_lang) === Languge.EN ? dir.Dir.LTR : dir.Dir.RTL);
    document.body.setAttribute('dir', String(_lang) === Languge.EN ? dir.Dir.LTR : dir.Dir.RTL);
    dir.loadStyleFiles();
    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + window.location.search;
    newurl = newurl.replace("?lng=ar", "").replace("?lng=en", "");
    window.history.pushState({ path: newurl }, '', newurl);
}


const getlangStrformNum = (num: string) => {
    if (num === "1") return "ar"
    if (num === "2") return "en"
}

const lan = {
    getLang,
    Languge,
    changeLang,
    loadLangSetup
}

export default lan;
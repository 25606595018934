import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { ApplicationState, reducers } from '../Store/index';
import { configureStore, getDefaultMiddleware, combineReducers } from '@reduxjs/toolkit';


const configStore = (history: History, initialState?: ApplicationState) => {

    const middleware = [...getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }), routerMiddleware(history)]

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    });

    const store = configureStore({
        reducer: rootReducer,
        middleware,
        preloadedState: initialState,
        devTools: process.env.NODE_ENV === 'development',
    });


    if (process.env.NODE_ENV === 'development' && module.hot) {
        module.hot.accept('./reducers', () => {
            const newRootReducer = require('./reducers').default
            store.replaceReducer(newRootReducer)
        })
    }

    return store;
}

export default configStore;

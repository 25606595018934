import React, { FC, useEffect, useState } from 'react';
import { Report, models, Embed, service } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import Skeleton from '@material-ui/lab/Skeleton';


import { IEmbedReportPagesService, IEmbedReportService } from 'Models/DashboardModels';
import Select from 'Components/Shared/UI/Select/Select';
import Styles from './EmbedReport.module.scss';
import T from 'Utils/localizerHook';
import { Icon } from 'Utils/Icon';

interface IEmbedProps {
    reportId: string,
    settings: models.IReportEmbedConfiguration,
    defaultPageId?: string,
    pages: Array<IEmbedReportPagesService>,
    getEmbedReport: (report: Report) => void,
    getReportApiEndPoint: (reportId: string) => Promise<IEmbedReportService>
}

/**
 * Power-bi Embed Report component
 * @argument {IEmbedProps} IEmbedProps
 * @returns Embed Power-bi component
 */
const EmbedReport: FC<IEmbedProps> = ({ reportId, settings, pages, defaultPageId, getEmbedReport, getReportApiEndPoint }) => {

    // Report Config object
    const ReportConfigObj = {
        type: 'report',
        tokenType: models.TokenType.Embed,
        settings: settings.settings
    }

    const resources = {
        DashboardsSelectReportSelectLabel: T("Dashboards.SelectReport.Select.Label"),
    }

    // PowerBI Report object (to be received via callback) (state)
    const [report, setReport] = useState<Report>();
    // PowerBI Report has renderd (state)
    const [reportHasRenderd, setReportHasRenderd] = useState<boolean>(false);
    // PowerBI Report Current page Id
    const [pageId, setPageId] = React.useState<string>(String(defaultPageId));
    // Schedule Token Timer to cancle the (RefreashToken) process in unmount
    const [scheduleTokenTimerId, setScheduleTokenTimerId] = useState<NodeJS.Timeout>();
    // PowerBI Report Config (state)
    const [reportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>(ReportConfigObj);

    useEffect(() => {
        return () => {
            if (scheduleTokenTimerId) {
                console.log("ClearTimer", scheduleTokenTimerId);
                clearTimeout(scheduleTokenTimerId);
            }
        }
    }, [scheduleTokenTimerId]);


    // Map of event handlers to be applied to the embedding report
    const eventHandlersMap = new Map([
        ['loaded', function () {
            console.log('Report has loaded');
        }],
        ['rendered', async function () {
            console.log('Report has rendered');
            setReportHasRenderd(true);
        }],
        ['error', async function (event?: service.ICustomEvent<any>) {
            if (event) {
                //console.error(event.detail);
                const error = event.detail;

                // If the error level isn't Fatal, log the error and continue.
                if (error.level !== models.TraceType.Fatal) {
                    console.error(error);
                    return;
                }

                // If the Fatal error is TokenExpired, refresh the token.
                if (error.message === models.CommonErrorCodes.TokenExpired) {
                    if(report) {
                    // Implement your own function here.
                    //const token = await generateEmbededToken(reportId);
                    // Set the new access token.
                    //report.setAccessToken(token.token);
                    console.error(error.message);
                    }
                } else {
                    // If the error isn't TokenExpired, show the custom
                    // dialog with detailed error message in the iframe.
                    // Implement your own function here.
                    //showError(error.detailedMessage);
                    console.error(error.detailedMessage)
                }
            }
        }]
    ]);

    const inputPageChangeHandler = async (PageId: string) => {
        setPageId(PageId);
        await report?.setPage(PageId);
    }

    const resetPageAfterReloadReport = async (tryCounter:number) => {
        let _tryCounter : number = tryCounter;
        try {
            _tryCounter--;            
            if(_tryCounter <= 0) return;
            await report?.setPage(pageId);
        }
        catch (err) {
            setTimeout(async () => {
                resetPageAfterReloadReport(_tryCounter);
            }, 10);
        }
    }

    // Fetch report's config (eg. embedUrl and AccessToken) for embedding
    const SignIn = async (): Promise<IEmbedReportService> => {

        // Generate report EmbedToken
        const embedToken = await generateEmbededToken(reportId);
        console.log(embedToken.expiry);

        // Set the fetched embedUrl and embedToken in the report config
        setReportConfig({
            ...reportConfig,
            embedUrl: embedToken.embeddingUrl,
            accessToken: embedToken.token,
        });

        scheduleGenerateNewEmbedToken(embedToken.secondsToExpire);

        return embedToken;
    };


    const scheduleGenerateNewEmbedToken = (secondsToExpire: number) => {
        // Set an setTimeout to get a new access token and update the state.
        const scheduleGenerateNewEmbedTokenTimerId = setTimeout(async () => {
            const token = await generateEmbededToken(reportId);
            console.log("New token generated");

            if (token) {
                // Set the new fetched embedToken in the report config
                //await report?.setAccessToken(token.token);
                setReportConfig({
                    ...reportConfig,
                    accessToken: token.token,
                    embedUrl: token.embeddingUrl
                });
                console.log(token.token, token.secondsToExpire);
                scheduleGenerateNewEmbedToken(token.secondsToExpire);
            }

        }, (secondsToExpire * 1000) - (10000));

        console.log(scheduleGenerateNewEmbedTokenTimerId);
        setScheduleTokenTimerId(scheduleGenerateNewEmbedTokenTimerId);
    }



    const generateEmbededToken = async (reportId: string): Promise<IEmbedReportService> => {
        return await getReportApiEndPoint(reportId);
    }

    useEffect(() => {
        SignIn();
    }, []);

    return (
        <div className='mt-4'>
            {Boolean(pages.length > 1) ? reportHasRenderd ? <Select
                name="Report"
                label={resources.DashboardsSelectReportSelectLabel}
                value={pageId}
                values={[
                    ...pages.map(item => {
                        return {
                            value: item.powerBiPageId,
                            label: item.displayNameEn
                        }
                    })
                ]}
                onChangeHandler={(event: React.ChangeEvent<HTMLInputElement>) => { inputPageChangeHandler(event.target.value); }}
            /> : <Skeleton animation="pulse" style={{ borderRadius: '5px' }} height={50} /> : null}

            <div className='position-relative border border-1'>
                <button className={`btn position-absolute top-0 mt-1 py-1 px-2 btn-focus-shadow-none ${Styles.ReportBtn}`} style={{ right: '92px' }} onClick={async () => {
                    await report?.reload();
                    await resetPageAfterReloadReport(50);
                }}>
                    <Icon iconname='Refresh' />
                </button>
                <button className={`btn position-absolute top-0 mt-1 py-1 px-2 btn-focus-shadow-none ${Styles.ReportBtn}`} style={{ right: '48px' }} onClick={async () => {
                    await report?.print();
                }}>
                    <Icon iconname='Print' />
                </button>
                <button className={`btn position-absolute top-0 mt-1 py-1 px-2 btn-focus-shadow-none ${Styles.ReportBtn}`} style={{ right: '4px' }} onClick={async () => {
                    await report?.fullscreen();
                }}>
                    <Icon iconname='Fullscreen' />
                </button>
                <PowerBIEmbed
                    embedConfig={{ ...reportConfig, pageName: String(defaultPageId) }}
                    eventHandlers={eventHandlersMap}
                    cssClassName={Styles.report_style_class}
                    getEmbeddedComponent={async (embedObject: Embed) => {
                        setReport(embedObject as Report);
                        // report.on will add an event listener ("pageChanged Event").
                        (embedObject as Report).on("pageChanged", function (event) {
                            let page: models.IPage = Object(event.detail).newPage;
                            setPageId(page.name);
                        });
                        getEmbedReport(embedObject as Report);
                    }}
                />
            </div>
        </div>
    )
}

export default React.memo(EmbedReport, (prevProps, nextProps) => prevProps === nextProps);

import React from 'react';
import { useParams, useHistory } from 'react-router';
import pupa from 'pupa';

import TopBorderedContainer from 'Shared/TopBorderedContainer/TopBorderedContainer';
import { organizationAffiliationsReport, organizationIdentityVerification, organizationNetworksReport, organizationWorksReport } from 'Config/RoutesConstants';
import { ITabItems } from 'Models/NetworkModels';
import Tabs from 'Researcher/Shared/Tabs/Tabs';
import T from "Utils/localizerHook";
import { useSelector } from 'react-redux';
import { adminOrgs } from 'Store/Features/OrgsSlice';
import NoDataPlaceholder from 'Shared/UI/NoData/NoData';


interface IProps {
    children: React.ReactNode
}

const MyOrgReportsContainer: React.FC<IProps> = ({ children }) => {
    const history = useHistory();
    const { join } = useParams<any>();
    const orgsList = useSelector(adminOrgs);
    const isVerifiedOrg = orgsList?.find(item => item.join === join)?.isIdentityVerified;


    const resources = {
        MyOrgWorksReportTabName: T("SideMenu.Org.WorksReport.Link"),
        OrgAffiliationsReportTabName: T("SideMenu.Org.AffiliationsReport.Link"),
        OrgNetworksReport: T("SideMenu.Org.NetworksReport.Link"),
        OrgNotVerifiedNoDataPlaceholderMsg: T("Dashboard.OrgNotVerified.nodataplaceholder.msg"),
        OrgNotVerifiedNoDataPlaceholderActionButtonLabel: T("Dashboard.OrgNotVerified.nodataplaceholder.ActionButton.Label")
    };

    const data: ITabItems = {
        items: [
            { name: resources.OrgAffiliationsReportTabName, routes: [pupa(organizationAffiliationsReport.route, [join])] },
            { name: resources.MyOrgWorksReportTabName, routes: [pupa(organizationWorksReport.route, [join])] },
            { name: resources.OrgNetworksReport, routes: [pupa(organizationNetworksReport.route, [join])] },
        ]
    }
    return (
        <TopBorderedContainer>
            {
                isVerifiedOrg ?
                    <>
                        <Tabs items={data.items} />
                        <div className="border-top border-2 border-muted">
                            {children}
                        </div>
                    </>
                    :
                    <NoDataPlaceholder
                        text={resources.OrgNotVerifiedNoDataPlaceholderMsg}
                        buttonText={resources.OrgNotVerifiedNoDataPlaceholderActionButtonLabel}
                        buttonOnClickAction={() => {
                            history.push(pupa(organizationIdentityVerification.route, [join, ""]))
                        }}
                    />
            }
        </TopBorderedContainer>
    )
}

export default React.memo(MyOrgReportsContainer);

import * as React from 'react';
import { RouteConfig } from "react-router-config";
import { Redirect } from "react-router-dom";
import { makeAuthenticator, Callback } from 'react-oidc';
import { useDispatch } from "react-redux";
import loadable from '@loadable/component';
import store from 'store2';

import routerConfig from './RoutesConstants';
import userManager from 'Utils/userManager';
import auth from 'Utils/Auth';
import MyWorksReport from 'Components/ResearchersPages/Pages/Dashboards/MyReports/MyWorksReport';
import Top10ISAsReport from 'Components/ResearchersPages/Pages/Dashboards/JoipReports/Top10ISAsReport';
import MyOrgWorksReport from 'Components/OrganizationsPages/Pages/Dashboards/MyOrgReports/MyOrgWorksReport';
import OrgAffiliationsReport from 'Components/OrganizationsPages/Pages/Dashboards/MyOrgReports/OrgAffiliationsReport';
import MyOrgNetworksReport from 'Components/OrganizationsPages/Pages/Dashboards/MyOrgReports/MyOrgNetworksReport';
import UserSuspended from 'Components/PublicPages/UserSuspended/UserSuspended';

//Layout
const BaseLayout = loadable(() => import('Layouts/BaseLayout'));
const BasePublicPagesLayout = loadable(() => import('Layouts/PublicBaseLayout'));
const ResearcherTwoColumnsLayout = loadable(() => import('Layouts/ResearcherTwoColumnsLayout'));
const ResearcherThreeColumnsLayout = loadable(() => import('Layouts/ResearcherThreeColumnsLayout'));
const OrganizationTwoColumnsLayout = loadable(() => import('Layouts/OrganizationTwoColumnsLayout'));

//Public & Common Routs
const Home = loadable(() => import('Public/Home/Home'));
const Messages = loadable(() => import('Shared/Messages/Messages'));
const NotFound = loadable(() => import("Public/NotFound/NotFound"));
const AccessDenied = loadable(() => import("Public/AccessDenied/AccessDenied"));
const Policy = loadable(() => import("Public/Policy/Policy"));
const Help = loadable(() => import('Components/PublicPages/Help/Help'));
const ErrorMessage = loadable(() => import("Public/ErrorMessage/ErrorMessage"));
const About = loadable(() => import('Public/About/About'));
const StaticContent = loadable(() => import('Public/StaticContent/StaticContent'));
const ContactUs = loadable(() => import('Public/ContactUs/ContactUs'));
const JoipNews = loadable(() => import('Public/News/News'));
const JoipNewsDetail = loadable(() => import('Public/News/NewsDetail'));
const JoipEvents = loadable(() => import('Public/Events/Events'));
const EmailVerify = loadable(() => import('Public/EmailVerify/EmailVerify'));
const OrgEmailVerify = loadable(() => import('Public/EmailVerify/OrgEmailVerify'));
const ResearcherSearch = loadable(() => import('Public/Search/Search'));
const FAQ = loadable(() => import('Public/FAQ/FAQ'));
const PublicSearch = loadable(() => import('Public/Search/PublicSearch'));
const Refresh = loadable(() => import('Public/Refresh/Refresh'));


//Researchers Routs
const ResearchersHome = loadable(() => import('Researcher/Pages/Home/Home'));
const ResearcherProfile = loadable(() => import('Researcher/Pages/Profile/Profile'));
const ResearcherContact = loadable(() => import('Researcher/Pages/Contact/Contact'));
const ResearcherOverView = loadable(() => import('Researcher/Pages/OverView/OverView'));
const ResearcherSocialLinks = loadable(() => import('Researcher/Pages/SocialLinks/SocialLinks'));
const ResearcherInnovationInterests = loadable(() => import('Researcher/Pages/InnovationInterests/InnovationInterests'));
const ResearcherOutputAndWorksGuide = loadable(() => import('Researcher/Pages/OutputAndWorks/OutputAndWorksGuide'));
const ResearcherAddWork = loadable(() => import('Researcher/Pages/AddWork/Work'));
const MyIDs = loadable(() => import('Researcher/Pages/ProfileIDs/MyIDs'));
const IDsGuides = loadable(() => import('Researcher/Pages/IDsGuides/IDsGuides'));
const ResercherSentRequests = loadable(() => import('Researcher/Pages/Network/Requests/SentRequests'));
const ResercherRecievedRequests = loadable(() => import('Researcher/Pages/Network/Requests/RecievedRequests'));
const ResearcherNetwork = loadable(() => import('Researcher/Pages/Network/MyNetwork/MyNetwork'));
const Following = loadable(() => import('Researcher/Pages/Network/Follows/Following'));
const OrgFollowing = loadable(() => import('Researcher/Pages/Network/Follows/OrgFollowing'));
const Followers = loadable(() => import('Researcher/Pages/Network/Follows/Followers'));
const EasyMatch = loadable(() => import('Researcher/Pages/Network/ProfileMatch/EasyMatch'));
const ResearcherDistinctions = loadable(() => import('Researcher/Pages/Distinction/Distinction'));
const ResearcherEducations = loadable(() => import('Researcher/Pages/Education/Education'));
const ResearcherEmployments = loadable(() => import('Researcher/Pages/Employment/Employment'));
const ResearcherInvitedPositions = loadable(() => import('Researcher/Pages/InvitedPosition/InvitedPosition'));
const ResearcherMemberships = loadable(() => import('Researcher/Pages/Membership/Membership'));
const ResearcherQualifications = loadable(() => import('Researcher/Pages/Qualification/Qualification'));
const ResearcherServices = loadable(() => import('Researcher/Pages/Service/Services'));
const ResearcherIdentityVerification = loadable(() => import('Researcher/Pages/IdentityVerification/IdentityVerification'));
const ResearcherContentVerification = loadable(() => import('Researcher/Pages/ContentVerification/ContentVerification'));
const ContentVerificationRequestDetails = loadable(() => import('Researcher/Pages/ContentVerification/ContentVerificationRequestDetails'));
const ResearcherSentReferralRequest = loadable(() => import('Researcher/Pages/Referral/Request/SentReferralRequest'));
const ResearcherReceivedReferralRequest = loadable(() => import('Researcher/Pages/Referral/Request/ReceivedReferralRequest'));
const ResearcherReferral = loadable(() => import('Researcher/Pages/Referral/Referral'));
const CurriculumVitae = loadable(() => import('Components/ResearchersPages/Pages/CurriculumVitae/CurriculumVitae'));
const VerificationGuides = loadable(() => import('Components/ResearchersPages/Pages/VerificationGuides/VerificationGuides'));
const ResearcherSentWorkFileShareRequest = loadable(() => import('Researcher/Pages/WorkFileShare/Request/SentWorkFileShareRequest'));
const ResearcherReceivedWorkFileShareRequest = loadable(() => import('Researcher/Pages/WorkFileShare/Request/ReceivedWorkFileShareRequest'));
const ResearcherWorkFileShare = loadable(() => import('Researcher/Pages/WorkFileShare/WorkFileShare'));
const ProfileViews = loadable(() => import('Researcher/Pages/ProfileViews/ProfileViews'));
const ContentVerificationGuides = loadable(() => import('Researcher/Pages/ContentVerificationGuide/ContentVerificationGuide'));
const Post = loadable(() => import('Shared/Pages/Post/Post'));
const MyActivityLog = loadable(() => import('Researcher/Pages/ActivityLog/ActivityLog'));
const RingGoldOrgSearch = loadable(() => import('Researcher/Pages/RingGoldOrgSearch/RingGoldOrgSearch'));
const ReportGuides =loadable(() => import('Components/ResearchersPages/Pages/Dashboards/ReportGuides'));
const ChairmanConferences =loadable(() => import('Components/ResearchersPages/Pages/Conferences/Chairman/Conferences'));
const ChairmanConferencesSubmissions =loadable(() => import('Components/ResearchersPages/Pages/Conferences/Chairman/Submissions/ConferencesSubmissions'));
const ChairmanConferenceSubmissionDetails =loadable(() => import('Components/ResearchersPages/Pages/Conferences/Chairman/Submissions/ConferenceSubmissionDetails'));
const ChairmanReviewerQuestionsManagement =loadable(() => import('Components/ResearchersPages/Pages/Conferences/Chairman/Questions/ReviewerQuestionsManagement'));
const ChairmanConferenceQuestionsManagement =loadable(() => import('Components/ResearchersPages/Pages/Conferences/Chairman/Questions/ConferenceQuestionsManagement'));
const ChairmanConferenceDocumentsManagement =loadable(() => import('Components/ResearchersPages/Pages/Conferences/Chairman/Documents/ConferenceDocumentsManagement'));
const ReviewerConferencesSubmissions =loadable(() => import('Components/ResearchersPages/Pages/Conferences/Reviewer/ConferencesSubmissions'));
const ReviewerConferencesSubmissionDetails  =loadable(() => import('Components/ResearchersPages/Pages/Conferences/Reviewer/ReviewerSubmissionDetails'));
const ResearcherConferencesSubmissions =loadable(() => import('Components/ResearchersPages/Pages/Conferences/Submitter/ConferencesSubmissions'));

//Organization Routes
const OrganizationProfile = loadable(() => import('Organization/Pages/Profile/Profile'));
const OrganizationOverview = loadable(() => import('Organization/Pages/OverView/OverView'));
const OrganizationContact = loadable(() => import('Organization/Pages/Contact/Contact'));
const OrganizationSocialLinks = loadable(() => import('Organization/Pages/SocialLinks/SocialLinks'));
const OrganizationWorks = loadable(() => import('Organization/Pages/Works/Work'));
const OrgIDs = loadable(() => import('Organization/Pages/OrgProfileIDs/OrgIDs'));
const OrgIDsGuides = loadable(() => import('Organization/Pages/OrgIDsGuides/OrgIDsGuides'));
const OrgOutputAndWorks = loadable(() => import('Organization/Pages/OrgOutputAndWorks/OrgOutputAndWorks'));
const OrgInnovationInterests = loadable(() => import('Organization/Pages/OrgInnovationInterests/OrgInnovationInterests'));
const OrgFollowers = loadable(() => import('Organization/Pages/OrgFollowers/OrgFollowers'));
const OrgNotifications = loadable(() => import('Organization/Pages/Notification/Notification'));
const Customers = loadable(() => import('Organization/Pages/Customers/Customers'));
const ProductsAndServices = loadable(() => import('Organization/Pages/ProductsAndServices/ProductsAndServices'));
const OrgVerificationGuides = loadable(() => import('Organization/Pages/OrgVerificationGuides/OrgVerificationGuides'));
const OrganizationIdentityVerification = loadable(() => import('Organization/Pages/IdentityVerification/IdentityVerification'));
const OrganizationContentVerificationRequests = loadable(() => import('Organization/Pages/ContentVerification/ContentVerificationRequests'));
const OrganizationContentVerificationRequest = loadable(() => import('Organization/Pages/ContentVerification/ContentVerificationRequest'));
const OrgReceivedWorkFileShareRequest = loadable(() => import('Organization/Pages/WorkFileShare/Request/ReceivedWorkFileShareRequest'));
const OrganizationViews = loadable(() => import('Organization/Pages/OrganizationViews/OrganizationViews'));
const OrgContentVerificationGuide = loadable(() => import('Organization/Pages/OrgContentVerificationGuide/OrgContentVerificationGuide'));
const OrgReportGuides = loadable(() => import('Components/OrganizationsPages/Pages/Dashboards/OrgReportGuides'));
const OrgProfileAdmins = loadable(() => import('Components/OrganizationsPages/Pages/OrgProfileAdmins/OrgProfileAdmins'));
const OrgConferences = loadable(() => import('Organization/Pages/Conferences/Conferences'));
const OrgConferenceSubmissions = loadable(() => import('Organization/Pages/Conferences/Submissions/Submissions'));
const OrgConferenceQuestionsManagement =loadable(() => import('Organization/Pages/Conferences/Questions/ConferenceQuestionsManagement'));
const OrgReviewerQuestionsManagement =loadable(() => import('Organization/Pages/Conferences/Questions/ReviewerQuestionsManagement'));
const OrgConferenceDocumentsManagement =loadable(() => import('Organization/Pages/Conferences/Documents/ConferenceDocumentsManagement'));

const AuthSilentCallBack = auth.SilentCallback;
const SuspendUser = auth.SuspendUser;

const AppWithAuth = makeAuthenticator({
    userManager: userManager,
    signinArgs: {
        state: {}
    }
})(BaseLayout);


const routes = (): RouteConfig[] => {
    const dispatch = useDispatch();

    return [
        {
            name: 'Base Layout',
            path: [
                routerConfig.researcherProfile.patterns[1]
                , routerConfig.researcherProfile.patterns[0]
                , routerConfig.resercherSentRequests.pattern
                , routerConfig.resercherRecievedRequests.pattern
                , routerConfig.researcherOverview.pattern
                , routerConfig.researcherSocialLinks.pattern
                , routerConfig.researcherInnovationInterests.pattern
                , routerConfig.researcherIdsGuides.pattern
                , routerConfig.researcherMyIds.pattern
                , routerConfig.researcherContact.pattern
                , routerConfig.researcherAddWork.pattern
                , routerConfig.researcherOutputAndWorksGuide.pattern
                , routerConfig.researcherNetwork.pattern
                , routerConfig.resercherFollowing.pattern
                , routerConfig.resercherOrgFollowing.pattern
                , routerConfig.resercherFollowers.pattern
                , routerConfig.researcherEasyMatch.pattern
                , routerConfig.researcher.pattern
                , routerConfig.researcherVerificationGuides.pattern
                // , routerConfig.researcherReportGuides.pattern
                , routerConfig.post.patterns[0]
                , routerConfig.organizationProfile.patterns[1]
                , routerConfig.organizationProfile.patterns[0]
                , routerConfig.organizationContact.pattern
                , routerConfig.organizationOverview.pattern
                , routerConfig.organizationSocialLinks.pattern
                , routerConfig.organizationWorks.pattern
                , routerConfig.organizationIds.pattern
                , routerConfig.organizationIdsGuides.pattern
                , routerConfig.organizationOutputAndWorksGuide.pattern
                , routerConfig.organizationInnovationInterests.pattern
                , routerConfig.organizationFollowers.pattern
                , routerConfig.organizationNotifications.pattern
                , routerConfig.organizationProductsServices.pattern
                , routerConfig.organizationCustomers.pattern
                , routerConfig.organizationVerificationGuides.pattern
                , routerConfig.organizationIdentityVerification.pattern
                , routerConfig.organizationContentVerification.pattern
                , routerConfig.organizationContentVerificationRequest.pattern
                , routerConfig.organizationReceivedWorkFileShareRequests.pattern
                , routerConfig.organizationViews.pattern
                , routerConfig.organizationContentVerificationGuides.pattern
                // , routerConfig.organizationReportGuides.pattern
                , routerConfig.organizationWorksReport.pattern
                // , routerConfig.organizationAffiliationsReport.pattern
                , routerConfig.organizationNetworksReport.pattern
                , routerConfig.researcherSearch.pattern
                , routerConfig.researcherService.pattern
                , routerConfig.researcherMembership.pattern
                , routerConfig.researcherQualification.pattern
                , routerConfig.researcherInvitedPosition.pattern
                , routerConfig.researcherEmployment.pattern
                , routerConfig.researcherEducation.pattern
                , routerConfig.researcherDistinction.pattern
                , routerConfig.researcherIdentityVerification.pattern
                , routerConfig.researcherContentVerification.pattern
                , routerConfig.researcherContentVerificationRequest.pattern
                , routerConfig.resercherSentReferralRequests.pattern
                , routerConfig.resercherReceivedReferralRequests.pattern
                , routerConfig.researcherReferrals.pattern
                , routerConfig.researcherCurriculumVitae.pattern
                , routerConfig.researcherSentWorkFileShareRequests.pattern
                , routerConfig.researcherReceivedWorkFileShareRequests.pattern
                , routerConfig.researcherWorkFileShare.pattern
                , routerConfig.researcherProfileViews.pattern
                , routerConfig.researcherContentVerificationGuides.pattern
                , routerConfig.messages.pattern
                , routerConfig.researcherActivityLog.pattern
                , routerConfig.researcherRingGoldSearch.pattern
                , routerConfig.refresh.pattern
                // , routerConfig.resercherWorksReport.pattern
                // , routerConfig.resercherTop10ISAsReport.pattern
                , routerConfig.organizationProfileAdmins.pattern
                , routerConfig.organizationConferences.pattern
                , routerConfig.orgConferenceSubmissions.pattern
                , routerConfig.orgConferenceQuestionsManagement.pattern
                , routerConfig.orgReviewerQuestionsManagement.pattern
                , routerConfig.orgConferenceDocumentsManagement.pattern
                , routerConfig.chairmanConferences.pattern
                , routerConfig.chairmanConferencesSubmissions.pattern
                , routerConfig.chairmanConferenceSubmissionDetails.pattern
                , routerConfig.chairmanConferenceQuestionsManagement.pattern
                , routerConfig.chairmanConferenceDocumentsManagement.pattern
                , routerConfig.chairmanReviewerQuestionsManagement.pattern
                , routerConfig.reviewerConferencesSubmissions.pattern
                , routerConfig.reviewerConferenceSubmissionDetails.pattern
                , routerConfig.researcherConferencesSubmissions.pattern
            ],
            component: BaseLayout,
            routes: [
                {
                    name: 'Researcher Two Columns Layout',
                    path: [
                        routerConfig.researcherProfile.patterns[1],
                        routerConfig.researcherProfile.patterns[0],
                        routerConfig.resercherSentRequests.pattern,
                        routerConfig.resercherRecievedRequests.pattern
                        , routerConfig.researcherOverview.pattern
                        , routerConfig.researcherSocialLinks.pattern
                        , routerConfig.researcherInnovationInterests.pattern
                        , routerConfig.researcherIdsGuides.pattern
                        , routerConfig.researcherMyIds.pattern
                        , routerConfig.researcherContact.pattern
                        , routerConfig.researcherAddWork.pattern
                        , routerConfig.researcherOutputAndWorksGuide.pattern
                        , routerConfig.researcherNetwork.pattern
                        , routerConfig.resercherFollowing.pattern
                        , routerConfig.resercherOrgFollowing.pattern
                        , routerConfig.resercherFollowers.pattern
                        , routerConfig.researcherEasyMatch.pattern
                        , routerConfig.researcherVerificationGuides.pattern
                        , routerConfig.researcherSearch.pattern
                        , routerConfig.researcherService.pattern
                        , routerConfig.researcherMembership.pattern
                        , routerConfig.researcherQualification.pattern
                        , routerConfig.researcherInvitedPosition.pattern
                        , routerConfig.researcherEmployment.pattern
                        , routerConfig.researcherEducation.pattern
                        , routerConfig.researcherDistinction.pattern
                        , routerConfig.researcherIdentityVerification.pattern
                        , routerConfig.researcherContentVerification.pattern
                        , routerConfig.researcherContentVerificationRequest.pattern
                        , routerConfig.resercherSentReferralRequests.pattern
                        , routerConfig.resercherReceivedReferralRequests.pattern
                        , routerConfig.researcherReferrals.pattern
                        , routerConfig.researcherCurriculumVitae.pattern
                        , routerConfig.researcherSentWorkFileShareRequests.pattern
                        , routerConfig.researcherReceivedWorkFileShareRequests.pattern
                        , routerConfig.researcherWorkFileShare.pattern
                        , routerConfig.researcherProfileViews.pattern
                        , routerConfig.researcherContentVerificationGuides.pattern
                        , routerConfig.researcherActivityLog.pattern
                        , routerConfig.researcherRingGoldSearch.pattern
                        // , routerConfig.researcherReportGuides.pattern
                        // , routerConfig.resercherWorksReport.pattern
                        // , routerConfig.resercherTop10ISAsReport.pattern                        
                        , routerConfig.chairmanConferences.pattern
                        , routerConfig.chairmanConferencesSubmissions.pattern
                        , routerConfig.chairmanConferenceSubmissionDetails.pattern
                        , routerConfig.chairmanConferenceQuestionsManagement.pattern
                        , routerConfig.chairmanConferenceDocumentsManagement.pattern
                        , routerConfig.chairmanReviewerQuestionsManagement.pattern
                        , routerConfig.reviewerConferencesSubmissions.pattern
                        , routerConfig.reviewerConferenceSubmissionDetails.pattern
                        , routerConfig.researcherConferencesSubmissions.pattern
                    ],
                    component: ResearcherTwoColumnsLayout,
                    routes: [
                        {
                            exact: true,
                            name: 'Researcher Profile with work modal',
                            path: routerConfig.researcherProfile.patterns[1],
                            component: ResearcherProfile,
                        },
                        {
                            exact: true,
                            name: 'Researcher Profile',
                            path: routerConfig.researcherProfile.patterns[0],
                            component: ResearcherProfile,
                        },
                        {
                            name: 'Researcher Sent Requests',
                            path: routerConfig.resercherSentRequests.pattern,
                            render: (routeProps) => ProtectedRoute(<ResercherSentRequests />, routeProps),
                        },
                        {
                            name: 'Researcher Recieved Requests',
                            path: routerConfig.resercherRecievedRequests.pattern,
                            render: (routeProps) => ProtectedRoute(<ResercherRecievedRequests />, routeProps),
                        },
                        {
                            name: 'Researcher Overview',
                            path: routerConfig.researcherOverview.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherOverView />, routeProps),
                        },
                        {
                            name: 'Researcher Social Linkes',
                            path: routerConfig.researcherSocialLinks.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherSocialLinks />, routeProps),
                        },
                        {
                            name: 'Researcher Contact & Basic Info',
                            path: routerConfig.researcherContact.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherContact />, routeProps),
                        },
                        {
                            name: 'Researcher Innovation Interests',
                            path: routerConfig.researcherInnovationInterests.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherInnovationInterests />, routeProps),
                        },
                        {
                            name: 'Researcher Ids Guides',
                            path: routerConfig.researcherIdsGuides.pattern,
                            render: (routeProps) => ProtectedRoute(<IDsGuides />, routeProps),
                        },
                        {
                            name: 'Researcher My Ids',
                            path: routerConfig.researcherMyIds.pattern,
                            render: (routeProps) => ProtectedRoute(<MyIDs />, routeProps),
                        },
                        {
                            name: 'Researcher Works Guides',
                            path: routerConfig.researcherOutputAndWorksGuide.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherOutputAndWorksGuide />, routeProps),
                        },
                        {
                            name: 'Researcher Add Work',
                            path: routerConfig.researcherAddWork.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherAddWork />, routeProps),
                        },
                        {
                            name: 'Researcher Network',
                            path: routerConfig.researcherNetwork.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherNetwork />, routeProps),
                        },
                        {
                            name: 'Researcher Following',
                            path: routerConfig.resercherFollowing.pattern,
                            render: (routeProps) => ProtectedRoute(<Following />, routeProps),
                        },
                        {
                            name: 'Researcher Org Following',
                            path: routerConfig.resercherOrgFollowing.pattern,
                            render: (routeProps) => ProtectedRoute(<OrgFollowing />, routeProps),
                        },
                        {
                            name: 'Researcher Followers',
                            path: routerConfig.resercherFollowers.pattern,
                            render: (routeProps) => ProtectedRoute(<Followers />, routeProps),
                        },
                        {
                            name: 'Researcher Easy Match',
                            path: routerConfig.researcherEasyMatch.pattern,
                            render: (routeProps) => ProtectedRoute(<EasyMatch />, routeProps),
                        },
                        {
                            name: 'Researcher Distinctions',
                            path: routerConfig.researcherDistinction.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherDistinctions />, routeProps),
                        },
                        {
                            name: 'Researcher Educations',
                            path: routerConfig.researcherEducation.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherEducations />, routeProps),
                        },
                        {
                            name: 'Researcher Employments',
                            path: routerConfig.researcherEmployment.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherEmployments />, routeProps),
                        },
                        {
                            name: 'Researcher InvitedPositions',
                            path: routerConfig.researcherInvitedPosition.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherInvitedPositions />, routeProps),
                        },
                        {
                            name: 'Researcher Qualifications',
                            path: routerConfig.researcherQualification.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherQualifications />, routeProps),
                        },
                        {
                            name: 'Researcher Memberships',
                            path: routerConfig.researcherMembership.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherMemberships />, routeProps),
                        },
                        {
                            name: 'Researcher Services',
                            path: routerConfig.researcherService.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherServices />, routeProps),
                        },
                        {
                            name: 'Researcher Identity Verification',
                            path: routerConfig.researcherIdentityVerification.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherIdentityVerification />, routeProps),
                        },
                        {
                            name: 'Researcher Content Verification',
                            path: routerConfig.researcherContentVerificationRequest.pattern,
                            render: (routeProps) => ProtectedRoute(<ContentVerificationRequestDetails />, routeProps),
                        },
                        {
                            name: 'Researcher Content Verification',
                            path: routerConfig.researcherContentVerification.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherContentVerification />, routeProps),
                        },
                        {
                            name: 'Researcher Search',
                            path: routerConfig.researcherSearch.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherSearch />, routeProps),
                        },
                        {
                            name: 'Researcher Verification Guides',
                            path: routerConfig.researcherVerificationGuides.pattern,
                            render: (routeProps) => ProtectedRoute(<VerificationGuides />, routeProps),
                        },
                        {
                            name: 'Researcher Sent Referral Requests',
                            path: routerConfig.resercherSentReferralRequests.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherSentReferralRequest />, routeProps),
                        },
                        {
                            name: 'Researcher Received Referral Requests',
                            path: routerConfig.resercherReceivedReferralRequests.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherReceivedReferralRequest />, routeProps),
                        },
                        {
                            name: 'Researcher referrals',
                            path: routerConfig.researcherReferrals.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherReferral />, routeProps),
                        },
                        {
                            name: 'Researcher Curriculum vitae',
                            path: routerConfig.researcherCurriculumVitae.pattern,
                            render: (routeProps) => ProtectedRoute(<CurriculumVitae />, routeProps),
                        },
                        {
                            name: 'Researcher Sent Work File Share Requests',
                            path: routerConfig.researcherSentWorkFileShareRequests.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherSentWorkFileShareRequest />, routeProps),
                        },
                        {
                            name: 'Researcher Received Work File Share Requests',
                            path: routerConfig.researcherReceivedWorkFileShareRequests.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherReceivedWorkFileShareRequest />, routeProps),
                        },
                        {
                            name: 'Researcher Work File Share',
                            path: routerConfig.researcherWorkFileShare.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherWorkFileShare />, routeProps),
                        },
                        {
                            name: 'Researcher Profile Views',
                            path: routerConfig.researcherProfileViews.pattern,
                            render: (routeProps) => ProtectedRoute(<ProfileViews />, routeProps),
                        },
                        {
                            name: 'Researcher Content Verification Guides',
                            path: routerConfig.researcherContentVerificationGuides.pattern,
                            render: (routeProps) => ProtectedRoute(<ContentVerificationGuides />, routeProps),
                        },
                        {
                            name: 'Researcher Actitvity Log',
                            path: routerConfig.researcherActivityLog.pattern,
                            render: (routeProps) => ProtectedRoute(<MyActivityLog />, routeProps),
                        },
                        {
                            name: 'Researcher RingGold Org Search',
                            path: routerConfig.researcherRingGoldSearch.pattern,
                            render: (routeProps) => ProtectedRoute(<RingGoldOrgSearch />, routeProps),
                        },
                        {
                            name: 'Resercher Works Report',
                            path: routerConfig.resercherWorksReport.pattern,
                            render: (routeProps) => ProtectedRoute(<MyWorksReport />, routeProps),
                        },
                        {
                            name: 'Resercher Top10 ISAs Report',
                            path: routerConfig.resercherTop10ISAsReport.pattern,
                            render: (routeProps) => ProtectedRoute(<Top10ISAsReport />, routeProps),
                        },
                        {
                            name: 'Researcher Report Guides',
                            path: routerConfig.researcherReportGuides.pattern,
                            render: (routeProps) => ProtectedRoute(<ReportGuides />, routeProps),
                        },                    
                        {
                            name: 'Chairman Conferences',
                            path: routerConfig.chairmanConferences.pattern,
                            render: (routeProps) => ProtectedRoute(<ChairmanConferences />, routeProps),
                        },
                        {
                            name: 'Chairman Conferences Submissions',
                            path: routerConfig.chairmanConferencesSubmissions.pattern,
                            render: (routeProps) => ProtectedRoute(<ChairmanConferencesSubmissions />, routeProps),
                        },
                        {
                            name: 'Chairman Conference Submission Details',
                            path: routerConfig.chairmanConferenceSubmissionDetails.pattern,
                            render: (routeProps) => ProtectedRoute(<ChairmanConferenceSubmissionDetails />, routeProps),
                        },
                        {
                            name: 'Chairman Manage Reviewer Questions',
                            path: routerConfig.chairmanReviewerQuestionsManagement.pattern,
                            render: (routeProps) => ProtectedRoute(<ChairmanReviewerQuestionsManagement />, routeProps),
                        },
                        {
                            name: 'Chairman Manage Conference Questions',
                            path: routerConfig.chairmanConferenceQuestionsManagement.pattern,
                            render: (routeProps) => ProtectedRoute(<ChairmanConferenceQuestionsManagement />, routeProps),
                        },
                        {
                            name: 'Chairman Manage Conference Documents',
                            path: routerConfig.chairmanConferenceDocumentsManagement.pattern,
                            render: (routeProps) => ProtectedRoute(<ChairmanConferenceDocumentsManagement />, routeProps),
                        },
                        {
                            name: 'Reviewer Conferences Submissions',
                            path: routerConfig.reviewerConferencesSubmissions.pattern,
                            render: (routeProps) => ProtectedRoute(<ReviewerConferencesSubmissions />, routeProps),
                        },
                        {
                            name: 'Reviewer Conferences Submission Details',
                            path: routerConfig.reviewerConferenceSubmissionDetails.pattern,
                            render: (routeProps) => ProtectedRoute(<ReviewerConferencesSubmissionDetails />, routeProps),
                        },
                        {
                            name: 'Researcher Conferences Submissions',
                            path: routerConfig.researcherConferencesSubmissions.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearcherConferencesSubmissions />, routeProps),
                        },
                    ]
                },
                {
                    name: 'Researcher Three Columns Layout',
                    path: [
                        routerConfig.researcher.pattern,
                        routerConfig.post.patterns[0],
                    ],
                    component: ResearcherThreeColumnsLayout,
                    routes: [
                        {
                            name: 'Researcher Feed',
                            path: routerConfig.researcher.pattern,
                            render: (routeProps) => ProtectedRoute(<ResearchersHome />, routeProps),
                        },
                        {
                            exact: true,
                            name: 'Researcher Post',
                            path: routerConfig.post.patterns[0],
                            component: Post,
                        },
                    ]
                },
                {
                    name: 'Organization Two Columns Layout',
                    path: [
                        routerConfig.organizationProfile.patterns[1],
                        routerConfig.organizationProfile.patterns[0],
                        routerConfig.organizationOverview.pattern,
                        routerConfig.organizationContact.pattern,
                        routerConfig.organizationSocialLinks.pattern,
                        routerConfig.organizationWorks.pattern,
                        routerConfig.organizationIds.pattern,
                        routerConfig.organizationIdsGuides.pattern,
                        routerConfig.organizationOutputAndWorksGuide.pattern,
                        routerConfig.organizationInnovationInterests.pattern,
                        routerConfig.organizationFollowers.pattern,
                        routerConfig.organizationNotifications.pattern,
                        routerConfig.organizationProductsServices.pattern,
                        routerConfig.organizationCustomers.pattern,
                        routerConfig.organizationVerificationGuides.pattern,
                        routerConfig.organizationIdentityVerification.pattern,
                        routerConfig.organizationContentVerification.pattern,
                        routerConfig.organizationContentVerificationRequest.pattern,
                        routerConfig.organizationReceivedWorkFileShareRequests.pattern,
                        routerConfig.organizationViews.pattern,
                        routerConfig.organizationContentVerificationGuides.pattern,
                        routerConfig.organizationWorksReport.pattern,
                        // routerConfig.organizationAffiliationsReport.pattern,
                        routerConfig.organizationNetworksReport.pattern,
                        // routerConfig.organizationReportGuides.pattern,
                        routerConfig.organizationProfileAdmins.pattern,
                        routerConfig.organizationConferences.pattern,
                        routerConfig.orgConferenceSubmissions.pattern,
                        routerConfig.orgConferenceQuestionsManagement.pattern,
                        routerConfig.orgReviewerQuestionsManagement.pattern,
                        routerConfig.orgConferenceDocumentsManagement.pattern,

                    ],
                    component: OrganizationTwoColumnsLayout,
                    routes: [
                        {
                            exact: true,
                            name: 'Organization Profile with work modal',
                            path: routerConfig.organizationProfile.patterns[1],
                            component: OrganizationProfile,
                        },
                        {
                            exact: true,
                            name: 'Organization Profile',
                            path: routerConfig.organizationProfile.patterns[0],
                            component: OrganizationProfile,
                        },
                        {
                            name: 'Organization Overview Info',
                            path: routerConfig.organizationOverview.pattern,
                            render: (routeProps) => ProtectedRoute(<OrganizationOverview />, routeProps),
                        },
                        {
                            name: 'Organization Contact & Basic Info',
                            path: routerConfig.organizationContact.pattern,
                            render: (routeProps) => ProtectedRoute(<OrganizationContact />, routeProps),
                        },
                        {
                            name: 'Organization Social Links',
                            path: routerConfig.organizationSocialLinks.pattern,
                            render: (routeProps) => ProtectedRoute(<OrganizationSocialLinks />, routeProps),
                        },
                        {
                            name: 'Organization Works',
                            path: routerConfig.organizationWorks.pattern,
                            render: (routeProps) => ProtectedRoute(<OrganizationWorks />, routeProps),
                        },
                        {
                            name: 'Organization Products',
                            path: routerConfig.organizationProductsServices.pattern,
                            render: (routeProps) => ProtectedRoute(<ProductsAndServices />, routeProps),
                        },
                        {
                            name: 'Organization Customers',
                            path: routerConfig.organizationCustomers.pattern,
                            render: (routeProps) => ProtectedRoute(<Customers />, routeProps),
                        },
                        {
                            name: 'Organization Ids',
                            path: routerConfig.organizationIds.pattern,
                            render: (routeProps) => ProtectedRoute(<OrgIDs />, routeProps),
                        },
                        {
                            name: 'Organization Ids Guides',
                            path: routerConfig.organizationIdsGuides.pattern,
                            render: (routeProps) => ProtectedRoute(<OrgIDsGuides />, routeProps),
                        },
                        {
                            name: 'Organization work Guides',
                            path: routerConfig.organizationOutputAndWorksGuide.pattern,
                            render: (routeProps) => ProtectedRoute(<OrgOutputAndWorks />, routeProps),
                        },
                        {
                            name: 'Organization Innovation Interest',
                            path: routerConfig.organizationInnovationInterests.pattern,
                            render: (routeProps) => ProtectedRoute(<OrgInnovationInterests />, routeProps),
                        },
                        {
                            name: 'Organization Followers',
                            path: routerConfig.organizationFollowers.pattern,
                            render: (routeProps) => ProtectedRoute(<OrgFollowers />, routeProps),
                        },
                        {
                            name: 'Organization Notifications',
                            path: routerConfig.organizationNotifications.pattern,
                            render: (routeProps) => ProtectedRoute(<OrgNotifications />, routeProps),
                        },
                        {
                            name: 'Organization Views',
                            path: routerConfig.organizationViews.pattern,
                            render: (routeProps) => ProtectedRoute(<OrganizationViews />, routeProps),
                        },
                        {
                            name: 'Organization Verification Guides',
                            path: routerConfig.organizationVerificationGuides.pattern,
                            render: (routeProps) => ProtectedRoute(<OrgVerificationGuides />, routeProps),
                        },
                        {
                            name: 'Organization Identity Verification',
                            path: routerConfig.organizationIdentityVerification.pattern,
                            render: (routeProps) => ProtectedRoute(<OrganizationIdentityVerification />, routeProps),
                        },
                        {
                            name: 'Organization Content Verification Requests',
                            path: routerConfig.organizationContentVerification.pattern,
                            render: (routeProps) => ProtectedRoute(<OrganizationContentVerificationRequests />, routeProps),
                        },
                        {
                            name: 'Organization Content Verification Request',
                            path: routerConfig.organizationContentVerificationRequest.pattern,
                            render: (routeProps) => ProtectedRoute(<OrganizationContentVerificationRequest />, routeProps),
                        },
                        {
                            name: 'Organization Work File Share Received Request',
                            path: routerConfig.organizationReceivedWorkFileShareRequests.pattern,
                            render: (routeProps) => ProtectedRoute(<OrgReceivedWorkFileShareRequest />, routeProps),
                        },
                        {
                            name: 'Organization Content Verification Guides',
                            path: routerConfig.organizationContentVerificationGuides.pattern,
                            render: (routeProps) => ProtectedRoute(<OrgContentVerificationGuide />, routeProps),
                        },
                        {
                            name: 'Organization Works Report',
                            path: routerConfig.organizationWorksReport.pattern,
                            render: (routeProps) => ProtectedRoute(<MyOrgWorksReport />, routeProps),
                        },
                        {
                            name: 'Organization Affiliations Report',
                            path: routerConfig.organizationAffiliationsReport.pattern,
                            render: (routeProps) => ProtectedRoute(<OrgAffiliationsReport />, routeProps),
                        },
                        {
                            name: 'Organization Networks Report',
                            path: routerConfig.organizationNetworksReport.pattern,
                            render: (routeProps) => ProtectedRoute(<MyOrgNetworksReport />, routeProps),
                        },
                        {
                            name: 'Organization Report Guides',
                            path: routerConfig.organizationReportGuides.pattern,
                            render: (routeProps) => ProtectedRoute(<OrgReportGuides />, routeProps),
                        },
                        {
                            name: 'Organization Profile Admins',
                            path: routerConfig.organizationProfileAdmins.pattern,
                            render: (routeProps) => ProtectedRoute(<OrgProfileAdmins />, routeProps),
                        },
                        {
                            name: 'Organization Conferences',
                            path: routerConfig.organizationConferences.pattern,
                            render: (routeProps) => ProtectedRoute(<OrgConferences />, routeProps),
                        },
                        {
                            name: 'Org Admin Conference Submissions',
                            path: routerConfig.orgConferenceSubmissions.pattern,
                            render: (routeProps) => ProtectedRoute(<OrgConferenceSubmissions />, routeProps),
                        },
                        {
                            name: 'Org Admin Manage Conference Questions',
                            path: routerConfig.orgConferenceQuestionsManagement.pattern,
                            render: (routeProps) => ProtectedRoute(<OrgConferenceQuestionsManagement />, routeProps),
                        },
                        {
                            name: 'Org Admin Manage Reviewer Questions',
                            path: routerConfig.orgReviewerQuestionsManagement.pattern,
                            render: (routeProps) => ProtectedRoute(<OrgReviewerQuestionsManagement />, routeProps),
                        },
                        {
                            name: 'Org Admin Manage Conference Documents',
                            path: routerConfig.orgConferenceDocumentsManagement.pattern,
                            render: (routeProps) => ProtectedRoute(<OrgConferenceDocumentsManagement />, routeProps),
                        },
                    ]
                },
                {
                    name: 'Messages',
                    path: routerConfig.messages.pattern,
                    render: (routeProps) => ProtectedRoute(<Messages />, routeProps),
                },
                {
                    name: 'Refresh',
                    path: routerConfig.refresh.pattern,
                    component: Refresh,
                }
            ]
        },
        {
            name: 'Auth Callback',
            path: routerConfig.authCallback.pattern,
            render: (routeProps) => <Callback
                onSuccess={(data: any) => {
                    // const user: UserModel = {
                    //     id: data.profile.sub,
                    //     jrin: data.profile.jrin,
                    //     firstName: data.profile.firstName,
                    //     familyName: data.profile.familyName,
                    //     profilePicName: data.profile?.profilePic,
                    //     orgs: data.profile?.orgs?.split(",")
                    // }
                    //dispatch(addUser(user));
                    //routeProps.history.push(routerConfig.researcher.route);
                    window.location.href = routerConfig.researcher.route;
                }}
                userManager={userManager}
            />,
        },
        {
            name: 'Silet Auth Callback',
            path: routerConfig.silentCallback.pattern,
            render: () => <AuthSilentCallBack />,
        },
        {
            name: 'Suspend User',
            path: routerConfig.suspendUser.pattern,
            render: () => <SuspendUser />,
        },
        {
            name: 'Public Base Layout',
            path: [
                routerConfig.defaultRoute.pattern,
                routerConfig.home.pattern,
                routerConfig.policy.pattern,
                routerConfig.faq.pattern,
                routerConfig.help.pattern,
                routerConfig.errorRoute.pattern,
                routerConfig.about.pattern,
                routerConfig.aboutJoip.pattern,
                routerConfig.whoWeAre.pattern,
                routerConfig.joipServices.pattern,
                routerConfig.joipNews.pattern,
                routerConfig.joipNewsDetail.pattern,
                routerConfig.joipEvents.pattern,
                routerConfig.individuals.pattern,
                routerConfig.organizations.pattern,
                routerConfig.patnerWithJoip.pattern,
                routerConfig.publicSearch.pattern,
                routerConfig.emailVerify.pattern,
                routerConfig.orgEmailVerify.pattern,
                routerConfig.accessdenied.pattern,
                routerConfig.notDound.pattern,
            ],
            component: BasePublicPagesLayout,
            routes: [
                {
                    name: 'Home',
                    path: routerConfig.defaultRoute.pattern,
                    exact: true,
                    render: (routeProps) => CheckUser(<Home />, routeProps),

                },
                {
                    path: routerConfig.home.pattern,
                    render: (routeProps) => CheckUser(<Home />, routeProps),
                },
                {
                    path: routerConfig.policy.pattern,
                    component: Policy
                },
                {
                    path: routerConfig.faq.pattern,
                    component: FAQ
                },
                {
                    path: routerConfig.help.pattern,
                    component: Help
                },
                {
                    name: 'Error',
                    path: routerConfig.errorRoute.pattern,
                    component: ErrorMessage
                },
                {
                    name: 'About',
                    path: routerConfig.about.pattern,
                    component: About
                },
                {
                    name: 'About Joib',
                    path: routerConfig.aboutJoip.pattern,
                    component: StaticContent
                },
                {
                    name: 'Who We Are',
                    path: routerConfig.whoWeAre.pattern,
                    component: StaticContent
                },
                {
                    name: 'Joib Services',
                    path: routerConfig.joipServices.pattern,
                    component: StaticContent
                },
                {
                    name: 'Joib News Detail',
                    path: routerConfig.joipNewsDetail.pattern,
                    component: JoipNewsDetail
                },
                {
                    name: 'Joib News',
                    path: routerConfig.joipNews.pattern,
                    component: JoipNews
                },
                {
                    name: 'Joib Events',
                    path: routerConfig.joipEvents.pattern,
                    component: JoipEvents
                },
                {
                    name: 'Individuals',
                    path: routerConfig.individuals.pattern,
                    component: StaticContent
                },
                {
                    name: 'Organization',
                    path: routerConfig.organizations.pattern,
                    component: StaticContent
                },
                {
                    name: 'Partner With Joip',
                    path: routerConfig.patnerWithJoip.pattern,
                    component: ContactUs
                },
                {
                    name: 'Public Search',
                    path: routerConfig.publicSearch.pattern,
                    component: PublicSearch,
                },
                {
                    name: 'Email Verify',
                    path: routerConfig.emailVerify.pattern,
                    component: EmailVerify
                },
                {
                    name: 'Org Email Verify',
                    path: routerConfig.orgEmailVerify.pattern,
                    component: OrgEmailVerify
                },
                {
                    name: 'Access Denied',
                    path: routerConfig.accessdenied.pattern,
                    component: AccessDenied
                },
                {
                    name: 'User Suspended',
                    path: routerConfig.userSuspended.pattern,
                    component: UserSuspended
                },
                {
                    name: '404',
                    path: routerConfig.notDound.pattern,
                    component: NotFound
                },
            ]
        },
        {
            name: 'Auth',
            render: () => AppWithAuth
        },
    ];
}
export default routes

//Check Protected Routes
const ProtectedRoute = (component: any, routeProps: any) => {
    let _componetn: any = component;

    if (!auth.isAuthenticated()) {
        auth.login();
        _componetn = <Redirect to={routerConfig.accessdenied.route} />
    }
    return _componetn;
};

const CheckUser = (component: any, routeProps: any) => {
    let _componetn: any = component;

    var value = store.get("isSuspended");
    if(value){
        _componetn = <Redirect to={routerConfig.userSuspended.route} />
    }

    return _componetn;
};
import { BaseService } from "./Core/BaseService";
import { IPolicy, IStaticContentModel, IAbout,IFAQ, INewsItem, IEventItem, ILandingPageContent, IBanner, IJoipContactInfo, IGlossay, IContactContent } from '../Models/PublicContentModels';
import { AxiosResponse } from 'axios';

export default class PublicContentService extends BaseService {

    public async getListPolicies(): Promise<IPolicy[]> {
        return this.Api.get(`PublicPages/ListPolicies`).then((response) => {
            return response.data;
        })
            .catch((error) => {
                throw new Error(error);
            })
    }

    public async getStaticContent(screenName: string): Promise<IStaticContentModel> {
        const url = `PublicPages/GetStaticContent?screenName=${screenName}`;
        return this.Api.get(url).then((response) => {
            return response.data;
        })
            .catch((error) => {
                this.throwException(error)
                throw new Error(error);
            })
    }

    public async getAboutContent(): Promise<IAbout[]> {
        return this.Api.get(`PublicPages/ListAboutPageCards`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw new Error(error);
            })
    }

    public async getListNews(): Promise<INewsItem[]> {
        return this.Api.get(`PublicPages/ListNewsCards`)
            .then((Res: AxiosResponse<Array<INewsItem>>) => {
                return Res.data;
            })
            .catch((error) => {
                throw new Error(error);
            })
    }

    public async getNewsById(id: number): Promise<INewsItem> {
        return this.Api.get(`PublicPages/GetNewsInfo`, {params:{ id: id }})
            .then((Res: AxiosResponse<INewsItem>) => {
                return Res.data;
            })
            .catch((error) => {
                throw new Error(error);
            })
    }

    public async getListEvent(): Promise<IEventItem[]> {
        return this.Api.get(`PublicPages/ListEventCards`)
            .then((Res: AxiosResponse<Array<IEventItem>>) => {
                return Res.data;
            })
            .catch((error) => {
                throw new Error(error);
            })
    }

    public async getLandingPageContent(): Promise<ILandingPageContent> {
        return this.Api.get(`PublicPages/GetLandingPageContent`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw new Error(error);
            })
    }

    public async getScreenName(screenName: string): Promise<IBanner> {
        const url = `PublicPages/GetScreenImageHeader?screenName=${screenName}`
        return this.Api.get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw new Error(error);
            })
    }

    public async getJoipContactInfo(): Promise<IJoipContactInfo> {
        return this.Api.get(`PublicPages/JoipContactInfo`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw new Error(error);
            })
    }

    public async getGlossary(): Promise<IGlossay[]> {
        return this.Api.get(`PublicPages/ListGlossary`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            })
    }

    public async getFAQ(): Promise<IFAQ[]> {
        return this.Api.get(`PublicPages/ListFAQ`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            })
    }

    public async createContactInquiry(data: any): Promise<void> {
        return this.Api.post(`PublicPages/CreateContactInquiry`, data)
            .then(res => res.data)
            .catch((error) => {
                if(error.response?.status === 400){
                    if(error.response?.data?.errors["RecaptchaToken"]?.length > 0){
                        this.throwException(error, {errorCode: 400, msgKey: "Validation.Msg.RecaptchaTokenVerificationError"});
                        throw error;
                    }
                }                                   
                this.throwException(error);
                throw error;
            })
    }

    public async getContactContent(): Promise<IContactContent> {
        return this.Api.get(`PublicPages/GetContactContent`).then((response) => {
            return response.data;
        })
            .catch((error) => {
                throw error;
            })
    }
}
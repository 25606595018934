import React from 'react';
import { useDispatch, useSelector, } from "react-redux";
import { useHistory } from 'react-router-dom';

import userMgr from '../Utils/userManager';
import { IReceivedNetworkNotification, IReceivedGeneralNotification, GENERAL_NOTIFICATION_TYPE } from '../Models/Notifications'
import { HubConnectionBuilder } from '@microsoft/signalr';
import { addNetworkNotificationStack, addGeneralNotificationStack, AddOrgAdmin } from '../Store/Features/Notifications/NotificationSlice';
import { Settings } from 'Utils/Settings';
import { updateUser, updateUserChairmanPermission, updateUserReviewerPermission } from 'Store/Features/UserSlice';
import { VERIFICATION_STATUS } from 'Models/ProfileModels';
import { deleteOrg, deleteOrgAdmin } from 'Store/Features/OrgsSlice';
import pupa from 'pupa';
import { researcherProfile, suspendUser } from 'Config/RoutesConstants';
import { setError as errorMes } from 'Components/Shared/UI/Toast/Toast';
import T from 'Utils/localizerHook';

const NotificationHub = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const resources = {
        NoPermissionToDelete: T("deleteProfileAdmin.deleteted.unSuccessfullyNoPermission")
    }

    const setTheConnceiotn = async () => {

        let userAccessToken = await userMgr.getUser().then((user) => user ? user.access_token : "").catch(() => "");

        const connection = new HubConnectionBuilder()
            .withUrl(Settings.signalR_HubEndpoint!, { accessTokenFactory: () => userAccessToken })
            .withAutomaticReconnect().build();

        connection.on('ReceivedNetworkNotification', (response) => {
            const data: IReceivedNetworkNotification = JSON.parse(response);
            dispatch(addNetworkNotificationStack(data));
        });

        connection.on('ReceivedGeneralNotification', (response) => {

            const data: IReceivedGeneralNotification = JSON.parse(response);

            if(data.Type != GENERAL_NOTIFICATION_TYPE.ProfileSuspension){
                dispatch(addGeneralNotificationStack(data))
            }

            const dynamicData = JSON.parse(data.Data);

            switch (data.Type) {
                case GENERAL_NOTIFICATION_TYPE.AddOrgProfileAdmin:
                    dispatch(AddOrgAdmin());
                    break
                case GENERAL_NOTIFICATION_TYPE.DeleteOrgProfileAdmin:
                    dispatch(deleteOrg({ orgId: dynamicData.OrgProfileId }));
                    dispatch(deleteOrgAdmin({ id: dynamicData.Id }));
                    var currentUrl = window.location.pathname.split('/');
                    if (currentUrl[currentUrl.length - 1] === "admins") {
                        history.push(pupa(researcherProfile.routes[0], [dynamicData.JRIN + '', 'about']));
                        errorMes(resources.NoPermissionToDelete);
                    }
                    break;
                case GENERAL_NOTIFICATION_TYPE.ProfileIdentityVerification:
                    if (dynamicData.Status === VERIFICATION_STATUS.Accepted) {
                        dispatch(updateUser({ isIdentityVerified: true }));
                    } else {
                        dispatch(updateUser({ isIdentityVerified: false }));
                    }
                    break;
                case GENERAL_NOTIFICATION_TYPE.AddChairman:
                    dispatch(updateUserChairmanPermission(true));
                    break;
                case GENERAL_NOTIFICATION_TYPE.AddReviewer:
                    dispatch(updateUserReviewerPermission(true));
                    break;
                case GENERAL_NOTIFICATION_TYPE.ProfileSuspension:
                    history.push(suspendUser.route);
                    break;
            }

        });

        try {
            await connection.start();
        } catch (err) {
            console.log(err);
        }
    }

    React.useEffect(() => {
        setTheConnceiotn();
    }, [])


    return <></>;
}

export default React.memo(NotificationHub);


import React from 'react';

import TopBorderedContainer from 'Shared/TopBorderedContainer/TopBorderedContainer';
import { resercherTop10ISAsReport } from 'Config/RoutesConstants';
import { ITabItems } from 'Models/NetworkModels';
import Tabs from 'Researcher/Shared/Tabs/Tabs';
import T from "Utils/localizerHook";

interface IProps {
    children: React.ReactNode
}

const JoipReportsContainer: React.FC<IProps> = ({ children }) => {

    const resources = {
        Top10ISAsReportTabName: T("SideMenu.Top10ISAsReport.Link"),
    };

    const data: ITabItems = {
        items: [
            { name: resources.Top10ISAsReportTabName, routes: [resercherTop10ISAsReport.route] },
        ]
    }
    return (
        <TopBorderedContainer>
            <Tabs items={data.items} />
            <div className="border-top border-2 border-muted">    
                {children}
            </div>
        </TopBorderedContainer>
    )
}

export default React.memo(JoipReportsContainer);

import * as React from 'react';
import { useQuery } from 'react-query';

import { IBanner } from 'Models/PublicContentModels';
import PublicContentService from 'Services/PublicContentService';
import Localizer from 'Utils/stringLocalizer'
import dir from 'Utils/direction'
import constants from 'Utils/constants'
import { Settings } from 'Utils/Settings';
import { DigitalLine } from 'Public/Shared/DigitalLine/DigitalLine';
import lineGold from 'Assets/Images/NCI Templates/JOIP-line_gold3.png';
//import classes from './HeaderImageBanner.module.scss'

if (dir.isRtl()) {
    require('./HeaderImageBanner.rtl.scss');
} else {
    require('./HeaderImageBanner.scss');
}

const HeaderImageBanner: React.FC<{ screenName: string, title?: string }> = ({ screenName = "", title = '' }): React.ReactElement => {

    const [banner, setBanner] = React.useState<IBanner>({
        title: "",
        titleAr: "",
        imageUrl: constants.urls.headerImagePlaceholder,
        screenName: ""
    })
    const _publicContentService = new PublicContentService();
    const fetchScreenHeader = async () => { return await _publicContentService.getScreenName(screenName); }

    const { data } = useQuery(["PublicPages/GetScreenImageHeader?screenName=", screenName], fetchScreenHeader, {
        cacheTime: Settings.publicPageCacheTime
    });

    React.useEffect(() => {
        if (data) {
            setBanner({
                title: title ? title : data.title,
                titleAr: title ? title : data.titleAr,
                imageUrl: data.imageUrl ? data.imageUrl : constants.urls.headerImagePlaceholder,
                screenName: data.screenName
            });
        }
    }, [data]);

    React.useEffect(() => {
        if (title) {
            setBanner({
                ...banner,
                title: title,
                titleAr: title,

            });
        }
    }, [title]);

    const DigitalLines: JSX.Element = (
        <>
            <DigitalLine DirAware={true} size="small" float="right" space="130px" color="bg-light" height={145} opacity='0.5' />
            <DigitalLine DirAware={true} size="small" float="right" space="189px" color="bg-secondary" height={197} opacity='0.5' />
            <DigitalLine DirAware={true} size="small" float="right" space="224px" color="bg-light" height={109} opacity='0.5' />
        </>
    )

    return (
        <header className="container-fluid p-0 overflow-hidden">
            <div className="row">
                <div className="col-md-12">
                    <div className="BannerContainer">
                        <div className={`position-relative Banner`}>
                            {DigitalLines}
                            <img width="100%" height="300" loading="lazy" className="img-fluid BannerImage" src={banner.imageUrl} />
                        </div>
                        <h1 className="text-white BannerName">{Localizer(banner.title, banner.titleAr)}</h1>
                        <img width="100%" height="100" loading="lazy" className="img-fluid LineGold" src={lineGold} style={{ transform: dir.getDir() === dir.Dir.RTL ? "scaleX(-1)" : undefined }} />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default HeaderImageBanner;
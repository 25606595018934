import React from 'react';
import { useParams } from 'react-router';
import { Report } from 'powerbi-client';
import { useQuery } from 'react-query';

import EmbedReport from 'Components/Shared/PowerBI/EmbedReport/EmbedReport';
import OrgDashboardsService from 'Services/OrgDashboardsService';
import DashboardsService from 'Services/DashboardsService';
import MyOrgReportsContainer from './MyOrgReportsContainer';
import { getOrganizationId } from 'Utils/Utilites';
import { Settings } from 'Utils/Settings';

const MyOrgNetworksReport = () => {
  const [report, setReport] = React.useState<Report>();
  const { join } = useParams<any>();
  const orgId = getOrganizationId(join);
  const reportId = Settings.dashboardsOrgMyWorksReport;
  const _OrgDashboardsService = new OrgDashboardsService();
  const _DashboardsService = new DashboardsService();

  const getReport = async (report: Report) => {
    setReport(report);
  }

  const fetcReportPages = async () => { return await _DashboardsService.getReportPages("OrgNetworksReport"); }
  const { data } = useQuery(["Reports/GetReportPagesByGroupName?groupName=", "OrgNetworksReport"], fetcReportPages, {
    cacheTime: Settings.cacheTimeProfile
  });

  return (
    <MyOrgReportsContainer>
      <EmbedReport reportId={reportId} getEmbedReport={getReport} pages={data || []} defaultPageId={data ? data[0].powerBiPageId : ''}
        getReportApiEndPoint={_OrgDashboardsService.getMyOrgEmbedReport.bind(_OrgDashboardsService, Number(orgId), reportId)}
        settings={{
          settings: {
            panes: {
              bookmarks: {
                visible: false
              },
              filters: {
                expanded: false,
                visible: false
              },
              syncSlicers: {
                visible: false
              },
              pageNavigation: {
                visible: false
              }
            },
          }
        }} />
    </MyOrgReportsContainer>
  )
}

export default  React.memo(MyOrgNetworksReport);
import React from 'react';

import classes from './DigitalLine.module.scss';
import Dir from 'Utils/direction';

interface Props {
    size: "small" | "large",
    height: number,
    color?: string,
    opacity?: string,
    float?: "left" | "right",
    space?: string,
    DirAware?: boolean
}

enum SIZES {
    SMALL = 'small',
    LARGE = 'large'
}

const getStyle = (size: string, color: string | undefined, opacity: string | undefined, float : string | undefined, space : string | undefined, DirAware : boolean | undefined) : any => {
    return {
        sizeClass: size === SIZES.SMALL ? classes.containerSmall : classes.containerLarge,
        color: color,
        opacity: opacity,
        styles : {
            floatStyle: float ? float === 'left' ? {left:'1px'} : {right:'1px'} : {},
            space: float ? float === 'left' ? {left: space} : {right: space} : {left:space}
        },
        DirAware: DirAware ? DirAware : false   
    }
}

export const DigitalLine: React.FC<Props> = ({size, color, opacity , height, float, space, DirAware}) => {
    const style = getStyle(size, color, opacity, float, space, DirAware)
    if (DirAware) if( Dir.isRtl()) {style.styles.space={left: space}; style.styles.floatStyle=''}

    return (
        <div className={`${style.sizeClass} d-xl-block d-lg-block d-md-block d-sm-block d-none`} style={Object.assign(style.styles.floatStyle, style.styles.space)}>
            <div className={` ${classes.circle} ${style.color} `} style={{ opacity: style.opacity}}/>
            <div className={` ${classes.column} ${style.color} `} style={{ height: height, opacity: style.opacity}} />
        </div>
    )
}

import { BaseService } from "./Core/BaseService";
import { IConfiguration, IAnnouncement } from "Models/CommonModels";

export default class CommonService extends BaseService {

    
    public async getConfigurations(): Promise<Array<IConfiguration>> {
        const url = 'AppConfiguration/GetConfigurations'
        return this.Api.get(url)
            .then((response) => {
                return response.data;
            }).catch((error) => {
                throw error;
            })
    }

    public async getAnnouncement(): Promise<IAnnouncement> {
        const url = "Common/GetAnnouncement";
        return this.ProtectedApi.get(url)
            .then(response => {
                return response.data;
            }).catch(error => {
                throw error;
            })
    };
}
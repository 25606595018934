import * as React from 'react';
import * as Store from 'store2';

const loadStyleFiles = (dir?: Dir) => {
    const _dir: Dir = dir ? dir : getDir();
    const links = document.querySelectorAll('link[rel="stylesheet"]') as any;
    
    if (_dir == Dir.RTL) {
        if (links.length) {
            links.forEach((styleSheet: any) => {
                if (styleSheet.href.endsWith('.chunk.css')) {
                    const link = document.createElement('link');
                    link.href = styleSheet.href.replace(/\.css$/, '.rtl.css');
                    link.rel = 'stylesheet';
                    document.head.appendChild(link);
                    document.head.removeChild(styleSheet);
                }
            });
        }
    }

    //else {
    //    links.forEach((style: any) => {
    //        if (style.href.endsWith('.chunk.rtl.css')) {
    //            // const link = document.createElement('link');
    //            // link.href = style.href.replace(/\.rtl.css$/, '.css');
    //            // link.rel = 'stylesheet';
    //            // document.head.appendChild(link);
    //            document.head.removeChild(style);
    //        }
    //    });
    //}
    document.body.setAttribute('dir', _dir);
}

enum Dir {
    LTR = "ltr",
    RTL = "rtl"
}

const getDir = (): Dir => {
    const currentDir = Store.get("dir");

    if (!currentDir)
        setDefaultDir();

    return Store.get("dir") as Dir;
}

const isRtl = (): boolean => {
    return getDir() === Dir.RTL
}

const setDefaultDir = (dir?: Dir) => {
    if (dir)
        Store.set("dir", dir);
    else
        Store.set("dir", Dir.LTR);
}

const dir = {
    getDir,
    setDefaultDir,
    Dir,
    isRtl,
    loadStyleFiles
}

export default dir;
import React from 'react';
import { toast, ToastProps } from 'react-toastify';

import { Icon } from 'Utils/Icon';
import Style from './Toast.module.scss';

const titleCase = (text: string): string => {
    return text[0].toUpperCase() + text.slice(1);
}

const SuccessMsg = ({ toastProps, msg }: { closeToast: any, toastProps: ToastProps, msg: string }): JSX.Element => {
    const { isIn, toastId, key, deleteToast, transition, draggablePercent, closeToast, hideProgressBar, autoClose, progressStyle, progressClassName,
        closeOnClick, draggableDirection, pauseOnFocusLoss, pauseOnHover, bodyStyle, bodyClassName, rtl = "false", closeButton, updateId, ...rest } = toastProps;
    return (
        <div {...rest} className="fs-small">
            <Icon iconname='CheckCircle' className={`${Style.Toast_sucess} ms-1 me-2`} /> {titleCase(msg)}
        </div>
    )
}

const WarningMsg = ({ toastProps, msg }: { closeToast: any, toastProps: ToastProps, msg: string }): JSX.Element => {
    const { isIn, toastId, key, deleteToast, transition, draggablePercent, closeToast, hideProgressBar, autoClose, progressStyle, progressClassName,
        closeOnClick, draggableDirection, pauseOnFocusLoss, pauseOnHover, bodyStyle, bodyClassName, rtl = "false", closeButton, updateId, ...rest } = toastProps;
    return (
        <div {...rest} className="fs-small">
            <Icon iconname='Warning' className={`${Style.Toast_warning} ms-1 me-2`} /> {titleCase(msg)}
        </div>
    )
}

const ErrorMsg = ({ toastProps, msg }: { closeToast: any, toastProps: ToastProps, msg: string }): JSX.Element => {
    const { isIn, toastId, key, deleteToast, transition, draggablePercent, closeToast, hideProgressBar, autoClose, progressStyle, progressClassName,
        closeOnClick, draggableDirection, pauseOnFocusLoss, pauseOnHover, bodyStyle, bodyClassName, rtl = "false", closeButton, updateId, ...rest } = toastProps;
    return (
        <div {...rest} className="fs-small">
            <Icon iconname='Error' className={`${Style.Toast_error} ms-1 me-2`} /> {titleCase(msg)}
        </div>
    )
}

export const setError = (msg: string) => {
    toast.error(({ closeToast, toastProps }) => <ErrorMsg closeToast={closeToast} toastProps={toastProps} msg={msg} />, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false
    })
};

export const setWarning = (msg: string) => {
    toast.error(({ closeToast, toastProps }) => <WarningMsg closeToast={closeToast} toastProps={toastProps} msg={msg} />, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false
    })
};

export const setSuccess = (msg: string) => toast.success(({ closeToast, toastProps }) => <SuccessMsg closeToast={closeToast} toastProps={toastProps} msg={msg} />, {
    position: "top-center",
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    hideProgressBar: true,
    draggable: true,
    closeButton: false
});

import * as React from 'react';

import HeaderImageBanner from 'Shared/HeaderImageBanner/HeaderImageBanner'
import { DigitalLine } from 'Public/Shared/DigitalLine/DigitalLine'

export default (props: any) => {

    return (
        <>
            <div>
                <HeaderImageBanner screenName={props.screenName} title={props.title} />
            </div>
            <div className="container position-relative" style={{ minHeight: '100vh' }}>
                <div style={{ position: 'absolute', left: -50, top: 620 }}>
                    <DigitalLine size="small" color="bg-secondary" opacity="0.5" height={200} />
                </div>
                <div style={{ position: 'absolute', left: -10, top: 500 }}>
                    <DigitalLine size="large" color="bg-secondary" opacity="0.8" height={370} />
                </div>
                {props.children}
                <div style={{ position: 'absolute', right: 10, top: 390 }}>
                    <DigitalLine size="large" color="bg-secondary" opacity="0.8" height={370} />
                </div>
            </div>
        </>
    );
};

export interface INetworkNotificationItem {
    id: number,
    userProfileId: number,
    networkNotificationType: number,
    createDateTime: string,
    read: boolean,
    networkUserProfileId: number,
    networkOrgProfileId?: number,
    networkUserProfile: {
        jrin: string,
        firstName: string,
        familyName: string,
        imageName: string
    }
}

export interface IBadges {
    networkNotificationCount: number,
    otherNotificationCount: number,
    chat: number
}

export interface INetworkNotificationStackItem {
    id: string,
    title: string,
    initiatorFullName: string,
    URL: string,
    initiatorPicture?: string,
    targetProfileId: number,
    date: string,
    message: string
}

export interface IReceivedNetworkNotification {
    Id: string,
    NetworkNotificationType: NETWORK_NOTIFICATION_TYPE,
    InitiatorFullName: string,
    JRIN: string,
    InitiatorPicture?: string,
    TargetProfileId: number,
    Date: string
}

export interface IReceivedGeneralNotification {
    Id: string,
    InitiatorId: number,
    InitiatorFullName: string,
    InitiatorPicture?: string,
    TargetProfileId: number,
    Date: string,
    Data: string,
    Type: GENERAL_NOTIFICATION_TYPE,
    Source: GENERAL_NOTIFICATION_SOURCE
}

export interface INetworkNotificationSliceState {
    networkNotificationCount: number,
    otherNotificationCount: number,
    messageNotificationCount: number,
    notificationsStack: Array<INetworkNotificationStackItem>
}

export interface IBaseGeneralNotification {
    id: number,
    generalNotificationType: number,
    generalNotificationSource: number,
    createDateTime: string,
    sourceId: number,
    data: string,
    notificationImage: string,
    name: string,
    read: boolean
}

export interface IGeneralNotification extends IBaseGeneralNotification {
    userProfileId: number
}

export interface IOrgGeneralNotification extends IBaseGeneralNotification {
    userProfileId: number
}

export enum NETWORK_NOTIFICATION_TYPE {
    ConnectionRequest = 1,
    AcceptedConnection = 2,
    NewFollower = 3
}

export enum GENERAL_NOTIFICATION_TYPE {
    CommentOnMyPost = 1,
    LikeOnMyPost = 2,
    ProfileIdentityVerification = 3,
    ReferralRequest = 4,
    ReferralReceived = 5,
    WorkFileShareRequest = 6,
    WorkFileShareReceived = 7,
    ProfileMentioned = 8,
    ContentVerification = 9,
    SendMessage = 10,
    WorkContributor = 11,
    OrgWorkContributor = 12,
    AddOrgProfileAdmin = 13,
    DeleteOrgProfileAdmin = 14,
    AddChairman = 15,
    DeleteChairman = 16,
    AddReviewer = 17,
    DeleteReviewer = 18,
    ReviewerAcceptAction = 19,
    ReviewerRejectAction = 20,
    NewSubmission = 21,
    AcceptSubmission = 22,
    RejectSubmission = 23,
    ProfileSuspension = 24,
    MissingWorkFileRequest = 25,
    SharePost = 26
}

export enum ORG_GENERAL_NOTIFICATION_TYPE {
    Follow = 1,
    PostComment = 2,
    PostLike = 3,
    WorkFileShareRequest = 4,
    OrgProfileIdentityVerification = 5,
    ContentVerificationRequest = 6,
    MissingWorkFileRequest = 8,
    OrgProfileMentioned = 9,
    SharePost = 10
}

export enum GENERAL_NOTIFICATION_SOURCE {
    UserProfile = 1,
    OrganizationProfile = 2,
    System = 3
}
import { setError } from 'Shared/UI/Toast/Toast';
import { NetworkError, ServerValidationException, GeneralSreverError, GeneralServerContentNotFoundMsg, AccessDenied } from './Resources';
import i18next from 'i18next';

export const throwException = (
    error: any,
    overwrite?: { errorCode: 400 | 409 | 404 | 403, msgKey: string }
) => {
    if (error?.response) {
        if (error.response?.status === 400) {
            if (overwrite && overwrite.errorCode === 400)
                setError(GetLocalizedMessage(overwrite.msgKey));
            else
                setError(GetLocalizedMessage(ServerValidationException));
        }
        else if (error.response?.status === 409) {
            if (overwrite && overwrite.errorCode === 409)
                setError(GetLocalizedMessage(overwrite.msgKey));
            else
                setError(GetLocalizedMessage("Server.Msg." + error.response.data.type));
        }
        else if (error.response?.status === 403) {
            if (overwrite && overwrite.errorCode === 403)
                setError(GetLocalizedMessage(AccessDenied));
            else
                setError(GetLocalizedMessage("Server.Msg." + error.response.data.type));
        } else if (error.response?.status === 404) {
            if (overwrite && overwrite.errorCode === 404)
                setError(GetLocalizedMessage(overwrite.msgKey));
            else
                setError(GetLocalizedMessage(GeneralServerContentNotFoundMsg));
        }
        else {
            setError(GetLocalizedMessage(GeneralSreverError));
        }
    }
    else {
        setError(GetLocalizedMessage(NetworkError));
    }
}

export const GetLocalizedMessage = (key:string) : string => {
    return i18next.t(key.toLowerCase());
}
import NoProfileAvailable from "Assets/Images/Profile/NoProfileAvailable.png";
import ProfilePlaceHolder from "Assets/Images/Profile/02-User.png";
import OrgProfilePlaceHolder from "Assets/Images/Profile/02-Org.png";
import WorkIcon from "Assets/Images/Profile/02-Work.png";
import CoverImgPlaceholder from 'Assets/Images/NCI Templates/Header-Image-Template.jpg';

const constants = {
  urls: {
    headerImagePlaceholder:
      "https://joipdevstorage.blob.core.windows.net/pub/assets/Header-Image-Template.jpg",
    CardImagePlaceholder: "https://via.placeholder.com/150",
    profilePlaceHolder: ProfilePlaceHolder,
    NoProfilePlaceHoler:NoProfileAvailable
      /*"https://static-exp1.licdn.com/sc/h/5t1znlongxxnu07iswxsaot9j"*/,
    OrgProfilePlaceHolder: OrgProfilePlaceHolder,
    WorkIcon: WorkIcon,
    CoverImgPlaceholder: CoverImgPlaceholder
  },
};

export default constants;
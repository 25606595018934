import { BaseService } from "./Core/BaseService";
import { IEmbedReportService } from "../Models/DashboardModels";

export default class OrgDashboardsService extends BaseService {
    
    public async getMyOrgEmbedReport(orgId: number, reportId: string): Promise<IEmbedReportService> {
        const url = `Reports/org/${orgId}/GetOrgEmbeddingReportInfo`
        return this.ProtectedApi.get(url, { params: { reportId: reportId } })
            .then(response => {
                return response.data;
            }).catch(error => {
                this.throwException(error);
                throw new Error(error);
            })
    }
}
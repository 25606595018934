import * as React from 'react';

import logo from 'Assets/Images/Identity/JOIP_logomark_color.png';
import './Splash.scss';
import { DigitalLine } from '../Shared/DigitalLine/DigitalLine';


interface Props {

}

const Footer = () => {

    return (
        <div className="contianer" style={{ height: "100vh", backgroundColor: "#f7f7f7" }}>
            <div>
                <div>
                    <DigitalLine float="left" space="30px" size="large" height={400} color="bg-dark" opacity="0.3" />
                </div>
                <div>
                    <DigitalLine float="left" space="70px" size="large" height={250} color="bg-dark" opacity="0.3" />
                </div>
            </div>


            <img loading="lazy" src={logo} alt="Joip logo" width="190" className="align-middle" style={{ position: "absolute", left: "calc(50% - 90px)", top: "33%" }} />
            <div>
                <div className="container1">
                    <div className="circle circle1"></div>
                    <div className="circle circle2"></div>
                    <div className="circle circle3"></div>
                    <div className="circle circle4"></div>
                    <div className="circle circle5"></div>
                </div>
            </div>

            <div style={{ position: 'absolute', bottom: 0, left: "auto", right: "60px" }}>
                <DigitalLine size="small" color="bg-secondary" height={150} />
            </div>
        </div>
    )
}

export default Footer

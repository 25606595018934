import { AxiosInstance } from 'axios';
import Api from 'Apis/Apis';
import ProtectedApi from 'Apis/ProtectedApis';
import { throwException } from 'Utils/ThrowException';

export abstract class BaseService {
    public ProtectedApi: AxiosInstance;
    public Api: AxiosInstance;
    public throwException: (error: any, overwrite?: {
        errorCode: 400 | 409 | 404 | 403;
        msgKey: string;
    } | undefined) => void;

    constructor() {
        this.ProtectedApi = ProtectedApi;
        this.Api = Api;
        this.throwException = throwException;
    }

}
import { UserVisit } from "Models/UserVisitModels";
import { BaseService } from "./Core/BaseService";


export default class PortalVisitService extends BaseService {

    public async AddPortalVisit(userVisit:UserVisit): Promise<void>{
        const data = {...userVisit};
        return await this.Api.post(`PortalVisit/AddPortalVisit`, data)
        .then(res => res.data)
        .catch(error => {
            this.throwException(error)
            return error
        })
    }

}
import { BaseService } from "./Core/BaseService";
import moment from "moment";
import { IBaseOrgProfile, IOrgAbout, IOrgContactContent, IOrgAlternativeName, IOrgOverViewInfo, IOrgAchievements, IOrgIdentityVerification, IOrgIdentityVerificaionBasicInfo, IRingGoldDataSearch, IRingGoldInfo, RingGoldSearchItemsModel } from 'Models/ProfileModels';
import { IOrgProfileIDs, IOrgSearchProfileData, IOrgProfileAdmins } from "Models/OrganizationModels";
import { ISocialLink, ISocialLinks } from "Models/SocialLinkModels";
import { NETWORK_TYPE } from "Models/CommonModels";

export default class OrgProfileService extends BaseService {

    public async getOrgProfileContact(orgId: number): Promise<IOrgContactContent> {
        const url = `Profile/Org/${orgId}/GetOrgUserProfileContact`;
        return this.ProtectedApi.get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            })
    }

    public async addBasicOrgProfile(orgProfile: IBaseOrgProfile): Promise<string> {
        const url = `Profile/Org/CreateOrgProfile`;
        return this.ProtectedApi.post(url, orgProfile)
            .then(response => {
                return response.data;
            }).catch(error => {
                this.throwException(error);
                throw error;
            });
    }

    public async deleteOrg(orgId: number): Promise<void> {
        const url = `Profile/Org/${orgId}/DeleteOrg`;
        return this.ProtectedApi.delete(url)
            .then((response) => response.data)
            .catch((error) => {
                this.throwException(error);
                throw error;
            })
    }


    public async getProfileCardInfo(join: string): Promise<any> {
        const url = `Profile/GetOrgProfileCardInfo`
        return this.ProtectedApi.get(url, { params: { join: join } })
            .then(response => {
                return response.data
            }).catch(error => {
                throw error
            })
    }

    public async getOrgProfileOverViewInfo(orgId: number): Promise<IOrgOverViewInfo> {
        const url = `Profile/Org/${orgId}/GetOrgOverviewInfo`
        return this.ProtectedApi.get(url)
            .then(response => {
                return response.data
            }).catch(error => {
                throw error;
            })
    }


    public async getOrgAboutContent(JOIN: string): Promise<IOrgAbout> {
        const url = `Profile/GetOrgProfileAboutTabInfo?Join=${JOIN}`;
        return this.ProtectedApi.get(url).then((response) => {
            const data: ISocialLink[] = response.data.orgSocialLinks;
            const reult: ISocialLinks = {
                personalLinks: data.filter((d: any) => d.type === NETWORK_TYPE.WEBSITE),
                socialLinks: data.filter((d: any) => d.type !== NETWORK_TYPE.WEBSITE)
            }
            response.data.orgSocialLinks= reult;
            return response.data;
        })
            .catch((error) => {
                throw error;
            })
    }

    public async getOrgsByAdminId(): Promise<Array<any>> {
        const url = 'Profile/GetListOrgs'
        return this.ProtectedApi.get(url)
            .then(response => {
                return response.data
            }).catch(error => {
                throw error;
            });
    }

    public async searchVerifiedOrgs(term: string): Promise<Array<IOrgSearchProfileData>> {
        const url = `Profile/SearchVerifiedOrgProfiles`
        return this.ProtectedApi.get(url, { params: { profileName: term } })
            .then(response => {
                return response.data
            }).catch(error => {
                throw error;
            });
    }

    public async uploadProfilePic(orgId: number, profileImage: string): Promise<string> {
        const data = {
            profileImage: profileImage.replace('data:image/png;base64,', '')
        }
        const url = `profile/org/${orgId}/UploadOrgProfilePic`
        return this.ProtectedApi.post(url, data)
            .then((response) => response.data)
            .catch((error) => {
                this.throwException(error)
                throw new Error(error)
            })
    }

    public async uploadProfileCoverPic(orgId: number, profileImage: string): Promise<string> {
        const data = {
            profileImage: profileImage.replace('data:image/png;base64,', '')
        }
        const url = `profile/org/${orgId}/UploadOrgProfileCoverPic`
        return this.ProtectedApi.post(url, data)
            .then((response) => response.data)
            .catch((error) => {
                this.throwException(error)
                throw new Error(error)
            })
    }

    public async deleteProfileCoverPic(orgId: number, imageName: string): Promise<boolean> {
        const url = `profile/org/${orgId}/DeleteOrgProfileCoverPic`

        return this.ProtectedApi.delete(url, { params: { profileImageName: imageName } })
            .then(response => {
                return true
            }).catch(error => {
                this.throwException(error)
                return false
            })
    }



    public async updateProfileName(orgId: number, firstName: string): Promise<boolean> {
        const data = {
            name: firstName
        }
        const url = `Profile/org/${orgId}/UpdateOrgName`
        return this.ProtectedApi.put(url, data)
            .then(response => {
                return true
            })
            .catch(error => {
                this.throwException(error)
                return false
            })
    }

    public async updateProfileAbout(orgId: number, about: string): Promise<boolean> {
        const data = {
            about: about,
        }
        const url = `Profile/org/${orgId}/UpdateOrgAbout`
        return this.ProtectedApi.put(url, data)
            .then(response => {
                return true
            }).catch(error => {
                this.throwException(error)
                return false
            })
    }

    public async updateProfileOrgType(orgId: number, type: number): Promise<boolean> {
        const data = {
            typeId: type,
        }
        const url = `Profile/org/${orgId}/UpdateOrgType`
        return this.ProtectedApi.put(url, data)
            .then(response => {
                return true
            }).catch(error => {
                this.throwException(error)
                return false
            })
    }

    public async updateProfileFoundationDate(orgId: number, fdate: string): Promise<boolean> {
        const data = {
            foundationDate: fdate ? moment(fdate).format('yyyy-MM-DD') : null,
        }
        const url = `Profile/org/${orgId}/UpdateOrgFoundationdate`
        return this.ProtectedApi.put(url, data)
            .then(response => {
                return true
            }).catch(error => {
                this.throwException(error)
                return false
            })
    }

    public async addAlternativeName(orgId: number, alterName: IOrgAlternativeName): Promise<IOrgAlternativeName> {
        const data = {
            name: alterName.name,
        }
        const url = `AlternativeNames/org/${orgId}/Create`
        return this.ProtectedApi.post(url, data)
            .then(response => {
                const entity: IOrgAlternativeName = {
                    name: alterName.name,
                    id: response.data
                }
                return entity
            }).catch(error => {
                this.throwException(error)
                throw new Error(error)
            })
    }

    public async updateAlternativeName(orgId: number, alterName: IOrgAlternativeName): Promise<boolean> {
        const data = {
            name: alterName.name,
            id: alterName.id
        }
        const url = `AlternativeNames/org/${orgId}/Update`
        return this.ProtectedApi.put(url, data)
            .then(response => {
                return true
            }).catch(error => {
                this.throwException(error)
                throw new Error(error)
            })
    }

    public async deleteAlternativeName(orgId: number, id: number): Promise<boolean> {
        const url = `AlternativeNames/org/${orgId}/Delete`
        return this.ProtectedApi.delete(url, { params: { itemId: id } })
            .then(response => {
                return true
            }).catch(error => {
                this.throwException(error)
                throw new Error(error)
            })
    }

    public async getOrgIDs(orgId: number): Promise<IOrgProfileIDs[]> {
        return this.ProtectedApi.get(`ProfileIdentifiers/Org/${orgId}/ListOrgIDs`).then((response) => {
            return response.data;
        })
            .catch((error) => {
                throw error;
            })
    }

    public async deleteOrgIDs(id: number, OrgId: number): Promise<boolean> {
        const params = {
            recId: id,
        };
        return this.ProtectedApi.delete(`ProfileIdentifiers/Org/${OrgId}/DeleteOrgProfileIdentifier`, {
            params: params,
        })
            .then(() => true)
            .catch(error => {
                this.throwException(error);
                throw error;
            });
    }

    public async addOrgIDs(obj: IOrgProfileIDs, OrgId: number): Promise<number> {
        return this.ProtectedApi.post(`ProfileIdentifiers/Org/${OrgId}/CreateOrgProfileIdentifier`, obj)
            .then((response) => { return response.data })
            .catch(error => {
                this.throwException(error);
                throw error;
            });
    }

    public async updateOrgIDs(obj: IOrgProfileIDs, OrgId: number): Promise<boolean> {
        return this.ProtectedApi.put(`ProfileIdentifiers/Org/${OrgId}/UpdateOrgProfileIdentifier`, obj)
            .then(() => true)
            .catch(error => {
                this.throwException(error);
                throw error;
            });
    }

    public async getOrgAchievements(orgId: number): Promise<IOrgAchievements> {
        return this.ProtectedApi.get(`Profile/Org/${orgId}/GetOrgAchievements`)
            .then((response) => response.data)
            .catch((error) => {
                throw error
            })
    }

    public async getOrgIdentityVerificaionRequests(orgId: number): Promise<IOrgIdentityVerification[]> {
        return this.ProtectedApi.get(`Profile/Org/${orgId}/GetOrgIdentityVerificationRequests`)
            .then((response) => response.data)
            .catch((error) => {
                throw error
            })
    }

    public async getOrgIdentityVerificaionBasicInfo(join: string): Promise<IOrgIdentityVerificaionBasicInfo> {
        return this.ProtectedApi.get(`Profile/GetOrgIdentityVerificaionBasicInfo`, {
            params: { join: join }
        })
            .then((response) => response.data)
            .catch((error) => {
                throw error
            })
    }

    public async addOrgIdentityVerificationRequest(orgId: number, data: IOrgIdentityVerification): Promise<IOrgIdentityVerification> {
        return this.ProtectedApi.post(`Profile/Org/${orgId}/AddOrgIdentityVerificationRequest`, data)
            .then((response) => {
                const res = response.data
                return { ...data, id: res.id, documents: res.documents }
            })
            .catch((error) => {
                throw error
            })
    }

    public async getRingGoldOrg(RingGoldDataSearch: IRingGoldDataSearch): Promise<RingGoldSearchItemsModel> {
        const data = {
            searchTerm: RingGoldDataSearch.searchTerm,
            pageIndex: RingGoldDataSearch.pageIndex,
            country: RingGoldDataSearch.country,
            city: RingGoldDataSearch.city,
            postCode: RingGoldDataSearch.postCode
        }
        return this.ProtectedApi.get(`Organizations/SearchRinggoldOrg`, { params: data })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                throw error;
            });
    };

    public async getRinggoldOrgById(ringGold: string): Promise<IRingGoldInfo> {
        const data = {
            ringgoldId: ringGold,
        }
        return this.ProtectedApi.get(`Organizations/SearchRinggoldOrgById`, { params: data })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                throw error;
            });
    };

    public async getOrgProfileAdmins(orgId: number): Promise<IOrgProfileAdmins[]> {
        const url = `Profile/Org/${orgId}/GetOrgProfileAdmins`;
        return this.ProtectedApi.get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            })
    }
    public async addOrgProfileAdmin(orgId: number, userProfileId: number, join: string): Promise<IOrgProfileAdmins> {
        const data = {
            profileId: userProfileId,
            join: join
        }
        return this.ProtectedApi.post(`Profile/Org/${orgId}/AddOrgProfileAdmin`, data)
            .then((response) => {
                return response.data

            })
            .catch((error) => {
                this.throwException(error);
                throw error
            })
    }
    public async deleteOrgProfileAdmin(orgId: number, id: number, profileId: number, join: string): Promise<void> {
        const url = `Profile/Org/${orgId}/DeleteOrgProfileAdmin`;
        const data = {
            recId: id,
            orgId: orgId,
            profileId: profileId,
            join: join
        }
        return this.ProtectedApi.delete(url, { params: data })
            .then((response) => response.data)
            .catch((error) => {
                throw error;
            })
    }
}
import rootReducer from './reducers';
import { PostState } from './Features/PostSlice';
import { BookmarkState } from './Features/BookmarkSlice';
import { OrgState } from './Features/OrgsSlice';

import { ThunkAction, Action } from '@reduxjs/toolkit';
import { INetworkNotificationSliceState } from 'Models/Notifications';
import {UserModel} from 'Models/UserModels';
import { IConfirmDelete, IViewWork } from 'Models/CommonModels';
import { IChatModal } from 'Models/ChatsModel';

// The top-level state object
export interface ApplicationState {
    post: PostState | undefined;
    user: UserModel | undefined;
    org: OrgState | undefined;
    bookmark: BookmarkState | undefined;
    notification?: INetworkNotificationSliceState;
    confirmDelete: IConfirmDelete | undefined;
    viewWork: IViewWork | undefined;
    chat: IChatModal | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    ...rootReducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    ApplicationState,
    unknown,
    Action<string>
>;
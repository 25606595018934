import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationState, AppThunk } from "../index";
import { IBookmarkItem, IBookmarkPagination } from "Models/BookmarkModels";
import BookmarkService from 'Services/BookmarkService';
import _ from 'lodash'

export interface BookmarkState {
  bookmarks: Array<IBookmarkItem>;
  currBookamrk: IBookmarkItem | null;
  isBusy: boolean;
  isError: boolean;
}

const initialState: BookmarkState = {
  bookmarks: [],
  currBookamrk: null,
  isBusy: false,
  isError: false
};

const _bookmarkService = new BookmarkService()

const bookmarkSlice = createSlice({
  name: "bookmark",
  initialState: initialState,
  reducers: {
    fetchBookmarks: (state, action: PayloadAction<Array<IBookmarkItem>>) => {
      state.bookmarks = action.payload;
    },
    addBookmark: (state, action: PayloadAction<IBookmarkItem>) => {
      state.bookmarks.push(action.payload);
      state.bookmarks =_.orderBy(state.bookmarks,  ['pinnedToTop', 'bookmarkDate'], ['desc', 'desc']);
      state.currBookamrk = action.payload
    },
    deleteBookmark: (state, action: PayloadAction<number | null>) => {
      state.currBookamrk = state.bookmarks.find(item=> item.id === action.payload) || null
      state.bookmarks = state.bookmarks.filter(c => c.id !== action.payload);
      if(state.currBookamrk !== null ){ state.currBookamrk.id = 0 }
    },
    setBusy: (state, action: PayloadAction<boolean>) => {
      state.isBusy = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload;
    }
  },
});

const { actions, reducer } = bookmarkSlice

export const { fetchBookmarks, addBookmark, deleteBookmark, setBusy, setError } = actions;

export const listBookmarksAsync = (params: IBookmarkPagination): AppThunk => (dispatch, getstate) => {
  dispatch(setBusy(true));
  _bookmarkService.listBookmarks(params)
  .then(response => {
      const bookmarks = response.items;
      dispatch(fetchBookmarks(bookmarks));
      dispatch(setError(false))
  })
  .catch(() => dispatch(setError(true)))
  .finally(() => dispatch(setBusy(false)));
}

export const selectBookmarks = (state: ApplicationState) => state.bookmark?.bookmarks;
export const selectBookmark = (state: ApplicationState) => state.bookmark?.currBookamrk;
export const selectIsBusy = (state: ApplicationState) => state.bookmark?.isBusy;
export const selectIsError = (state: ApplicationState) => state.bookmark?.isError;

export default reducer;
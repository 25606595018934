/**
 * This is the doc comment for Resources.ts
 * @description Resources.ts file includes json object with
 * (key, value) of all shared resources used in the system
 */

// buttons and navigation items
export const ButtonSave = "Button.Save.Label";
export const ButtonCancel = "Button.Cancel.Label";
export const ButtonClose = "Button.Close.Label";
export const ButtonSearch = "Button.Search.Label";
export const ButtonConfirm = "Button.Confirm.Label";
export const MenuIndividuals = "Header.Individuals.Label";
export const MenuOrganizations = "Header.Organization.Label";
export const MenuAbout = "Header.About.Label";
export const MenuHelp = "Header.Help.Label";
export const MenuForum = "Header.Forum.Label";
export const SortRecentlyAdded = "Sort.RecentlyAdded.Label";
export const SortFirstName = "Sort.FirstName.Label";
export const SortLastName = "Sort.LastName.Label";
export const SortName = "Sort.Name.Label";
export const ButtonSubmit = "Button.Submit.Label";
export const RejectButton = "Button.Reject.Label";
export const AcceptButton = "Button.Accept.Label";
// Networks Buttons
export const ButtonConnect = "Button.Connect.Label"
export const ButtonRemoveConnection = "Button.RemoveConnection.Label"
export const ButtonCancelConnectionRequest = "Button.CancelConnectionRequest.Label"
export const ButtonAcceptConnectionRequest = "Button.AcceptConnectionRequest.Label"
export const ButtonRejectConnectionRequest = "Button.RejectConnectionRequest.Label"
export const ButtonFollow = "Button.Follow.Label"
export const ButtonUnfollow = "Button.Unfollow.Label"
// Work Labels
export const WorkVersionOf = "AddWork.List.VersionOf";
export const WorkPartOf = "AddWork.List.PartOf";
export const WorkFundedBy = "AddWork.List.FundedBy";
export const WorkViaORCID = "AddWork.List.ViaORCID";
export const WorkViaJOIP = "AddWork.List.ViaJOIP";
// Works
export const ViewWorkModalTitle = "View.Work.Modal.Title";
// general exception messages
export const NetworkError = "General.NetworkConnection.Error.Message";
export const ServerValidationException = "General.Validation.Exception.Message";
export const GeneralSreverError = "General.ServerError.Exception.Message";
export const UnupdatableOrcidDataException =
  "General.UnupdatableOrcidData.Exception.Message";
export const GeneralServerContentNotFoundMsg = "Server.Msg.ContentNotFound";
export const OrcidUnupdatableTooltip = "Action.Tooltip.Orcid.Unupdatable";
export const OrcidUndeletableTooltip = "Action.Tooltip.Orcid.Undeletable";
// general messages
export const GeneralPrivacyUpdatedSuccessfully ="Msg.Content.PrivacyUpdatedSuccessfully";
export const NoResults = "General.NoResults.Message";
export const IdentityVerifiedTooltip = "Common.Identity.Verified.Tooltip"
export const ContentVerifiedTooltip = "Common.Content.Verified.Tooltip"

// Priavcy labels
export const PrivacyPublic = "Privacy.Public.Label";
export const PrivacyMynetwork = "Privacy.Mynetwork.Label";
export const PrivacyOnlyme = "Privacy.Onlyme.Label";
// Notifications
export const AcceptedYourConnectionRequest = "Notification.AcceptedYourConnectionRequest";
export const SentYouConnectionRequest = "Notification.SentYouConnectionRequest";
export const StartedFollowingYou = "Notification.StartedFollowingYou";
export const NotificationCommentOnMyPost = "Notification.CommentOnMyPost";
export const NotificationLikeOnMyPost = "Notification.LikeOnMyPost";
export const NotificationSystem = "Notification.System";
export const AskedForReferral = "Notification.AskedForReferral";
export const WroteYouReferral = "Notification.WroteYouReferral";
export const RequestedWorkFileShare = "Notification.RequestedWorkFileShare";
export const SharedWorkFiles = "Notification.SharedWorkFiles";
export const MentionProfile = "Notification.MentionProfile";
export const ContentVerificationAccepted = "Notification.Title.ContentVerificationAccepted"
export const ContentVerificationDeclined = "Notification.Title.ContentVerificationDeclined"
export const NotificationWorkContribute = "Notification.WorkContributor";
export const NotificationAddOrgProfileAdmin = "Notification.AddOrgProfileAdmin";
export const NotificationDeleteOrgProfileAdmin= "Notification.DeleteOrgProfileAdmin";

export const AccessDenied = "General.Msg.AccessDenied";
export const Source = "Common.Source";
export const DateTo = "Common.Date.To";
export const SourceViaORCID = "Common.ViaORCID";
export const SourceViaJOIP = "Common.ViaJOIP";
export const Present = "Common.Date.Present";
export const CommonBy = "Common.By"
export const CommonOf = "Common.Of"
export const NoInputs = "No.Inputs"
//Request status
export const RequestStatusPending = "Common.Request.Status.Pending";
export const RequestStatusVerified = "Common.Request.Status.Verified";
export const RequestStatusDeclined = "Common.Request.Status.Declined";
export const RequestStatusUnanswered = "Common.Request.Status.Unanswered";
export const RequestStatusAccepted = "Common.Request.Status.Accepted";
export const RequestStatusApproved = "Common.Request.Status.Approved";
//Profile Activites (Empolyment & Education)
export const ProfileActivitiesDepartmentLabel = "Profile.Activities.Department.Input.Label"
export const ProfileActivitiesDepartmentPlaceholder = "Profile.Activities.Department.Input.Placeholder"
export const ProfileActivitiesDepartmentTitle = "Profile.Activities.Department.Title"

export const ProfileActivitiesUrlLabel = "Profile.Activities.Url.Input.Label"
export const ProfileActivitiesUrlPlaceholder = "Profile.Activities.Url.Input.Placeholder"
export const ProfileActivitiesUrlTitle = "Profile.Activities.Url.Title"

export const ProfileActivitesProfessionLabel = "Profile.Activities.Profession.Input.Label"
export const ProfileActivitesProfessionDefaultValue = "Profile.Activities.Profession.Select.DefaultValue"
export const ProfileActivitesProfessionTitle = "Profile.Activities.Profession.Title"
export const ProfileActivitesProfessionSubTypeLabel = "Profile.Activities.ProfessionSubType.Input.Label"
export const ProfileActivitesProfessionSubTypeDefaultValue = "Profile.Activities.ProfessionSubType.Select.DefaultValue"
export const ProfileActivitesProfessionSubTypeTitle = "Profile.Activities.ProfessionSubType.Title"

export const OrgNameRequiredValidationMsg = "Validation.OrgName.Required.Msg"
export const CountryRequiredValidationMsg = "Validation.Country.Required.Msg"
export const CityRequiredValidationMsg = "Validation.City.Required.Msg"
export const DateRangeValidationMsg = "Validation.DateRange.InvalidEndDate.Msg"
export const UrlValidationMsg = "Validation.Url.InvalidUrl.Msg"

export const ContentVerificationTitleLabel = "ContentVerification.Request.Title.Label"
export const ContentVerificationDepartmentLabel = "ContentVerification.Request.Department.Label"
export const ContentVerificationFieldAlreadyVerfied = "ContentVerification.Field.alreadyVerified"
export const ContentVerificationFieldAlreadyHasPendingRequest = "ContentVerification.Field.alreadyPindingRequest"
export const ContentVerificationRequestSentSuccessfully = "Profile.Activites.ContentVerificationRequestSentSuccessfully"

//ISAs
export const ISAsSectionTitle = "Profile.Activities.ISAs.Section.Title"
export const ISAsEmptyStateMsg = "Empty.State.Profile.Activities.ISAs.Message"
export const noAvailableISAsButtoText = "Empty.State.Profile.Activities.ISAs.Button"
export const defaultIsaDomainSelect = "Select.Domain.Default.Value"
export const isaDeleteConfirmMessage = "Modal.IsaInterest.Delete.Confirm.Message"
export const isaDeleteConfirmTitle = "Modal.IsaInterest.Delete.Confirm.Title"

//paperSubmission labels
export const TitleName = "PaperSubmission.TitleName.Label"
export const SubmissionKeywords = "PaperSubmission.Keywords.Label"
export const SubmissionInstitution = "papersubmission.instituation.lable"
export const SubmissionMobileNumber = "papersubmission.fullmobilenumber.lable"
export const SubmissionEmail = "papersubmission.email.lable"
export const SubmissionCoAuthors = "papersubmission.coauthors.lable"
export const SubmissionCountryName = "papersubmission.countryname.lable"
export const ThematicAreas = "PaperSubmission.ThematicArea.Section.Title"
export const MarineResearchRole = "PaperSubmission.MarineResearchRole.Label"
export const ExamplesOfSuccess = "PaperSubmission.ExamplesOfSuccess.Label"
export const MarineProtectedAreas = "PaperSubmission.MarineProtectedAreas.Label"
export const Stressors = "PaperSubmission.Stressors.Label"
export const SustainableBlueEconomy = "PaperSubmission.SustainableBlueEconomy.Label"
export const Reports = "PaperSubmission.Reports.Label"
export const SupportResearch = "PaperSubmission.SupportResearch.Label"
export const StatusLabel = "Common.Request.Status.Label"
export const DownloadTooltip = "download.file.tooltip"
export const ActivityTitleLabel = "Activities.Submission.Activity.Name.Label"
export const PaperTitleLabel = "Activities.Submission.PaperTitle.Label"



const SharedResx = {
  ButtonSave,
  ButtonCancel,
  ButtonConfirm,
  MenuIndividuals,
  MenuOrganizations,
  MenuAbout,
  MenuHelp,
  NetworkError,
  ServerValidationException,
  GeneralSreverError,
  GeneralPrivacyUpdatedSuccessfully,
  UnupdatableOrcidDataException,
  GeneralServerContentNotFoundMsg,
  OrcidUnupdatableTooltip,
  OrcidUndeletableTooltip,
  SortRecentlyAdded,
  SortFirstName,
  SortLastName,
  SortName,
  NoResults,
  WorkVersionOf,
  WorkPartOf,
  WorkFundedBy,
  WorkViaORCID,
  WorkViaJOIP,
  AcceptedYourConnectionRequest,
  SentYouConnectionRequest,
  StartedFollowingYou,
  NotificationCommentOnMyPost,
  NotificationLikeOnMyPost,
  NotificationSystem,
  PrivacyPublic,
  PrivacyMynetwork,
  PrivacyOnlyme,
  AccessDenied,
  Source,
  Present,
  DateTo,
  SourceViaORCID,
  SourceViaJOIP,
  ProfileActivitiesDepartmentLabel,
  ProfileActivitiesDepartmentPlaceholder,
  ProfileActivitiesDepartmentTitle,
  ProfileActivitiesUrlLabel,
  ProfileActivitiesUrlPlaceholder,
  ProfileActivitiesUrlTitle,
  ProfileActivitesProfessionTitle,
  ProfileActivitesProfessionLabel,
  ProfileActivitesProfessionDefaultValue,
  ProfileActivitesProfessionSubTypeTitle,
  ProfileActivitesProfessionSubTypeLabel,
  ProfileActivitesProfessionSubTypeDefaultValue,
  OrgNameRequiredValidationMsg,
  CountryRequiredValidationMsg,
  CityRequiredValidationMsg,
  DateRangeValidationMsg,
  UrlValidationMsg,
  ISAsSectionTitle,
  ISAsEmptyStateMsg,
  noAvailableISAsButtoText,
  defaultIsaDomainSelect,
  isaDeleteConfirmMessage,
  isaDeleteConfirmTitle,
  IdentityVerifiedTooltip
};

export default SharedResx;

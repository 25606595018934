import { createSlice, PayloadAction } from '@reduxjs/toolkit';


import { ApplicationState } from '../index';
import { IConfirmDelete } from 'Models/CommonModels';


export const initialState: IConfirmDelete = { show: false, text: '', title: '', isLoading: false };

const confirmDeleteSlice = createSlice({
    name: 'confirmDelete',
    initialState: initialState,
    reducers: {
        showConfirm: (state, action: PayloadAction<{ text?: string, title?: string }>) => {
            state.show = true;
            state.text = action.payload.text;
            state.title = action.payload.title;
        },
        hideConfirm: (state, action: PayloadAction) => {
            state.show = false;
            state.text = "";
            state.title = "";
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        }
    }
});

export const { showConfirm, hideConfirm, setLoading } = confirmDeleteSlice.actions;

export const confirmDelete = (state: ApplicationState) => state.confirmDelete;
export default confirmDeleteSlice.reducer;

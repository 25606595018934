import { BaseService } from "./Core/BaseService";
import { BOOKMARK_TYPE, IBookmarkItem, IBookmarkPagination } from 'Models/BookmarkModels'
import { IPaginatedList } from 'Models/CommonModels'

export default class BookmarkService extends BaseService {

    public async listBookmarks(params: IBookmarkPagination): Promise<IPaginatedList<IBookmarkItem>> {
        return this.ProtectedApi.get("Bookmarks/ListBookmarks", { 
            params: params  
        })
            .then(response => {
                const data: IPaginatedList<IBookmarkItem> = {
                    items: response?.data?.items,
                    totalCount: response?.data?.totalCount,
                    hasNextPage: response?.data?.hasNextPage,
                    totalPages: response?.data?.totalPages,
                    pageIndex: response?.data?.pageIndex
                }
                return data;
            }).catch(error => {
                throw error;
            })
    }

    public async createBookmark(bookmarkItem: IBookmarkItem): Promise<IBookmarkItem> {
        let id = null;
        if(bookmarkItem.bookmarkType === BOOKMARK_TYPE.USER_PROFILE) {
            id = bookmarkItem.bookmarkedProfileId
        } else if(bookmarkItem.bookmarkType === BOOKMARK_TYPE.ORG_PROFILE) {
            id = bookmarkItem.bookmarkedOrgProfileId
        }
        if(bookmarkItem.bookmarkType === BOOKMARK_TYPE.WORK) {
            id = bookmarkItem.bookmarkedWorkId
        } else if(bookmarkItem.bookmarkType === BOOKMARK_TYPE.ORG_WORK) {
            id = bookmarkItem.bookmarkedOrgWorkId
        }
        const data = {
            bookmarkedItemId: id,
            type: bookmarkItem.bookmarkType
        }
        return this.ProtectedApi.post('Bookmarks/Create', data)
        .then(response => {
            let result: IBookmarkItem = {...bookmarkItem, id: response.data}
            return result
        })
        .catch(error => {
            this.throwException(error)
            throw error
        })
    }

    public async deleteBookmark(id: number): Promise<void> {
        return this.ProtectedApi.delete('Bookmarks/Delete', {
            params: { id: id }
        })
            .then(response => response.data)
            .catch((error) => {
                this.throwException(error);
                throw error
            })
    }

    public async setBookmarkPinnedTop(id: number, pinToTop: boolean): Promise<boolean> {
        const data = {
            id: id,
            pinToTop: pinToTop
        }
        return this.ProtectedApi.put('Bookmarks/SetBookmarkPinnedTop', data)
        .then(() => true)
        .catch(error => {
            this.throwException(error)
            throw error
        })
    }

}
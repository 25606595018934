
export interface IRoute {
  pattern: string,
  route: string,
}

export interface IRoutes {
  patterns: Array<string>,
  routes: Array<string>
}

//Public & Common Pages
export const defaultRoute: IRoute = { pattern: '/', route: '/' };
export const home: IRoute = { pattern: '/home', route: '/home' };
export const messages: IRoute = { pattern: '/messages', route: '/messages' };
export const publicSearch: IRoute = { pattern: '/pub-search/:searchTerm?', route: '/pub-search/{0}' };
export const authCallback: IRoute = { pattern: '/callback', route: '/callback' };
export const silentCallback: IRoute = { pattern: '/silent-callback', route: '/silent-callback' };
export const suspendUser: IRoute = { pattern: '/suspend-user', route: '/suspend-user' };
export const policy: IRoute = { pattern: '/policy', route: '/policy' };
export const help: IRoute = { pattern: '/help', route: '/help' };
export const about: IRoute = { pattern: '/about', route: '/about' };
export const aboutJoip: IRoute = { pattern: '/about-joip', route: '/about-joip' };
export const whoWeAre: IRoute = { pattern: '/who-we-are', route: '/who-we-are' };
export const joipServices: IRoute = { pattern: '/joip-services', route: '/joip-services' };
export const joipNews: IRoute = { pattern: '/joip-news', route: '/joip-news' };
export const joipNewsDetail: IRoute = { pattern: '/joip-news/:id', route: '/joip-news/{0}' };
export const joipEvents: IRoute = { pattern: '/joip-events', route: '/joip-events' };
export const individuals: IRoute = { pattern: '/individuals', route: '/individuals' };
export const organizations: IRoute = { pattern: '/organizations', route: '/organizations' };
export const faq: IRoute = { pattern: '/FAQ', route: '/FAQ' };
export const patnerWithJoip: IRoute = { pattern: '/partner-with-joip', route: '/partner-with-joip' };
export const accessdenied: IRoute = { pattern: '/access-denied', route: '/access-denied' };
export const userSuspended: IRoute = { pattern: '/user-suspended', route: '/user-suspended' };
export const errorRoute: IRoute = { pattern: '/error', route: '/error' };
export const notDound: IRoute = { pattern: '*', route: '*' };
export const emailVerify: IRoute = { pattern: '/email-verify/:token', route: '/email-verify' };
export const orgEmailVerify: IRoute = { pattern: '/org-email-verify/:token/:join', route: '/org-email-verify/{0}/{1}' };
export const refresh: IRoute = { pattern: '/refresh', route: '/refresh' };



//Resercher Pages
export const researcher: IRoute = { pattern: '/feed', route: '/feed' };
export const post: IRoutes = { patterns: ['/post/:postId/:commentId?'], routes: ['/post/{0}/{1}', '/post/{0}'] };
// researcherProfile.patterns[0] reads from researcherProfile.routes[0] & researcherProfile.routes[2] for scrolling down to #tab
export const researcherProfile: IRoutes = { patterns: ['/profile/:jrin?/:tab?', '/profile/:jrin?/:tab?/:jdoi?'], routes: ['/profile/{0}/{1}', '/profile/{0}/{2}/{1}', '/profile/{0}/{1}#tab'] };
export const resercherSentRequests: IRoute = { pattern: '/mynetwork/sent-requests', route: '/mynetwork/sent-requests' };
export const resercherRecievedRequests: IRoute = { pattern: '/mynetwork/recieved-requests', route: '/mynetwork/recieved-requests' };
export const resercherFollowing: IRoute = { pattern: '/mynetwork/following', route: '/mynetwork/following' };
export const resercherOrgFollowing: IRoute = { pattern: '/mynetwork/org-following', route: '/mynetwork/org-following' };
export const resercherFollowers: IRoute = { pattern: '/mynetwork/followers', route: '/mynetwork/followers' };
export const researcherEasyMatch: IRoute = { pattern: '/mynetwork/easy-match', route: '/mynetwork/easy-match' };
export const researcherContact: IRoute = { pattern: '/myprofile/contact-info/', route: '/myprofile/contact-info' };
export const researcherOverview: IRoute = { pattern: '/myprofile/overview', route: '/myprofile/overview' };
export const researcherSocialLinks: IRoute = { pattern: '/myprofile/links', route: '/myprofile/links' };
export const researcherInnovationInterests: IRoute = { pattern: '/myprofile/innovation-interests', route: '/myprofile/innovation-interests' };
export const researcherIdsGuides: IRoute = { pattern: '/myprofile/ids-guide', route: '/myprofile/ids-guide' };
export const researcherMyIds: IRoute = { pattern: '/myprofile/ids', route: '/myprofile/ids' };
export const researcherOutputAndWorksGuide: IRoute = { pattern: '/myprofile/works-guide', route: '/myprofile/works-guide' };
export const researcherAddWork: IRoute = { pattern: '/myprofile/works', route: '/myprofile/works' };
export const researcherNetwork: IRoute = { pattern: '/mynetwork/connections', route: '/mynetwork/connections' };
export const researcherDistinction: IRoute = { pattern: '/myprofile/distinctions', route: '/myprofile/distinctions' };
export const researcherEducation: IRoute = { pattern: '/myprofile/educations', route: '/myprofile/educations' };
export const researcherEmployment: IRoute = { pattern: '/myprofile/employments', route: '/myprofile/employments' };
export const researcherInvitedPosition: IRoute = { pattern: '/myprofile/invited-positions', route: '/myprofile/invited-positions' };
export const researcherMembership: IRoute = { pattern: '/myprofile/memberships', route: '/myprofile/memberships' };
export const researcherQualification: IRoute = { pattern: '/myprofile/qualifications', route: '/myprofile/qualifications' };
export const researcherService: IRoute = { pattern: '/myprofile/services', route: '/myprofile/services' };
export const researcherIdentityVerification: IRoute = { pattern: '/myprofile/identity-verification/:id?', route: '/myprofile/identity-verification/{0}' };
export const researcherVerificationGuides: IRoute = { pattern: '/myprofile/verification-guide', route: '/myprofile/verification-guide' };
export const researcherSearch: IRoute = { pattern: '/search/:searchTerm?', route: '/search/{0}' };
export const resercherSentReferralRequests: IRoute = { pattern: '/myprofile/sent-referral-requests', route: '/myprofile/sent-referral-requests' };
export const resercherReceivedReferralRequests: IRoute = { pattern: '/myprofile/received-referral-requests/:id?', route: '/myprofile/received-referral-requests/{0}' };
export const researcherReferrals: IRoute = { pattern: '/myprofile/referrals/:id?', route: '/myprofile/referrals/{0}' };
export const researcherCurriculumVitae: IRoute = { pattern: '/myprofile/curriculum-vitae', route: '/myprofile/curriculum-vitae' };
export const researcherSentWorkFileShareRequests: IRoute = { pattern: '/myprofile/sent-file-share-requests', route: '/myprofile/sent-file-share-requests' };
export const researcherReceivedWorkFileShareRequests: IRoute = { pattern: '/myprofile/received-file-share-requests/:id?', route: '/myprofile/received-file-share-requests/{0}' };
export const researcherWorkFileShare: IRoute = { pattern: '/myprofile/file-share', route: '/myprofile/file-share' };
export const researcherContentVerification: IRoute = { pattern: '/myprofile/content-verifications', route: '/myprofile/content-verifications' };
export const researcherContentVerificationRequest: IRoute = { pattern: '/myprofile/content-verification/:id/:contentType', route: '/myprofile/content-verification/{0}/{1}' };
export const researcherProfileViews: IRoute = { pattern: '/myprofile/profile-views', route: '/myprofile/profile-views' };
export const researcherContentVerificationGuides: IRoute = { pattern: '/myprofile/content-verification-guide', route: '/myprofile/content-verification-guide' };
export const researcherActivityLog: IRoute = { pattern: '/myprofile/my-activity', route: '/myprofile/my-activity' };
export const researcherRingGoldSearch: IRoute = { pattern: '/myprofile/ringgold-search/:searchTerm?', route: '/myprofile/ringgold-search/{0}' };
export const resercherWorksReport: IRoute = { pattern: '/myprofile/dashboards/myReports/worksReport', route: '/myprofile/dashboards/myReports/worksReport' };
export const resercherTop10ISAsReport: IRoute = { pattern: '/myprofile/dashboards/joipReports/top10ISAsReport', route: '/myprofile/dashboards/joipReports/top10ISAsReport' };
export const researcherReportGuides: IRoute = { pattern: '/myprofile/reports-guide', route: '/myprofile/reports-guide' };
export const chairmanConferences: IRoute = { pattern: '/myprofile/chairman-activities', route: '/myprofile/chairman-activities' };
export const chairmanConferencesSubmissions: IRoute = { pattern: '/myprofile/chairman-activities-submissions/:orgId/:conferenceId', route: '/myprofile/chairman-activities-submissions/{0}/{1}' };
export const chairmanConferenceSubmissionDetails: IRoute = { pattern: '/myprofile/chairman-activity-submission-details/:submissionId/:reviewerId?', route: '/myprofile/chairman-activity-submission-details/{0}/{1}' };
export const chairmanReviewerQuestionsManagement: IRoute = { pattern: '/myprofile/manage-reviewer-questions/:orgId/:conferenceId', route: '/myprofile/manage-reviewer-questions/{0}/{1}' };
export const chairmanConferenceQuestionsManagement: IRoute = { pattern: '/myprofile/manage-activity-questions/:orgId/:conferenceId', route: '/myprofile/manage-activity-questions/{0}/{1}' };
export const chairmanConferenceDocumentsManagement: IRoute = { pattern: '/myprofile/manage-activity-documents/:orgId/:conferenceId', route: '/myprofile/manage-activity-documents/{0}/{1}' };
export const reviewerConferencesSubmissions: IRoute = { pattern: '/myprofile/reviewer-activities-submissions', route: '/myprofile/reviewer-activities-submissions' };
export const reviewerConferenceSubmissionDetails: IRoute = { pattern: '/myprofile/reviewer-activity-submission-details/:submissionId', route: '/myprofile/reviewer-activity-submission-details/{0}' };
export const researcherConferencesSubmissions: IRoute = { pattern: '/myprofile/submitter-activities-submissions/:submissionId?', route: '/myprofile/submitter-activities-submissions/{0}' };

//Organization Pages
// organizationProfile.patterns[0] reads from organizationProfile.routes[0] & organizationProfile.routes[2] for scrolling down to #tab
export const organizationProfile: IRoutes = { patterns: ['/org-profile/:join?/:tab?', '/org-profile/:join?/:tab?/:jdoi?'], routes: ['/org-profile/{0}/{1}', '/org-profile/{0}/{2}/{1}', '/org-profile/{0}/{1}#tab'] };
export const organizationOverview: IRoute = { pattern: '/org/:join/manage-overview', route: '/org/{0}/manage-overview' };
export const organizationContact: IRoute = { pattern: '/org/:join/manage-contact-info', route: '/org/{0}/manage-contact-info' };
export const organizationSocialLinks: IRoute = { pattern: '/org/:join/manage-links', route: '/org/{0}/manage-links' };
export const organizationWorks: IRoute = { pattern: '/org/:join/manage-works', route: '/org/{0}/manage-works' };
export const organizationIds: IRoute = { pattern: '/org/:join/manage-ids', route: '/org/{0}/manage-ids' };
export const organizationIdsGuides: IRoute = { pattern: '/org/:join/ids-guide', route: '/org/{0}/ids-guide' };
export const organizationOutputAndWorksGuide: IRoute = { pattern: '/org/:join/works-guide', route: '/org/{0}/works-guide' };
export const organizationInnovationInterests: IRoute = { pattern: '/org/:join/manage-innovation-interests', route: '/org/{0}/manage-innovation-interests' };
export const organizationFollowers: IRoute = { pattern: '/org/:join/followers', route: '/org/{0}/followers' };
export const organizationNotifications: IRoute = { pattern: '/org/:join/notification', route: '/org/{0}/notification' };
export const organizationProductsServices: IRoute = { pattern: '/org/:join/products', route: '/org/{0}/products' };
export const organizationCustomers: IRoute = { pattern: '/org/:join/customers', route: '/org/{0}/customers' };
export const organizationVerificationGuides: IRoute = { pattern: '/org/:join/verification-guide', route: '/org/{0}/verification-guide' };
export const organizationIdentityVerification: IRoute = { pattern: '/org/:join/identity-verification/:id?', route: '/org/{0}/identity-verification/{1}' };
export const organizationReceivedWorkFileShareRequests: IRoute = { pattern: '/org/:join/received-file-share-requests/:id?', route: '/org/{0}/received-file-share-requests/{1}' };
export const organizationContentVerification: IRoute = { pattern: '/org/:join/content-verifications', route: '/org/{0}/content-verifications' };
export const organizationContentVerificationRequest: IRoute = { pattern: '/org/:join/content-verification/:id/:contentType', route: '/org/{0}/content-verification/{1}/{2}' };
export const organizationViews: IRoute = { pattern: '/org/:join/org-views', route: '/org/{0}/org-views' };
export const organizationContentVerificationGuides: IRoute = { pattern: '/org/:join/content-verification-guide', route: '/org/{0}/content-verification-guide' };
export const organizationWorksReport: IRoute = { pattern: '/org/:join/dashboards/myReports/worksReport', route: '/org/{0}/dashboards/myReports/worksReport' };
export const organizationAffiliationsReport: IRoute = { pattern: '/org/:join/dashboards/myReports/affiliationsReport', route: '/org/{0}/dashboards/myReports/affiliationsReport' };
export const organizationNetworksReport: IRoute = { pattern: '/org/:join/dashboards/myReports/networksReport', route: '/org/{0}/dashboards/myReports/networksReport' };
export const organizationReportGuides: IRoute = {pattern: '/org/:join/reports-guide', route: '/org/{0}/reports-guide'}
export const organizationProfileAdmins: IRoute = {pattern: '/org/:join/admins', route: '/org/{0}/admins'}
export const organizationConferences: IRoute = { pattern: '/org/:join/activities', route: '/org/{0}/activities' };
export const orgConferenceSubmissions: IRoute = { pattern: '/org/:join/activity-submissions/:conferenceId', route: '/org/{0}/activity-submissions/{1}' };
export const orgConferenceQuestionsManagement: IRoute = { pattern: '/org/:join/manage-activity-questions/:conferenceId', route: '/org/{0}/manage-activity-questions/{1}' };
export const orgReviewerQuestionsManagement: IRoute = { pattern: '/org/:join/manage-reviewer-questions/:conferenceId', route: '/org/{0}/manage-reviewer-questions/{1}' };
export const orgConferenceDocumentsManagement: IRoute = { pattern: '/org/:join/manage-conference-documents/:conferenceId', route: '/org/{0}/manage-conference-documents/{1}' };

const Routes = {
  defaultRoute,
  home,
  authCallback,
  silentCallback,
  suspendUser,
  userSuspended,
  policy,
  faq,
  help,
  about,
  aboutJoip,
  whoWeAre,
  joipServices,
  joipNews,
  joipNewsDetail,
  joipEvents,
  individuals,
  organizations,
  patnerWithJoip,
  accessdenied,
  errorRoute,
  notDound,
  researcher,
  researcherProfile,
  resercherSentRequests,
  resercherRecievedRequests,
  researcherContact,
  researcherOverview,
  researcherSocialLinks,
  researcherInnovationInterests,
  researcherIdsGuides,
  researcherMyIds,
  researcherVerificationGuides,
  researcherSearch,
  emailVerify,
  orgEmailVerify,
  researcherOutputAndWorksGuide,
  researcherAddWork,
  researcherNetwork,
  resercherFollowing,
  resercherFollowers,
  resercherOrgFollowing,
  researcherEasyMatch,
  researcherReportGuides,
  post,
  organizationOverview,
  organizationContact,
  organizationSocialLinks,
  organizationIds,
  organizationIdsGuides,
  organizationOutputAndWorksGuide,
  organizationInnovationInterests,
  organizationWorks,
  organizationProfile,
  organizationFollowers,
  organizationNotifications,
  organizationProductsServices,
  organizationCustomers,
  organizationVerificationGuides,
  organizationIdentityVerification,
  organizationReceivedWorkFileShareRequests,
  organizationContentVerification,
  organizationContentVerificationRequest,
  organizationViews,
  publicSearch,
  researcherDistinction,
  researcherEducation,
  researcherEmployment,
  researcherInvitedPosition,
  researcherMembership,
  researcherQualification,
  researcherService,
  researcherIdentityVerification,
  resercherSentReferralRequests,
  resercherReceivedReferralRequests,
  researcherReferrals,
  researcherCurriculumVitae,
  researcherSentWorkFileShareRequests,
  researcherReceivedWorkFileShareRequests,
  researcherWorkFileShare,
  researcherContentVerification,
  researcherContentVerificationRequest,
  researcherProfileViews,
  organizationContentVerificationGuides,
  researcherContentVerificationGuides,
  messages,
  researcherActivityLog,
  researcherRingGoldSearch,
  refresh,
  resercherWorksReport,
  resercherTop10ISAsReport,
  organizationWorksReport,
  organizationAffiliationsReport,
  organizationNetworksReport,
  organizationReportGuides,
  organizationProfileAdmins,
  organizationConferences,
  orgConferenceSubmissions,
  orgConferenceQuestionsManagement,
  orgReviewerQuestionsManagement,
  orgConferenceDocumentsManagement,
  chairmanConferences,
  chairmanConferencesSubmissions,
  chairmanConferenceSubmissionDetails,
  chairmanConferenceQuestionsManagement,
  chairmanConferenceDocumentsManagement,
  chairmanReviewerQuestionsManagement,
  reviewerConferenceSubmissionDetails,
  reviewerConferencesSubmissions,
  researcherConferencesSubmissions,
}
export default Routes;
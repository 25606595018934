import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import _ from 'lodash';

import styles from './Tabs.module.scss';

interface IProps {
    items: Array<{
        name: string,
        routes: Array<string>
    }>
    ,
    styleClasses?:string
}

const Tabs: React.FC<IProps> = ({ items ,styleClasses }) => {
    const { pathname } = useLocation();

    const navlink = items?.map((item, index: number) => {
        return (
                <span key={"link0" + index} className={!styleClasses? `${styles.TabItem} d-relative`:''}>
                    <NavLink
                        className={!styleClasses?`flex-sm-fill text-sm-center nav-link bg-white text-muted fw-bold NavButton 
                           ${(pathname == item.routes[0] || pathname == item.routes[1]) && " border-bottom border-secondary  border-3"}`:` ${styleClasses} 
                           ${(pathname == item.routes[0] || pathname == item.routes[1]) && styles.ActiveNavButton}`}
                          to={item.routes[0] }>
                        {item.name}
                    </NavLink>
                </span>
        )
    })

    return (
        <nav>
            <div className="nav col-sm-12 d-flex justify-content-start m-auto" id="nav-tab" >
                {navlink}
            </div>
        </nav>
    );
}

export default React.memo(Tabs,(prevProps, nextProps) => _.isEqual(prevProps.items, nextProps.items));
import * as React from 'react';
import { renderRoutes } from "react-router-config";
import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
//@ts-ignore
import { Offline, Online } from "react-detect-offline";
import 'simplebar/dist/simplebar.min.css';

import './App.scss';
import routes from 'Config/routes';
import NotificationHub from 'Hubs/NotificationHub';
import NetwrokNotification from 'Shared/UI/Toast/NotificationToast';
import { unreadNotificationsCounts } from 'Store/Features/Notifications/NotificationSlice';
import { currUser, setUserFromLocalStorge } from 'Store/Features/UserSlice';
import { FetchSettings } from 'Services/InitialData/Settings';
import OfflineContent from 'Shared/Offline/Offline';
import Chats from 'Services/InitialData/Chats';
import ClientError from 'Shared/Error/ClientError';
import withClearCache from "./ClearCache";
import { Settings } from 'Settings';
import "Assets/Styles/Components/Chat/themes.scss";
import SessionExpired from 'Shared/SessionExpired/SessionExpired';
import CheckUser from 'Utils/CheckUser';
import { v4 as uuidv4 } from 'uuid';
import PortalVisitService from 'Services/PortalVisitService';
import { UserVisit } from 'Models/UserVisitModels';
import MetaPixel from 'Utils/Meta/MetaPixel';

const MainApp = () => {

    const _portalVisitService = new PortalVisitService();
    const userInfo = useSelector(currUser);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (Boolean(userInfo?.id)) { dispatch(unreadNotificationsCounts()); }
         
        if (Boolean(userInfo?.id)) {
            var data:UserVisit ={
             clientId:sessionStorage.getItem("clientId"),
             userProfileId:userInfo?.id,
             jrin:userInfo?.jrin
            }
             _portalVisitService.AddPortalVisit(data)
            .then((response) => {
                sessionStorage.removeItem("clientId");
                return true;
            })
            .catch(() => false);
        }
        else{
            if(sessionStorage.getItem("clientId") == null && localStorage.getItem("curr.user") == null )
            {
            let clientId =  uuidv4();
            sessionStorage.setItem("clientId", clientId );
            var data:UserVisit ={
                clientId:sessionStorage.getItem("clientId"),
                userProfileId:null,
                jrin:null
               }
                _portalVisitService.AddPortalVisit(data)
               .catch(() => false);
            }
        }

        dispatch(setUserFromLocalStorge());
        Sentry.setUser({ username: `${userInfo?.firstName} ${userInfo?.familyName}`, id: userInfo?.jrin });
    }, [userInfo?.id]);

    return (
        <>
            {
                <>
                    <FetchSettings />
                    <NetwrokNotification />
                    <Online polling={{ enabled: false }}>
                        {/* <ErrorBoundary fallback={<ClientError />}> */}
                        {renderRoutes(routes())}
                        {/* </ErrorBoundary> */}
                    </Online>
                    <Offline polling={{ enabled: false }}><OfflineContent /></Offline>
                    {Boolean(userInfo?.id) && <Chats />}
                    <SessionExpired />
                    <NotificationHub />
                    <MetaPixel/>
                    <CheckUser />
                    <div id='modal-root'></div>
                </>
            }
        </>
    );
};

const isProduction = process.env.NODE_ENV === 'production';

const ClearCacheComponent = isProduction && Settings.isCacheCleanerRuning ? MainApp : MainApp;

const App = () => {
    return <ClearCacheComponent />;
}

export default withClearCache(App);
import postSlice from './Features/PostSlice';
import userSlice from './Features/UserSlice';
import bookmarkSlice from './Features/BookmarkSlice';
import notificationSlice from './Features/Notifications/NotificationSlice';
import orgslice from './Features/OrgsSlice';
import confirmSlice from './Features/ConfirmSlice';
import viewWorkSlice from './Features/ViewWorkSlice';
import chatSlice from './Features/ChatSlice';

export default {
    post: postSlice,
    user: userSlice,
    bookmark: bookmarkSlice,
    org: orgslice,
    notification: notificationSlice,
    confirmDelete : confirmSlice,
    viewWork: viewWorkSlice,
    chat: chatSlice
}
